<template>
  <div>
    <div v-if="!isLookWrit">
      <el-table
        :data="tableData"
        style="width: 100%; margin-top: 30px"
        :header-cell-style="headerStyleEvent"
      >
        <el-table-column label="客户姓名" width="" prop="customerName">
        </el-table-column>
        <el-table-column prop="enterpriseName" label="公司名称" width="">
        </el-table-column>
        <el-table-column prop="mobile" label="手机号码" width="">
        </el-table-column>
        <el-table-column prop="email" label="电子邮箱" width="">
        </el-table-column>
        <!-- <el-table-column prop="name" label="签收状态" width="">
          <template slot-scope="scope">
            <span style="color: #8279f3" v-if="scope.row.isCompleted==2">已签收</span>
            <span style="color: #FC5C47;" v-if="scope.row.isCompleted==1">交付待签收</span>
            <span style="color: #FCC647;" v-if="scope.row.isCompleted==0">未签收</span>
          </template>
        </el-table-column> -->
        <!-- <el-table-column prop="name" label="签署状态" width="">
          <template slot-scope="scope"> -->
            <!-- <span style="color: #8279F3;">已签署</span> -->
            <!-- <span style="color: #fc5c47">未签署</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="name" label="操作" width="310" align="center">
          <template slot-scope="scope">
            <div style="padding: 0px 10px">
              <span
                style="color: #45cfa0; margin-right: 50px"
                @click="resend(scope.row.id)"
              >
                <i
                  class="ri-send-plane-fill"
                  style="
                    display: inline-block;
                    margin-top: -2px;
                    font-size: 16px;
                    margin-right: 4px;
                    cursor:pointer ;
                  "
                ></i>
                <span style=" cursor:pointer ;">重发</span>
              </span>
              <span
                style="color: #fcc647; margin-right: 50px"
                @click="lookWord(scope.row)"
              >
                <i
                  class="ri-eye-fill"
                  style="
                    display: inline-block;
                    margin-top: -2px;
                    font-size: 16px;
                    margin-right: 4px;
                    cursor:pointer ;
                  "
                ></i>
                <span style=" cursor:pointer ;">查看</span>
              </span>
              <span
                style="color: #6474c7"
                @click="downLoad(scope.row)"
              >
                <i
                  class="ri-download-cloud-line"
                  style="
                    display: inline-block;
                    margin-top: -2px;
                    font-size: 16px;
                    margin-right: 4px;
                    cursor:pointer ;
                  "
                ></i>
                <span style=" cursor:pointer ;">下载</span>
              </span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        align="center"
        style="margin-top: 20px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="ManualList.PageIndex"
        :page-count="totalPages"
        :page-sizes="[10, 20, 30,40]"
        :page-size="ManualList.PageSize"
        layout=" prev, pager, next, jumper,sizes,total"
        :total="total"
      >
      </el-pagination>
    </div>
    <div class="table table1" v-if="isLookWrit">
      <!-- 编辑框 -->
          <el-dialog
          title="查看文书"
          :visible.sync="isLookWrit"
          height="1000px"
          width="1200px"
          center
          top="50px"
        >
          <div id="box">
            <div id="placeholder"></div>
          </div>
          <p class="btn">
            <!-- <el-button type="primary" round @click="download()">下载</el-button> -->
            <el-button round @click="isLookWrit = false">返回</el-button>
          </p>
        </el-dialog>
    </div>
    <remote-js :src="CX_Api" />
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { getarchives, Deldownload } from '@/FackApi/api/employee-handbook'
import { viewInitEditor } from '@/Utils/viewonlineEdit'
export default {
  name: 'archives',
  components: {
    'remote-js': {
      render (createElement) {
        return createElement('script', {
          attrs: { type: 'text/javascript', src: this.src }
        })
      },
      props: {
        src: { type: String, required: true }
      }
    }
  },
  data () {
    return {
      isLookWrit: false,
      tableData: [],
      isShow: 0,
      pdfsite: '', // pdf地址
      total: 0,
      totalPages: 0,
      ManualList: {
        PageIndex: 1,
        PageSize: 10
      }
    }
  },
  mounted () {
    // this.getManualList()
    this.getManualList(this.ManualList)
  },
  methods: {
    getManualList (data) {
      // console.log(data);
      getarchives(data).then(res => {
        // console.log(res);
        if (res.code == 0) {
          this.tableData = res.data
          this.totalPages = res.page.totalPages
          this.total = res.page.totalCount
        }
      })
    },
    // 查看
    // 文件查看
    lookWord (item) {
      // console.log(item);
      this.fileInfo = item
      this.isLookWrit = true
      const url = 'https://api.ilaas.cn' + item.path
      this.createId = item.id
      this.$nextTick(() => {
        const el = document.getElementById('box')
        el.innerHTML = '<div id="placeholder"></div>'
        viewInitEditor(
          item.title,
          url,
          true,
          true,
          false,
          false,
          String(item.serailNo),
          'view',
          'desktop|mobile',
          {
            comment: true,
            download: false,
            edit: true,
            print: false,
            review: true
          },
          // item.title,
          this.COOKIE_TOKEN,
          100
        )
      })
    },
    // 下载
    // downLoad(row) {
    //   console.log(row);
    //   // return
    //    let ext = row.path.substring(row.path.lastIndexOf('.'));//截取文件后缀
    //   let url =  'http://apitest.ilaas.cn' +row.path
    //    let link = document.createElement("a"); //创建a标签
    //     link.style.display = "none"; //使其隐藏
    //     link.href = url; //赋予文件下载地址
    //     link.download= row.title+ext; //设置下载属性 以及文件名
    //     document.body.appendChild(link); //a标签插至页面中
    //     link.click(); //强制触发a标签事件
    //     document.body.removeChild(link) // 下载完成移除元素
    // },
    downLoad (row) {
      const ext = row.path.substring(row.path.lastIndexOf('.'))// 截取文件后缀
      const path = 'https://workbench.ilaas.cn/apis/' + row.path
      console.log(path)
      this.getBlob(path).then(blob => {
        this.saveAs(blob, row.title + ext)
      })
    },
    //     //通过文件下载url拿到对应的blob对象
    getBlob (url) {
      return new Promise(resolve => {
        const xhr = new XMLHttpRequest()
        xhr.open('GET', url, true)
        xhr.responseType = 'blob'
        xhr.onload = () => {
          if (xhr.status === 200) {
            resolve(xhr.response)
          }
        }
        xhr.send()
      })
    },
    // 下载文件 　　js模拟点击a标签进行下载
    saveAs (blob, filename) {
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = filename
      link.click()
    },

    // 重发
    resend: debounce(function (id) {
      this.$api.sendAgeain({ contractids: [id] }).then((res) => {
        if (res.code == 200) {
          this.$message({ message: '发送成功', type: 'success', offset: 400 })
        }
      })
    }, 1000),
    handleCurrentChange (val) {
      // console.log(val);
      this.ManualList.PageIndex = val
      this.getManualList(this.ManualList)
    },
    handleSizeChange (val) {
      // console.log(val);
      this.ManualList.PageSize = val
      this.getManualList(this.ManualList)
    },
    // switch切换
    switchIcon (idx) {
      if (idx == 0) {
        this.tabShow = false
        this.iconSrc = require('../../assets/images/home/卡片-选择@2x.png')
        this.iconSrc1 = require('../../assets/images/home/列表@2x.png')
      } else {
        this.tabShow = true
        this.iconSrc = require('../../assets/images/home/卡片.png')
        this.iconSrc1 = require('../../assets/images/home/列表-选择.png')
      }
    },
    // 表头颜色
    headerStyleEvent ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 6) {
        return {
          background: 'linear-gradient(90deg, #6878C8 0%, #79BAF2 100%)',
          color: '#FFFFFF'
        }
      } else if (
        columnIndex == 1 ||
        columnIndex == 2 ||
        columnIndex == 3 ||
        columnIndex == 4 ||
        columnIndex == 5 ||
        columnIndex == 0
      ) {
        return {
          background: '#EAF5FF',
          color: '#6474C7'
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
  ::v-deep .el-dialog__body{
    width:100%;
    height: calc(100% - 44px);
    padding: 0;
    #box{
      height: calc(100% - 50px);
    }
  }
  .btn{
    display: block !important;
    text-align: center;
    padding:5px;
    ::v-deep .el-button{
      width:135px;
      height:36px;
      line-height: 12px;
      border-radius: 18px;
      margin: 0 20px;
    }
    ::v-deep .el-button--primary{
      background-image: linear-gradient(#318DFA,#0261D5);
    }
  }
</style>
