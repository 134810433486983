<template>
  <div>
    <div class="boxtop" v-if="!isShowEdit">
      <div class="topleft">
        <span class="shouze" @click="screen(0)">按时间</span>
        <span class="shouze" @click="screen(1)">按客户</span>
        <span class="shouze" @click="screen(2)">按状态</span>
      </div>
      <div class="topright">
        <label>搜索<input type="text" placeholder="请输入文件名称" v-model="ManualList.Keyword"  @blur="ftSearch()" @keyup.enter="ftSearch()"/></label>
        <img
          @click="switchIcon(0)"
          style="
                        margin: 0 10px 0 52px;
                         30px;height: 30px;
                        display: inline-block;
                      "
          :src="iconSrc"
          alt=""
        />
        <img
          @click="switchIcon(1)"
          style="width: 30px; height: 30px; display: inline-block"
          :src="iconSrc1"
          alt=""
        />
      </div>
    </div>
    <template>
      <div v-if="!isShowEdit">
        <div v-if="tabShow">
        <el-table
          :data="tableData"
          style="width: 100%; margin-top: 30px"
          :header-cell-style="headerStyleEvent"
        >
          <el-table-column label="编号" width="200" prop="serailNo"> </el-table-column>
          <el-table-column prop="title" label="手册名称" width="">
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间" width="">
          </el-table-column>
          <el-table-column prop="name" label="状态" width="">
            <template slot-scope="scope">
              <div>
                <span style="color: #FC5C47;" v-if="scope.row.isCompleted==0">待确认</span>
                <span style="color: #8279F3;" v-if="scope.row.isCompleted==2">已确认</span>
                <span style="color: #FCC647;" v-if="scope.row.isCompleted==1">确认中</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="文件交付" width="">
            <template slot-scope="scope">
              <div>
                <button
                  @click="deliverWord(scope.row)"
                  class="tabbtn exam"
                  style="color: #fff"
                >
                  文件交付
                </button>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="操作" width="310" align="center">
            <template slot-scope="scope">

              <div style="padding: 0px 10px">
                <span style="color: #45cfa0; margin-right: 50px" @click="lookWord(scope.row)">
                  <i
                    class="ri-edit-2-line"
                    style="
                      display: inline-block;
                      margin-top: -2px;
                      font-size: 16px;
                      margin-right: 4px;
                      cursor:pointer ;
                    "
                  ></i>
                  <span style=" cursor:pointer ;">编辑</span>
                </span>
                <span style="color: #6474c7; margin-right: 50px" @click="download(scope.row)">
                  <i
                    class="ri-download-cloud-line"
                    style="
                      display: inline-block;
                      margin-top: -2px;
                      font-size: 16px;
                      margin-right: 4px;
                      cursor:pointer ;
                    "
                  ></i>
                  <span style=" cursor:pointer ;">下载</span>
                </span>
                <span style="color: #fcc647" @click="deleteWord(scope.row)">
                  <i
                    class="el-icon-delete"
                    style="
                      display: inline-block;
                      margin-top: -2px;
                      font-size: 16px;
                      margin-right: 4px;
                      cursor:pointer ;
                    "
                  ></i>
                  <span style=" cursor:pointer ;">删除</span>
                </span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-else class="listCard">
        <el-card
          class="box-card"
          ref="posRel"
          v-for="(item) in tableData"
          :key="item.id"
        >
          <img
            src="../../assets/images/home/word.png"
            alt=""
            style="
              display: inline-block;
              margin-right: 20px;
              float: left;
              margin-bottom: 20px;
              height: 90px;
            "
          />
          <span style="float: left; height: 90px; display: inline-block">
            <p>海富德公司员工手册1</p>
            <p>2022-09-12 13:50</p>
            <span
              @click="deliverWord(item)"
              style="
                display: inline-block;
                width: 100px;
                height: 30px;
                line-height: 30px;
                text-align: center;
                color: #ffffff;
                background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
                border-radius: 8px;
                margin-top: 10px;
                cursor:pointer ;
              "
              >文件交付</span
            >
          </span>
          <span
            style="
              float: right;
              width: 70px;
              display: inline-block;
              text-align: right;
              height: 90px;
            "
          >
            <p
            v-if="item.isCompleted==0"
              style="
                width: 70px;
                height: 26px;
                background: rgba(252,92,71,0.15);
                border-radius: 18px;
                text-align: center;
               color: #FC5C47;
              "
            >
              待确认
            </p>
            <p
            v-if="item.isCompleted==1"
              style="
                width: 70px;
                height: 26px;
                background: rgba(252,198,71,0.15);
                border-radius: 18px;
                text-align: center;
                color: #FCC647;
              "
            >
              确认中
            </p>
            <p
            v-if="item.isCompleted==2"
              style="
                width: 70px;
                height: 26px;
                background: rgba(130,121,243,0.15);
                border-radius: 18px;
                text-align: center;
                color: #8279F3;
              "
            >
              已确认
            </p>
            <el-popover
              placement="top"
              width="98px"
              trigger="click"
              style="text-align: center;cursor:pointer ;"
            >
              <div style="color: #606266; width: 98px" @click="lookWord(item)">
                <i
                  class="ri-edit-2-line"
                  style="
                    display: inline-block;
                    font-size: 14px;
                    margin-right: 10px;
                    cursor:pointer ;
                  "
                ></i>
                <span style=" cursor:pointer ;">编辑</span>
              </div>
              <div style="color: #606266; width: 98px" @click="download(item)">
                <i
                  class="ri-download-cloud-line"
                  style="
                    display: inline-block;
                    font-size: 14px;
                    margin-right: 10px;
                    cursor:pointer ;
                  "
                ></i>
                <span style=" cursor:pointer ;">下载</span>
              </div>
              <div style="color: #606266; width: 98px" @click="deleteWord(item)">
                <i
                  class="el-icon-delete"
                  style="
                    display: inline-block;
                    font-size: 14px;
                    margin-right: 10px;
                    cursor:pointer ;
                  "
                ></i>
                <span style=" cursor:pointer ;">删除</span>
              </div>
              <i class="ri-more-line iconmus" slot="reference"></i>
            </el-popover>
          </span>
        </el-card>
        <i v-for="(item, index) in tableData.length - 1" :key="index"></i>
      </div>
      <el-pagination
                  align="center"
                  style="margin-top: 20px"
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="ManualList.pageindex"
                  :page-count="totalPages"
                  :page-sizes="[10, 20, 30,40]"
                  :page-size="ManualList.pagesize"
                  layout=" prev, pager, next, jumper,sizes,total"
                  :total="total"
                >
      </el-pagination>
      <!-- 交付弹窗 -->
      <div class="popNotification" v-if="popShow">
        <div
          style="
            height: 61px;
            line-height: 60px;
            text-align: center;
            font-size: 16px;
            font-weight: bold;
            color: #6474c7;
            margin-bottom: 21px;
            background: #eaf5ff;
            border-radius: 18px 18px 0px 0px;
          "
        >
          文件交付
        </div>
        <div
          v-if="!newnewReceshow"
          style="
            margin: 0px 31px;
            height: 325px;
            background: #ffffff;
            border-radius: 8px;
            border: 1px solid #d7dbda;
          "
        >
          <div
            style="
              font-weight: bold;
              color: #ffffff;
              width: 597px;
              height: 44px;
              text-align: center;
              line-height: 44px;
              background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
              border-radius: 7px 7px 0px 0px;
            "
          >
            企业
          </div>
          <div style="margin-top: 30px; padding-left: 20px">
            <label style="width: 64px; color: #909399">客户名称</label
            ><el-input
              v-model="EnterpriseName"
              disabled
              type="text"
              placeholder="请选择客户名称"
              style="
                width: 464px;
                height: 40px;
                background: #ffffff;
                border-radius: 8px;
                color: #333333;
              "
            />
          </div>
          <div style="padding-left: 20px; margin-top: 20px">
            <label style="width: 64px; color: #909399">接收人</label
            ><el-select
              style="
                color: #333333;
                width: 372px;
                height: 40px;
                background: #ffffff;
                border-radius: 8px;
              "
              v-model="ReceiverName"
              filterable
              placeholder="请选择接收人"
              @change="changeReceiverName"
            >
              <el-option
                v-for="item in CusDetil"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
            <span
              @click="newRece"
              style="
                border-bottom: 1px solid #6474c7;
                color: #6474c7;
                margin-left: 12px;
              "
              >新增接收人</span
            >
          </div>
          <div v-if="ReceiverName">
            <div style="padding-left: 20px; margin-top: 20px">
            <label style="width: 64px; color: #909399">手机号</label
            ><span
              v-if="!phoneShow"
              style="color: #333333; width: 372px; display: inline-block"
              >{{ phone }}</span
            ><el-input
              v-else
              type="text"
              ref="phoneInput"
              v-model="phone"
              @blur="phoneBlur"
              focus="true"
              style="
                color: #333333;
                width: 372px;
                height: 40px;
                background: #ffffff;
                border-radius: 8px;
              "
            /><span
              @click="modifyPhone"
              style="
                color: #6474c7;
                border-bottom: 1px solid #6474c7;
                margin-left: 12px;
              "
              >修改</span
            >
          </div>
          <div style="padding-left: 20px; margin-top: 20px">
            <label style="width: 64px; color: #909399">邮箱号</label
            ><span style="color: #333333; width: 372px; display: inline-block" v-if="!emailShow">{{email}}</span>
            <el-input  v-else v-model="email" @blur="emailBlur" ref="emailInput" focus="true"
                            style="
                            color: #333333;
                            width: 372px;
                            height: 40px;
                            background: #ffffff;
                            border-radius: 8px;
                          "></el-input><span
                          @click="modifyEmail"
                          style="
                            color: #6474c7;
                            border-bottom: 1px solid #6474c7;
                            margin-left: 12px;
                            cursor:pointer
                          "
                          >修改</span
                        >
          </div>
          </div>
          <div style="margin-top: 70px; padding-left: 280px">
            <span
              @click="cancl"
              style="
                width: 140px;
                height: 50px;
                line-height: 50px;
                text-align: center;
                background: #f1f2f1;
                border-radius: 25px;
                margin-right: 20px;
                color: #bbbbbb;
                display: inline-block;
                font-weight: bold;
                font-size: 14px;
              "
              >取消</span
            ><button
             :disabled="rollDis"
              @click="submit"
              style="
                width: 140px;
                height: 50px;
                line-height: 50px;
                text-align: center;
                display: inline-block;
                background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
                border-radius: 25px;
                border:none;
                color: #ffffff;
                font-weight: bold;
                font-size: 14px;
              "
              >{{rollDis?'发送中':'确认'}}</button
            >
          </div>
        </div>
        <div
          v-else
          style="
            margin: 0px 31px;
            height: 325px;
            background: #ffffff;
            border-radius: 8px;
            border: 1px solid #d7dbda;
          "
        >
          <div
            style="
              font-weight: bold;
              color: #ffffff;
              width: 597px;
              height: 44px;
              text-align: center;
              line-height: 44px;
              background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
              border-radius: 7px 7px 0px 0px;
            "
          >
            企业
          </div>
          <div style="margin-top: 30px; padding-left: 20px">
            <label>接收人</label
            ><el-input
              v-model="newReceiver.name"
              placeholder="请输入接收人"
              style="
                width: 498px;
                height: 40px;
                background: #ffffff;
                border-radius: 8px;
                color: #333333;
                margin-left: 20px;
              "
            ></el-input>
          </div>
          <div style="margin-top: 30px; padding-left: 20px">
            <label>手机号</label
            ><el-input
              v-model="newReceiver.phoneText"
              placeholder="请输入手机号"
              style="
                width: 498px;
                height: 40px;
                background: #ffffff;
                border-radius: 8px;
                color: #333333;
                margin-left: 20px;
              "
            ></el-input>
          </div>
          <div style="margin-top: 30px; padding-left: 20px">
            <label>邮箱号</label
            ><el-input
              v-model="newReceiver.email"
              placeholder="请输入邮箱号"
              style="
                width: 498px;
                height: 40px;
                background: #ffffff;
                border-radius: 8px;
                color: #333333;
                margin-left: 20px;
              "
            ></el-input>
          </div>
          <div style="margin-top: 100px; padding-left: 280px">
            <span
              @click="newcancl"
              style="
                width: 140px;
                height: 50px;
                line-height: 50px;
                text-align: center;
                background: #f1f2f1;
                border-radius: 25px;
                margin-right: 20px;
                color: #bbbbbb;
                display: inline-block;
                font-weight: bold;
                font-size: 14px;
              "
              >取消</span
            ><span
              @click="submitnewReceiver"
              style="
                width: 140px;
                height: 50px;
                line-height: 50px;
                text-align: center;
                display: inline-block;
                background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
                border-radius: 25px;
                color: #ffffff;
                font-weight: bold;
                font-size: 14px;
              "
              >确认</span
            >
          </div>
        </div>
      </div>
      </div>
      <div v-show="!popShow" style="height:100%" class="editor">
        <!-- <div class="edit-btn">
          <div style="height:20px;"></div>
          <el-button @click="goBack()"><i class="el-icon-arrow-left"></i>返回</el-button>
        </div> -->
        <el-dialog
            title="编辑文件"
            :visible.sync="isLookWrit"
            width="1200px"
            height="100%"
            center
            top="50px"
          >
          <div id="box" ref="box">
            <div id="placeholder"></div>
          </div>
          <p class="btn">
            <!-- <el-button type="primary" round @click="download()">下载</el-button> -->
            <el-button round @click="isLookWrit = false">取消</el-button>
            <el-button round @click="isLookWrit = false">保存</el-button>
          </p>
    </el-dialog>
      </div>
      <remote-js :src="CX_Api"/>
    </template>
  </div>
</template>

<script>
import { getdeliverList, getCustomer, Deldeliver, getCusDetil, launch, addCustomer } from '@/FackApi/api/employee-handbook'
import { viewInitEditor } from '@/Utils/viewonlineEdit'
export default {
  name: 'ReleaseDelivery',
  components: {
    'remote-js': {
      render (createElement) {
        return createElement('script', {
          attrs: { type: 'text/javascript', src: this.src }
        })
      },
      props: {
        src: { type: String, required: true }
      }
    }
  },
  data () {
    return {
      isShowEdit: false,
      total: 0,
      totalPages: 0,
      ManualList: {
        pageindex: 1,
        pagesize: 10,
        status: -1,
        Keyword: ''
      },
      tabStatus: '#FCC647', // 状态
      bgStatus: 'rgba(252,198,71,0.15)', // 状态
      tabShow: true, // 审查列表
      iconSrc: require('../../assets/images/home/卡片.png'),
      iconSrc1: require('../../assets/images/home/列表-选择.png'),
      tableData: [],
      phoneText: '123456789', // 手机
      phoneShow: false, // input显示
      emailShow: false, // input显示
      popShow: false,
      EnterpriseName: '', // 企业名称
      ReceiverName: '', // 接收人
      newnewReceshow: false,
      newReceiver: {
        name: '',
        phoneText: '',
        email: ''
      },
      isLookWrit: false,
      createId: '', // 创建ID
      fileInfo: {},
      CusDetil: [], // 】联系人列表
      ReceiId: '', // 协作人id
      manualId: '',
      wordId: '', // 文件id
      email: '',
      phone: '',
      rollDis: false
    }
  },
  mounted () {
    const oSpan = document.getElementsByClassName('shouze')
    oSpan[0].style.color = '#FFFFFF'
    oSpan[0].style.backgroundColor = '#6474C7'
    this.getManualList(this.ManualList)
  },
  methods: {
    // 搜索
    ftSearch () {
      this.getManualList(this.ManualList)
      this.ManualList.Keyword = ''
    },
    // 选择接收人
    changeReceiverName (val) {
      this.ReceiverName = val.name
      this.phone = val.mobile
      this.email = val.email
      this.ReceiId = val.id
    },
    // 编辑
    lookWord (item) {
      // console.log(item);
      this.fileInfo = item
      this.isLookWrit = true
      this.createId = item.id
      this.$nextTick(() => {
        const el = document.getElementById('box')
        el.innerHTML = '<div id="placeholder"></div>'
        viewInitEditor(
          item.title,
          item.path,
          true,
          true,
          true,
          true,
          String(item.serailNo),
          'eide',
          'desktop|mobile',
          {
            comment: true,
            download: false,
            edit: true,
            print: false,
            review: true
          },
          item.title,
          this.COOKIE_TOKEN,
          100
        )
      })
    },
    // 返回
    goBack () {
      this.isShowEdit = false
    },
    handleCurrentChange (val) {
      // console.log(val);
      this.ManualList.pageindex = val
      this.getManualList(this.ManualList)
    },
    handleSizeChange (val) {
      // console.log(val);
      this.ManualList.pagesize = val
      this.getManualList(this.ManualList)
    },
    // 数据
    getManualList (data) {
      // console.log(data);
      getdeliverList(data).then(res => {
        console.log(res)
        if (res.code == 0) {
          this.tableData = res.data
          this.totalPages = res.page.totalPages
          this.total = res.page.totalCount
          // this.manualId = res.data.id
        }
      })
    },
    // 数据排序
    screen (index) {
      const oSpan = document.getElementsByClassName('shouze')
      for (let i = 0; i < oSpan.length; i++) {
        oSpan[i].style.color = '#6474C7'
        oSpan[i].style.backgroundColor = '#FFFFFF'
      }
      oSpan[index].style.color = '#FFFFFF'
      oSpan[index].style.backgroundColor = '#6474C7'

      if (index == 0) {
        this.ManualList.status = -1
      } else if (index == 1) {
        this.ManualList.status = 0
      } else {
        this.ManualList.status = 1
      }
      // console.log(this.ManualList);
      this.getManualList(this.ManualList)
    },
    // switch切换
    switchIcon (idx) {
      if (idx == 0) {
        this.tabShow = false
        this.iconSrc = require('../../assets/images/home/卡片-选择@2x.png')
        this.iconSrc1 = require('../../assets/images/home/列表@2x.png')
      } else {
        this.tabShow = true
        this.iconSrc = require('../../assets/images/home/卡片.png')
        this.iconSrc1 = require('../../assets/images/home/列表-选择.png')
      }
    },
    // 表头颜色
    headerStyleEvent ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 5) {
        return {
          background: 'linear-gradient(90deg, #6878C8 0%, #79BAF2 100%)',
          color: '#FFFFFF'
        }
      } else if (
        columnIndex == 1 ||
        columnIndex == 2 ||
        columnIndex == 3 ||
        columnIndex == 4 ||
        columnIndex == 5 ||
        columnIndex == 0
      ) {
        return {
          background: '#EAF5FF',
          color: '#6474C7'
        }
      }
    },
    // 交付弹窗
    deliverWord (row) {
    // console.log(row);
      if (row.isCompleted == 0) {
        this.wordId = row.id
        this.manualId = row.customerId
        this.popShow = true
        getCusDetil(row.customerId).then((res) => {
          // console.log(res);
          if (res.code == 0) {
            this.CusDetil = res.item.contacts
          }
        })
        // EnterpriseName
        getCustomer(2).then(res => {
          if (res.code == 0) {
            const data = res.items
            data.forEach(val => {
              if (val.id == row.customerId) {
                this.EnterpriseName = val.name
              }
            })
          }
        })
      } else if (row.isCompleted == 1) {
        this.$message({
          type: 'error',
          message: '确认中，请勿重复提交',
          offset: 400,
          duration: 800
        })
      } else {
        this.$message({
          type: 'error',
          message: '已确认',
          offset: 400,
          duration: 800
        })
      }
    },
    // 修改
    modifyPhone () {
      this.phoneShow = true
      setTimeout(() => {
        this.$refs.phoneInput.focus()
      }, 200)
    },
    modifyEmail () {
      this.emailShow = true
      setTimeout(() => {
        this.$refs.emailInput.focus()
      }, 200)
    },
    // 失去焦点
    phoneBlur () {
      this.phoneShow = false
    },
    emailBlur () {
      this.emailShow = false
    },
    // 取消
    cancl () {
      this.popShow = false
    },
    // 提交交付
    submit () {
      const { ReceiverName, phone, email, wordId, ReceiId } = this
      if (ReceiverName && phone && email) {
      } else {
        return this.$message({
          type: 'error',
          message: '姓名/邮箱/手机为必填项。',
          offset: 400,
          duration: 800
        })
      }
      this.rollDis = true
      const str = [
        {
          id: ReceiId,
          name: ReceiverName,
          email,
          mobile: phone,
          synergyType: 1
        }
      ]
      console.log(str)
      launch(str, wordId).then(res => {
        console.log(res)
        if (res.code == 0) {
          this.popShow = false
          this.rollDis = false
          this.$message({
            type: 'success',
            message: res.msg,
            offset: 400,
            duration: 800
          })
          this.getManualList(this.ManualList)
        }
      })
    },
    // 新增接收人
    newRece () {
      this.newnewReceshow = true
    },
    // 新增取消
    newcancl () {
      this.popShow = true
      this.newnewReceshow = false
    },
    // 提交新增联系人
    submitnewReceiver () {
      const emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      const phoneReg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/
      if (this.newReceiver.name == '') {
        this.$message({
          type: 'error',
          message: '协作人不能为空！',
          offset: 400,
          duration: 800
        })
        return
      }
      if (phoneReg.test(this.newReceiver.phoneText) == false) {
        this.$message({
          type: 'error',
          message: '请输入正确的手机号！',
          offset: 400,
          duration: 800
        })
        return
      }
      if (emailReg.test(this.newReceiver.email) == false) {
        this.$message({
          type: 'error',
          message: '请输入正确的邮箱！',
          offset: 400,
          duration: 800
        })
        return
      }
      const data = {
        name: this.newReceiver.name,
        mobile: this.newReceiver.phoneText,
        email: this.newReceiver.email,
        customerId: this.manualId
      }
      // console.log(data);
      addCustomer(data).then((res) => {
        console.log(res)
        if (res.code == 0) {
          this.$message({
            type: 'success',
            message: res.msg,
            offset: 400,
            duration: 800
          })
          getCusDetil(this.manualId).then((res) => {
            if (res.code == 0) {
              this.CusDetil = res.item.contacts
            }
          })
        }
      })
      this.newnewReceshow = false
      this.popShow = true
    },
    // 点击下载
    // download(row) {
    //   let ext = row.path.substring(row.path.lastIndexOf('.'));
    //   this.getBlob(row.path).then(blob => {
    //     this.DownloadItem(blob, row.title+ext);
    //   });
    // },
    // //下载 重命名 3
    //   getBlob(url) {
    //       return new Promise(resolve => {
    //       const xhr = new XMLHttpRequest();
    //       xhr.open('GET', url, true);
    //       xhr.responseType = 'blob';
    //       xhr.onload = () => {
    //         if (xhr.status === 200) {
    //           resolve(xhr.response);
    //         }
    //       };
    //       xhr.send();
    //     });
    //   },
    //    DownloadItem(blob,fileName){
    //      let link = document.createElement('a');
    //      link.href = window.URL.createObjectURL(blob);
    //      link.download = fileName;
    //      link.click();
    // },
    //  download(row) {
    //   console.log(row);
    //   // return
    //   let url = row.path
    //   let a = document.createElement("a");
    //   a.href = url;
    //   // link.setAttribute("download", binding.value.fileName);
    //   a.download =row.title
    //   a.target = "_blank";
    //   a.click();
    // },
    download (row) {
      const ext = row.path.substring(row.path.lastIndexOf('.'))// 截取文件后缀
      const path = 'https://workbench.ilaas.cn/apis' + row.path
      console.log(path)
      this.getBlob(path).then(blob => {
        this.saveAs(blob, row.title + ext)
      })
    },
    //     //通过文件下载url拿到对应的blob对象
    getBlob (url) {
      return new Promise(resolve => {
        const xhr = new XMLHttpRequest()
        xhr.open('GET', url, true)
        xhr.responseType = 'blob'
        xhr.onload = () => {
          if (xhr.status === 200) {
            resolve(xhr.response)
          }
        }
        xhr.send()
      })
    },
    // 下载文件 　　js模拟点击a标签进行下载
    saveAs (blob, filename) {
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = filename
      link.click()
    },
    // 删除
    deleteWord (row) {
      Deldeliver(row.id).then(res => {
        // console.log(res);
        if (res.code == 0) {
          this.getManualList(this.ManualList)
          this.$message({
            type: 'error',
            message: res.msg,
            offset: 400,
            duration: 800
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.editor{
  position:relative;
  margin:0 20px;
  .edit-dialog{
    position:absolute;
    height: calc(100vh - 240px);
    top: 65px;
    ::v-deep .el-dialog{
      height:100%;
      .el-dialog__header{
        display:none;
      }
      .el-dialog__body{
        padding:0;
      }
    }
  }
}
/deep/.iq-card-body {
  padding: 20px;
  margin-top: -6px;
}
.card-bodyel-tabs,
.el-tabs--top {
  margin-top: -20px;
  /deep/.el-tabs__item {
    font-size: 14px;
    font-weight: bold;
  }
  /deep/.el-tabs__item.is-top:nth-child(2) {
    padding-left: 18px;
  }

  /deep/.el-tabs__item.is-active {
    color: #837af3;
  }
  /deep/.el-tabs__item:hover {
    color: #837af3;
    cursor: pointer;
  }
  /deep/.el-tabs__active-bar.is-top {
    width: 92px !important;
  }
  /deep/.el-tabs__active-bar {
    left: -18px;
    width: 100px;
    height: 6px;
    background: linear-gradient(270deg, #f0e4fd 0%, #e6e4fd 100%);
    border-radius: 4px 4px 0px 0px;
  }
  /deep/.el-tabs__item {
    padding: 0 23px;
    margin-bottom: 4px;
  }
}
label {
  color: var(--iq-dark);
}
// 深度选择器 去掉全选按钮
::v-deep .el-table .disabledCheck .cell .el-checkbox__inner {
  display: none;
}

::v-deep .el-table .disabledCheck .cell::before {
  content: "";
  text-align: center;
  line-height: 37px;
}

/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #6474c7;
  border-color: #6474c7;
}
/deep/.el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #6474c7;
}
/deep/.el-checkbox__inner {
  top: 3px;
  right: 0;
}

/deep/.el-table-column--selection .cell {
  padding-left: 14px;
  padding-right: 18px;
}
// 表格
/deep/.el-table__header-wrapper {
  border-radius: 18px 18px 0px 0px !important;
  /deep/.el-table_1_column_8 {
    color: #ffffff;
    background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%) !important;
  }
  /deep/.el-checkbox {
    width: 16px;
    height: 16px;
  }
}
.shouze {
  display: inline-block;
  margin-right: 20px;
  text-align: center;
  line-height: 30px;
  color: #6474c7;
  font-size: 14px;
  width: 80px;
  height: 30px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #6474c7;
}
.disb {
  background: #6474c7;
  border-radius: 8px;
  color: #ffffff;
}
.boxtop {
  width: 100%;
  height: 40px;
  margin-top: 30px;
}
.topleft {
  float: left;
}
.topright {
  float: right;
  position: relative;
  top: 0px;
  right: 0;
  color: #333333;
  input {
    width: 300px;
    height: 40px;
    color: #909399;
    background: #eaf5ff;
    border-radius: 10px;
    margin-left: 10px;
    outline: none;
    border: none;
    text-indent: 20px;
  }
}
.tabbtn {
  width: 100px;
  height: 30px;
  display: inline-block;
  background: #45cfa0;
  border-radius: 10px !important;
  border: none;
}
.exam {
  background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
  border-radius: 8px;
}
.okcan {
  width: 50px;
  height: 30px;
  display: inline-block;
  background: #45cfa0;
  border: none;
  text-align: center;
  color: #fff;
}
.okbtn {
  border-radius: 8px 0px 0px 8px !important;
}
.cancal {
  background: #fff;
  color: #45cfa0;
  border-radius: 0px 8px 8px 0px !important;
  border: 1px solid #45cfa0;
}

/deep/.el-table__expand-icon {
  position: absolute;
  right: 40px;
  top: 16px;
}

.editword {
  width: 80px;
  height: 30px;
  background: #ffffff;
  border-radius: 8px !important;
  border: 1px solid #45cfa0;
  color: #45cfa0;
}
/deep/.listCard {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  color: 14px;
  margin-right: -20px;
  .box-card {
    width: 400px;
    margin-right: 32px;
    margin: 30px 24px 0px 0px !important;
    border-radius: 18px;
    border: 1px solid #d7dbda;
    color: 14px;
  }
}
.listCard > i {
  width: 400px;
  margin-right: 20px;
}

.iconmus {
  display: inline-block;
  width: 25px;
  height: 25px;
  background: linear-gradient(270deg, #f0e4fd 0%, #e6e4fd 100%);
  border-radius: 5px;
  text-align: center;
  margin-top: 38px;
}
.posAbs {
  position: absolute;
  left: 0;
  top: 210px;
  z-index: 999;

  font-size: 14px;
  margin-top: 20px;
  display: flex;
  align-content: space-around;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
}
.tabsc {
  width: 100px;
  text-align: center;
  display: inline-block;
}
.tabsc :first-child(1) {
  margin-left: 0px;
}
.tabsactive {
  width: 100px;
  border-bottom: 6px solid #f0e4fd;
  border-radius: 3px;
}
/deep/.el-popover {
  width: 98px !important;
}
::v-deep .el-dialog__body{
    width:100%;
    height: calc(100% - 44px);
    padding: 0;
    #box{
      height: calc(100% - 50px);
    }
  }
  .btn{
    display: block !important;
    text-align: center;
    padding:5px;
    ::v-deep .el-button{
      width:135px;
      height:36px;
      line-height: 12px;
      border-radius: 18px;
      margin: 0 20px;
    }
    ::v-deep .el-button--primary{
      background-image: linear-gradient(#318DFA,#0261D5);
    }
  }
</style>
<style>
.contract {
  border-radius: 10px;
  overflow: hidden;
  background: linear-gradient(to right, #f0e4fd, #e6e4fd) !important;
}
.contract .iq-card-body {
  background: white;
}
.popNotification {
  width: 660px;
  height: 515px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(152, 172, 190, 0.2);
  border-radius: 18px;
}
</style>
