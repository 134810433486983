// 引入request
import request from "../../Utils/request";


//获取客户列表
export const getCustomer = (params) =>
  request({
    url: `/api/customer/select/list?type=${params}`,
    method: "get",
    // data
  });
// 查看客户详情
export const getCustomerDetail = (params) =>
  request({
    url: `/api/customer/detail/${params}`,
    method: "get",
  });
// 新增手册
export function setAddemployee(data) {
  return request({
    url: 'EmployeeHandbook/set/add',
    method: 'post',
    data
  })
}
// 手册列表
export function getManual(data) {
  return request({
    url: `EmployeeHandbook/set/list?PageIndex=${data.PageIndex}&PageSize=${data.PageSize}&Keyword=${data.Keyword}`,
    method: 'get',
  })
}
// 删除手册
export function delManual(data) {
  return request({
    url: `EmployeeHandbook/set/delete?Ids=${data}`,
    method: 'delete',
  })
}
// 编辑手册 
export function EditManual(data) {
  return request({
    url: `EmployeeHandbook/set/edit/${data}`,
    method: 'get',
  })
}
// 查询节内容
export function getSection(data) {
  return request({
    url: `EmployeeHandbook/section/view/${data}`,
    method: 'get',
  })
}
// 更新一节内容
export function upManual(data) {
  return request({
    url: `EmployeeHandbook/section/edit`,
    method: 'put',
    data
  })
}
// 更新标题
export function upTitlte(data) {
  return request({
    url: `EmployeeHandbook/set/title`,
    method: 'put',
    data
  })
}
// 删除节 
export function DelManual(data) {
  return request({
    url: `EmployeeHandbook/section/remove/${data}`,
    method: 'put',
  })
}
// 节内容初始化
export function initialization(data) {
  return request({
    url: `EmployeeHandbook/section/init/${data}`,
    method: 'put',
  })
}
// 恢复
export function recoveryManaul(data) {
  return request({
    url: `EmployeeHandbook/section/recover/${data}`,
    method: 'put',
  })
}
// 获取劳动法规
export function getlabourlaw(data) {
  return request({
    url: `EmployeeHandbook/section/lawinfo/${data.vid}?PageIndex=${data.PageIndex}&PageSize=${data.PageSize}&Keyword=${data.Keyword}`,
    method: 'get',
  })
}
// 查询手册设置下最新的一份生成文件
export function getManualWord(data) {
  return request({
    url: `EmployeeHandbook/file/view/${data}`,
    method: 'get',
  })
}
// 发起协作/交付
export function launch(data,cid) {
  return request({
    url: `EmployeeHandbook/file/synergy/${cid}`,
    method: 'post',
    data
  })
}
// 转入待交付
export function Todelivered(data) {
  return request({
    url: `EmployeeHandbook/file/submit/${data}`,
    method: 'post',
  })
}
// 交付列表
export function getdeliverList(data) {
  return request({
    url: `EmployeeHandbook/file/list?pagesize=${data.pagesize}&pageindex=${data.pageindex}&status=${data.status}&Keyword=${data.Keyword}`,
    method: 'get'
  })
}
// 添加客户联系人
export function addCustomer(data) {
  return request({
    url: `api/customer/contact/add`,
    method: 'post',
    data
  })
}
// 删除交付文件
export function Deldeliver(data) {
  return request({
    url: `EmployeeHandbook/file/delete?ids=${data}`,
    method: 'delete',
  })
}
// 下载交付文件
export function download(data) {
  return request({
    url: `EmployeeHandbook/file/download/${data}`,
    method: 'get',
  })
}
// 客户详情
export function getCusDetil(data) {
  return request({
    url: `api/customer/detail/${data}`,
    method: 'get',
  })
}
// 生成文件
export function generateWord(data) {
  return request({
    url: `EmployeeHandbook/file/create/${data}`,
    method: 'post',
  })
}
// 档案列表
export function getarchives(data) {
  return request({
    url: `EmployeeHandbook/file/list/archives?PageIndex=${data.PageIndex}&PageSize=${data.PageSize}`,
    method: 'get',
  })
}
// 取消协作
export function offcooperation(data) {
  return request({
    url: `/EmployeeHandbook/file/synergy/cancel/${data}`,
    method: 'post',
  })
}
// 协作完成
export function cancooperation(data) {
  return request({
    url: `EmployeeHandbook/file/synergy/confirm/${data}`,
    method: 'post',
  })
}
// 下载交付文件
export function Deldownload(data) {
  return request({
    url: `EmployeeHandbook/file/download/${data}`,
    method: 'get',
  })
}
// 新增章节
export function addSideTitle(data) {
  return request({
    url: `EmployeeHandbook/section/parent/add`,
    method: 'post',
    data
  })
}
// 新增内容
export function addSection(data) {
  return request({
    url: `EmployeeHandbook/section/sub/add`,
    method: 'post',
    data
  })
}
// 生成文件预览
export function Addpreview(data) {
  return request({
    url: `EmployeeHandbook/file/createpreview/${data}`,
    method: 'get',
  })
}
// 发起文件文件审查
export function getexamination(data) {
  return request({
    url: `EmployeeHandbook/file/handbook/exam/${data}`,
    method: 'get',
  })
}
// 获取审查指定章节内容
export function Titleexamination(data) {
  return request({
    url: `EmployeeHandbook/file/handbook/detail/${data}`,
    method: 'get',
  })
}
// 更新审查内容
export function upContentexamination(data) {
  return request({
    url: `EmployeeHandbook/file/handbook/update`,
    method: 'post',
    data
  })
}
// 审查指定内容
export function contentexamination(data) {
  return request({
    url: `EmployeeHandbook/file/handbook/text/exam`,
    method: 'post',
    data
  })
}
// 协作数据
export function getmanList(data) {
  return request({
    url: `EmployeeHandbook/file/synergy/view/${data}`,
    method: 'get',
  })
}
// 协作通过
export function endcomplete(data) {
  return request({
    url: `EmployeeHandbook/file/synergy/completed`,
    method: 'put',
    data
  })
}

// 上传图片返路径
export function uploadImg(data) {
  return request({
    url: "api/account/member/upload/img",
    method: "post",
    data,
  });
}