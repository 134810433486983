<template>
  <div>
    <div
      style="position: relative; margin-top: 50px; width: 100%"
    >

     <!-- 提示 -->
      <div style="margin-top: 12px;padding:0px 10px; height: 40px; line-height: 40px">
        <div
          style="
            font-weight: bold;
            color: #333333;
            height: 40px;
            line-height: 40px;
            float: left;
          "
        >
          {{mamaultitle}}
        </div>
        <!-- <div @click="GlobalModel" class="Global" style=" 
        cursor:pointer ;width: 120px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        float: right;
        background: linear-gradient(270deg, #f0e4fd 0%, #e6e4fd 100%);
        border-radius: 8px;
        font-weight: bold;
        color: #837af3;
        font-size: 14px;">{{ GlobalHtml }}</div> -->
        <div style="height: 40px; line-height: 40px; float: right">
          <span v-show="tipShow">请浏览以下制式条款内容，如必要请进一步调整以更加符合您公司的特点和要求。</span>
        </div>
      </div>
      <!-- 工具栏 -->
      <Toolbar
        style="border-bottom: 1px solid #ccc; width: 100%;"
        :editor="editor"
        :defaultConfig="toolbarConfig"
      />
      <!-- 编辑器 -->

      <div
        style="
          margin-top: 0px;
          background: #ffffff;
          padding: 8% 6%;
          color: #333333;
          height: 100%;
          width: 100%;
          overflow: auto;
          box-shadow: 0px 0px 10px 0px rgba(152, 172, 190, 0.2);
        "
      >
        <div style="display: flex; justify-content: space-between">
          <span
            style="
              width: 41px;
              height: 41px;
              display: inline-block;
              border-right: 1px solid #cdcdcd;
              border-bottom: 1px solid #cdcdcd;
            "
          ></span>
          <span
            style="
              width: 41px;
              height: 41px;
              display: inline-block;
              border-left: 1px solid #cdcdcd;
              border-bottom: 1px solid #cdcdcd;
            "
          ></span>
        </div>
        <Editor
          style="min-height:600px"
          :defaultConfig="editorConfig"
          v-model="html"
          @onChange="onChange"
          @onCreated="onCreated"
          @onBlur="onBlur"
        />
        <div style="display: flex; justify-content: space-between">
          <span
            style="
              width: 41px;
              height: 41px;
              display: inline-block;
              border-right: 1px solid #cdcdcd;
              border-top: 1px solid #cdcdcd;
            "
          ></span>
          <span
            style="
              width: 41px;
              height: 41px;
              display: inline-block;
              border-left: 1px solid #cdcdcd;
              border-top: 1px solid #cdcdcd;
            "
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { DomEditor } from "@wangeditor/editor";

export default {
  name: "MyEditor",
  components: { Editor, Toolbar ,},
  props: {
    content: {
      type: String,
      default: "",
    },
    GlobalHtml: {
      type: String,
      default: "",
    },
    mamaultitle: {
      type: String,
      default: "",
    },
    tipShow: {
      type: Boolean,
      default: "",
    },
    deitShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editor: null,
      html: "",
      toolbarConfig: {
        /* 显示哪些菜单，如何排序、分组 */
        toolbarKeys: [
          "undo",
          "redo",
          "|",
          "headerSelect",
          "fontFamily",
          "fontSize",
          "|",
          "bold",
          "italic",
          "underline",
          "color",
          "bgColor",
          "|",
          "bulletedList",
          "numberedList",
          {
            iconSvg:
              '<svg viewBox="0 0 1024 1024"><path d="M768 793.6v102.4H51.2v-102.4h716.8z m204.8-230.4v102.4H51.2v-102.4h921.6z m-204.8-230.4v102.4H51.2v-102.4h716.8zM972.8 102.4v102.4H51.2V102.4h921.6z"></path></svg>',
            key: "group-justify",
            menuKeys: [
              "justifyLeft",
              "justifyRight",
              "justifyCenter",
              "justifyJustify",
            ],
            title: "对齐",
          },
          {
            iconSvg:
              '<svg viewBox="0 0 1024 1024"><path d="M0 64h1024v128H0z m384 192h640v128H384z m0 192h640v128H384z m0 192h640v128H384zM0 832h1024v128H0z m0-128V320l256 192z"></path></svg>',
            key: "group-indent",
            menuKeys: ["indent", "delIndent"],
          },
          "divider",
          // {
          //   iconSvg:
          //     '<svg viewBox="0 0 1024 1024"><path d="M959.877 128l0.123 0.123v767.775l-0.123 0.122H64.102l-0.122-0.122V128.123l0.122-0.123h895.775zM960 64H64C28.795 64 0 92.795 0 128v768c0 35.205 28.795 64 64 64h896c35.205 0 64-28.795 64-64V128c0-35.205-28.795-64-64-64zM832 288.01c0 53.023-42.988 96.01-96.01 96.01s-96.01-42.987-96.01-96.01S682.967 192 735.99 192 832 234.988 832 288.01zM896 832H128V704l224.01-384 256 320h64l224.01-192z"></path></svg>',
          //   key: "group-image",
          //   menuKeys: ["insertImage", "uploadImage"],
          //   title: "图片",
          // },
        ],
        // excludeKeys: [ ], /* 隐藏哪些菜单 */
      },
      editorConfig: {
        placeholder: "",
        // autoFocus: false,
        readOnly: false, // 只读、不可编辑
        // 所有的菜单配置，都要在 MENU_CONF 属性下
        MENU_CONF: {},
      },
    };
  },
  mounted() {
    // console.log(this.GlobalHtml);
    const toolbar = DomEditor.getToolbar(editor);
    const curToolbarConfig = toolbar.getConfig();
    // console.log(curToolbarConfig.toolbarKeys, 11);
  },
  methods: {
    // GlobalModel() {
    //   this.$emit("Global");
    // },
    onCreated(editor) {
      this.editor = Object.seal(editor); // 【注意】一定要用 Object.seal() 否则会报错
    },
    onChange(editor) {
      // console.log("onChange", editor.getHtml() ); // onChange 时获取编辑器最新内容
      // const toolbar = DomEditor.getToolbar(editor)
      // console.log("工具栏配置", toolbar.getConfig().toolbarKeys ); // 工具栏配置
      // this.$emit("content", editor.getHtml());
      // console.log(editor.getHtml());
    },
    // onFocus(editor) {
    //   this.html = this.content;
    // },
    onBlur(editor) {
      this.$emit("getContent", editor.getHtml());
    },
    getEditorText() {
      const editor = this.editor;
      if (editor == null) return;
      // console.log(editor.getText()); // 执行 editor API
    },
    printEditorHtml() {
      const editor = this.editor;
      if (editor == null) return;
      // console.log(editor.getHtml()); // 执行 editor API
    },
  },
  mounted() {
    // 模拟 ajax 请求，异步渲染编辑器
    setTimeout(() => {
      this.html = this.content;
    }, 200);
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁 editor ，重要！！！
  },
};
</script>
 
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="less" scoped>
// .Global {
//   width: 120px;
//   height: 40px;
//   line-height: 40px;
//   text-align: center;
//   float: right;
//   background: linear-gradient(270deg, #f0e4fd 0%, #e6e4fd 100%);
//   border-radius: 8px;
//   font-weight: bold;
//   color: #837af3;
//   font-size: 14px;
// }
</style>
<style lang="less">
#wangeditor {
  .w-e-text-container {
    // 文本框里面的层级调低
    z-index: -1 !important;
  }
  
  .w-e-menu {
    // 最重要的一句代码
    z-index: auto !important;

    .w-e-droplist {
      // 触发工具栏后的显示框调高
      z-index: 2 !important;
    }
  }
}
.w-e-toolbar {
    // 给工具栏换行
    flex-wrap: nowrap;
    z-index: 9 !important;
  }
</style>
