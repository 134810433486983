<template>
  <div>
      <div v-show="isPreview" class="posabs" v-loading="loaddding">
          <div class="side-bars">
            <div v-for="item in manualWord" :key="item.id">
              <div class="side-title">
                <span>{{ item.title }}</span>
                <span class="ri-edit-2-line" @click="modifyTitle(1,item.id)" style=" cursor:pointer ;"></span>
              </div>
              <div
                v-for="val in item.sub"
                :key="val.id"
                style=" cursor:pointer;"
                class="side-section"
                :style="[
                  { color: val.id == subvid ? '#fff' : '#333' },
                  { backgroundColor: val.id == subvid ? '#fc5c47' : '#fff' },
                ]"
                @click="getTitle(val.id)"
              >
                <span style=" margin-left: 20px;float:left;">{{ val.title }}</span>
                <img src="../../assets/images/home/形状.png" alt="" style="float:right;" v-if="val.id == subvid"/>
                <span style="float:right;">
                    <span
                    class="el-icon-circle-check"
                    v-if="val.isEdit"
                    style="margin-right:8px;"
                    ></span>
                    <span
                      class="el-icon-circle-plus-outline"
                      v-if="val.isAdd"
                      style="margin-right:8px:"
                    ></span>
                    <span
                      class="el-icon-circle-close"
                      v-if="val.isDelete"
                      style="margin-right:8px:"
                    ></span>
                </span>
              </div>
            </div>
            <div
              v-if="parentId!=0"
              class="side-title newAdd"
              @click="addContent(0)"
              style=" cursor:pointer ;"
              :class="[bgcolor === 0 ? 'newAddShow' : '']"
            >
              <span>增加新章节</span>
            </div>
            <div
              v-if="parentId!=0"
              style=" cursor:pointer ;"
              class="side-title newAdd"
              @click="addContent(1)"
              :class="[bgcolor === 1 ? 'newAddShow' : '']"
            >
              <span>增加新内容</span>
            </div>
            <div
              v-if="parentId!=0"
              style=" cursor:pointer ;"
              class="side-title newAdd"
              @click="addContent(2)"
              :class="[bgcolor === 2 ? 'newAddShow' : '']"
            >
              <span>手册生成</span>
            </div>
          </div>
          <div class="manuslMain" v-if="bgcolor == -1">
            <!-- 标题 -->
            <div
              style="
                margin: -20px -20px -50px -20px;
                height:60px;
                line-height:60px;
                padding-left:20px;
                background: #fff;
                position: relative;
                border-radius: 18px;
                z-index:999;
              "
            >
              <div>
                   <span>手册名称</span
                  ><span style="color: #333333; margin: 0px 14px">{{ manualName }}</span
                  ><i
                    @click="modifyTitle(0)"
                    class="ri-edit-2-line"
                    style=" 
                      cursor:pointer ;
                      display: inline-block;
                      font-size: 14px;
                      margin-right: 10px;
                      color: #6474c7;
                    "
                  ></i>
              </div>
              <!-- 编辑按钮 -->
              <div v-if="!deitShow" style="position: absolute;right: 20px;top:0px;">
                <span
                  @click="EditWord"
                  style="
                    display: inline-block;
                    width: 100px;
                    height: 30px;
                    cursor:pointer ;
                    line-height: 30px;
                    text-align: center;
                    color: #ffffff;
                    background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
                    border-radius: 25px;
                    margin-right: 40px;
                  "
                  >编辑</span
                >
                <span
                  v-if="!recoveryshow"
                  @click="DelMan"
                  style="
                    width: 100px;
                    cursor:pointer ;
                    display: inline-block;
                    color: #6474c7;
                    line-height: 30px;
                    text-align: center;
                    height: 30px;
                    background: #ffffff;
                    border-radius: 25px;
                    border: 1px solid #6474c7;
                    margin-right: 40px;
                  "
                  >删除此部分</span
                >
                <span
                  v-if="recoveryshow || isinitialization"
                  @click="initialization"
                  style="
                    width: 100px;
                    cursor:pointer ;
                    display: inline-block;
                    color: #6474c7;
                    line-height: 30px;
                    text-align: center;
                    height: 30px;
                    background: #ffffff;
                    border-radius: 25px;
                    border: 1px solid #6474c7;
                  "
                  >初始化内容</span
                >

                <span
                   v-if="recoveryshow"
                  @click="recovery"
                  style="
                    width: 100px;
                    display: inline-block;
                    color: #6474c7;
                    cursor:pointer ;
                    line-height: 30px;
                    text-align: center;
                    height: 30px;
                    background: #ffffff;
                    border-radius: 25px;
                    border: 1px solid #6474c7; 
                    margin-left: 40px;
                  "
                  >恢复此部分</span
                >
              </div>
              <!-- 保存，取消 -->
              <div v-else style="text-align: center; position: absolute;right: 20px;top:0px;">
                <span
                  @click="preservation"
                  style="
                    display: inline-block;
                    width: 100px;
                    height: 30px;
                    cursor:pointer ;
                    line-height: 30px;
                    text-align: center;
                    color: #ffffff;
                    background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
                    border-radius: 25px;
                    margin-right: 40px;
                  "
                  >保存</span
                >
                <span
                  @click="cancl"
                  style="
                    width: 100px;
                    display: inline-block;
                    color: #6474c7;
                    line-height: 30px;
                    text-align: center;
                    cursor:pointer ;
                    height: 30px;
                    background: #ffffff;
                    border-radius: 25px;
                    border: 1px solid #6474c7;
                  "
                  >取消</span
                >
              </div>
            </div>
             <div  v-show="!deitShow" style="margin-top:50px;z-index:999;">
                <!-- 工具栏 -->
                <Toolbar
                  style="border-bottom: 1px solid #ccc; width: 100%;"
                  :editor="editor"
                  :defaultConfig="toolbarConfig"
                />
                <!-- 提示 -->
                <div style="margin-top: 12px;padding:0px 10px;  line-height: 40px;overflow: hidden;">
                  <div
                    style="
                      font-weight: bold;
                      color: #333333;
                      height: 40px;
                      line-height: 40px;
                      float: left;
                    "
                  >
                    {{mamaultitle}}
                  </div>
                  <!-- <div @click="GlobalModel" class="Global" style=" 
                  cursor:pointer ;width: 120px;
                  height: 40px;
                  line-height: 40px;
                  text-align: center;
                  float: right;
                  background: linear-gradient(270deg, #f0e4fd 0%, #e6e4fd 100%);
                  border-radius: 8px;
                  font-weight: bold;
                  color: #837af3;
                  font-size: 14px;">{{ GlobalHtml }}</div> -->
                  <div style="height: 40px; line-height: 40px; float: right">
                    <span>请浏览以下制式条款内容，如必要请进一步调整以更加符合您公司的特点和要求。</span>
                  </div>
                </div>
            </div>
            <div style="padding:0px;position: relative;">
              <!-- :style="{ paddingRight: pdpx, paddingLeft: pdpx }" -->
              <!-- 提示 -->
              <!-- <div
                    style="
                      display: flex;
                      justify-content: space-between;
                      margin-top: 12px;
                    "
                  >
                    <span
                      style="
                        font-weight: bold;
                        color: #333333;
                        display: inline-block;
                        height: 40px;
                        line-height: 40px;
                      "
                      >目的与公示形式</span
                    >
                    <span
                      >请浏览以下制式条款内容，如必要请进一步调整以更加符合您公司的特点和要求。<span
                        @click="GlobalModel"
                        class="Global"
                        >{{ GlobalHtml }}</span
                      ></span
                    >
                  </div> -->
              <!-- 编辑器 -->
              <div>
                <MyEditor
                  :tipShow='tipShow'
                  :content="detContent"
                  :mamaultitle="mamaultitle"
                  v-if="deitShow"
                  @getContent="changeCon"
                  :GlobalHtml="GlobalHtml"
                />
              </div>
              <!-- 正文 -->
              <div
                v-show="!deitShow"
                style="
                  margin-top: 12px;
                  background: #ffffff;
                  padding: 8% 6%;
                  color: #333333;
                  height: 100%;
                  overflow: auto;
                  box-shadow: 0px 0px 10px 0px rgba(152, 172, 190, 0.2);
                "
              >
                <div style="display: flex; justify-content: space-between">
                  <span
                    style="
                      width: 41px;
                      height: 41px;
                      display: inline-block;
                      border-right: 1px solid #cdcdcd;
                      border-bottom: 1px solid #cdcdcd;
                    "
                  ></span>
                  <span
                    style="
                      width: 41px;
                      height: 41px;
                      display: inline-block;
                      border-left: 1px solid #cdcdcd;
                      border-bottom: 1px solid #cdcdcd;
                    "
                  ></span>
                </div>
                <div
                  style="padding: 0px 41px; min-height: 600px"
                  class="TextContent"
                ></div>
                <div style="display: flex; justify-content: space-between">
                  <span
                    style="
                      width: 41px;
                      height: 41px;
                      display: inline-block;
                      border-right: 1px solid #cdcdcd;
                      border-top: 1px solid #cdcdcd;
                    "
                  ></span>
                  <span
                    style="
                      width: 41px;
                      height: 41px;
                      display: inline-block;
                      border-left: 1px solid #cdcdcd;
                      border-top: 1px solid #cdcdcd;
                    "
                  ></span>
                </div>
              </div>
              
              <!-- 右侧弹窗 -->
              <el-tooltip
                class="item"
                effect="dark"
                content="查看法条与政策解读。"
                placement="left"
              >
                <img
                  v-if="!drawer"
                  @click="drawerShow(0)"
                  src="../../assets/images/home/按钮-展开2.png"
                  alt=""
                  class="openCard"
                />
              </el-tooltip>
            </div>
          </div>
          <div class="Drawer" v-if="drawer">
            <div>
              <div class="top-box">
                <button @mousedown="downMouse" @mouseup="upMouse" >按住拖拽<span style="position: absolute;right:20px;" @click="drawerShow(1)">收起</span></button>
                
              </div>
             <div style="margin-top:40px;padding:0px 20px;">
                   <div style="position: relative;padding-top: 20px;">
                      <input
                        type="text"
                        placeholder="请输入关键词"
                        v-model="keyWord"
                        @blur="ftSearch()"
                        @keyup.enter="ftSearch()"
                      /><i class="ri-search-line" v-if="!keyWord"></i>
                  </div>
             </div>
             <div class="youcheDrag">
                <el-tabs v-model="activeName">
                  <el-tab-pane
                    label="本条解释"
                    name="drafirst"
                    v-loading="dataloadingfirst"
                  >
                    <div>选择章节：{{ subtitle }}</div>
                    <div class="dranr" v-html="law"></div>
                    <div
                      class="emptydata"
                      v-if="law == '' || law == null"
                      description="暂无数据"
                      :image-size="150"
                    >
                      暂无数据
                    </div>
                  </el-tab-pane>
                  <el-tab-pane
                    :label="fatiaoTips + '(' + `${fatiaocount}` + ')'"
                    name="drasecond"
                    v-loading="dataloading"
                  >
                    <div>选择章节：{{ subtitle }}</div>
                    <div
                      class="dranr2 infinite-list"
                      v-for="(ftitem, index) in fatiaodata"
                      v-infinite-scroll="fatiaoload"
                      v-show="isShowfilelist"
                      :key="index"
                    >
                      <div class="drafatiao infinite-list-item">
                        <h2>{{ ftitem.title }}</h2>
                        <p v-html="ftitem.remark"></p>
                        <el-link
                          style="color: #6474c7"
                          type="primary"
                          :underline="false"
                          @click="ftdetail(ftitem.sourceUrl)"
                          >查看来源</el-link
                        >
                      </div>
                    </div>
                    <div
                      class="emptydata"
                      v-if="fatiaocount == 0"
                      description="暂无数据"
                      :image-size="150"
                    >
                      暂无数据
                    </div>

                    <!--文件详情-->
                    <div class="filedetailshow" v-show="isShowfiledetail">
                      <el-link
                        type="primary"
                        :underline="false"
                        @click="lookFatiaoClose"
                        ><i class="el-icon-arrow-left"></i> 返回</el-link
                      >
                      <div class="filedetailshownr">
                        <h2 class="filedetailshownrtitle">{{ fatiaotitle }}</h2>
                        <div
                          id="fatiaocontent2"
                          @mouseup="handleMouseSelect"
                          v-html="fatiaocontent"
                        ></div>
                        <p class="btn" style="margin-top: 15px">
                          <el-button round @click="lookFatiaoClose">返回</el-button>
                          <el-link
                            style="color: #6474c7"
                            v-if="isfatiaosourceurl == 1"
                            type="primary"
                            class="yuanwen"
                            :underline="false"
                            @click="ftsourceUrl(fatiaosourceurl)"
                            >查看来源</el-link
                          >
                        </p>
                      </div>
                    </div>
                  </el-tab-pane>
                  <el-tab-pane
                    :label="canshuTips + '(' + `${canshucount}` + ')'"
                    name="drathird"
                    v-loading="dataloading"
                  >
                    <div>选择章节：{{ subtitle }}</div>
                    <div
                      class="dranr2 infinite-list"
                      v-for="(pmitem, index) in canshudata"
                      :key="index"
                    >
                      <div class="drafatiao">
                        <h2>{{ pmitem.province }} {{ pmitem.title }}</h2>
                        <p v-html="pmitem.content"></p>
                        <el-link
                          type="primary"
                          :underline="false"
                          @click="canshuCopy(pmitem)"
                          >复制</el-link
                        >
                      </div>
                    </div>
                    <div
                      class="emptydata"
                      v-if="canshucount == 0"
                      description="暂无数据"
                      :image-size="150"
                    >
                      暂无数据
                    </div>
                  </el-tab-pane>
                </el-tabs>
             </div>
              
            </div>
          </div>
          <div
            v-else-if="bgcolor == 0"
            class="manuslMain"
            style="background: #ffffff; padding: 60px"
          >
            <div style="margin-bottom: 20px">请填写章节标题名称</div>
            <div style="margin-bottom: 40px">
              <label>章节标题：</label
              ><el-input
                v-model="newSideTitle"
                placeholder="请填写章节标题"
                style="width: 600px; height: 44px; border-radius: 6px"
              ></el-input>
            </div>
            <div style="margin-bottom: 20px">请选择章节与插入的位置</div>
            <div style="margin-bottom: 40px">
              <el-radio v-model="Sideradio" label="0">插入在选择项之前</el-radio>
              <el-radio v-model="Sideradio" label="1">插入在选择项之后</el-radio>
            </div>
            <div style="margin-bottom: 103px">
              <label>选择章节：</label>
              <el-select
                v-model="sideOptionsTitle"
                placeholder="请填写章节标题"
                @change="CheckTitle"
                style="
                  width: 600px;
                  height: 44px;
                  background: #ffffff;
                  border-radius: 6px;
                "
              >
                <el-option
                  v-for="item in sideOptions"
                  :key="item.id"
                  :label="item.title"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <div>
              <button
                @click="backStep"
                style="
                  width: 120px;
                  height: 40px;
                  background: #eaf5ff;
                  border: none;
                  display: inline-block;
                  border-radius: 25px;
                  font-size: 14px;
                  color: #6474c7;
                  line-height: 40px;
                  text-align: center;
                  margin-right: 452px;
                "
              >
                返回
              </button>
              <button
                @click="addSideTitle"
                style="
                  width: 120px;
                  height: 40px;
                  display: inline-block;
                  line-height: 40px;
                  text-align: center;
                  border: none;
                  background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
                  border-radius: 25px;
                  color: #ffffff;
                  font-size: 14px;
                "
              >
                保存设置
              </button>
            </div>
          </div>
          <div
            v-else-if="bgcolor == 1"
            class="manuslMain"
            style="background: #ffffff; padding: 60px"
          >
            <div>
              <div style="display: inline-block; margin-right: 110px">
                <div style="color: #6474c7; color: #6474c7; margin-bottom: 20px">
                  请选择章节
                </div>
                <div>
                  <label style="color: #909399">选择章节：</label
                  ><el-select
                    v-model="sideOptionsTitle"
                    @change="CheckTitle"
                    placeholder="请选择章节"
                    style="
                      width: 540px;
                      height: 44px;
                      background: #ffffff;
                      border-radius: 6px;
                    "
                  >
                    <el-option
                      v-for="item in sideOptions"
                      :key="item.id"
                      :label="item.title"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div style="display: inline-block">
                <div style="color: #6474c7; color: #6474c7; margin-bottom: 20px">
                  请填写标题内容
                </div>
                <div style="color: #909399">
                  <label style="color: #909399">标题内容：</label
                  ><el-input
                    v-model="contenttitle"
                    placeholder="请填写内容"
                    style="
                      width: 540px;
                      height: 44px;
                      background: #ffffff;
                      border-radius: 6px;
                    "
                  ></el-input>
                </div>
              </div>
            </div>
            <div style="color: #6474c7; color: #6474c7; margin-top: 40px">
              内容：<span
                @click="addSection"
                style="
                  width: 120px;
                  height: 40px;
                  display: inline-block;
                  line-height: 40px;
                  text-align: center;
                  border: none;
                  background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
                  border-radius: 25px;
                  color: #ffffff;
                  font-size: 14px;
                  margin-left: 684px;
                  cursor:pointer ;
                "
                >保存</span
              >
            </div>
            <div>
              <MyEditor
                :tipShow='tipShow'
                :content="detContent"
                @getContent="changeCon"
                :GlobalHtml="GlobalHtml"
              />
            </div>
          </div>
          <div
            v-else-if="bgcolor == 2"
            class="manuslMain"
            style="background: #ffffff; padding: 60px"
          >
            <div style="color: #333">
              <p>
                当您定制化并编辑完您的员工手册，您就可以点击“生成”按钮生成你的员工手册。发布后的最新版的员工手册将以WORD和PDF的版本出现在“协作空间”页面，
                同时自动保存在“已生成手册”的历史记录页面：
              </p>
              <p>
                1、您可以打印员工手册，并以纸质版的形式发放给您的员工签收，并阅读学习。
              </p>
              <p>
                2、您也可以通过HRPP的文件管理平台，授权您的员工以用户名密码的形式登录，
                让员工随时随地登录方便地在线阅读和学习员工手册。网站同时会做出告知员工和员工已阅读学习的签收记录。
              </p>

              <p>
                因为公司制度调整等原因，如果您将来需要更改手册，您可以随时返回网站，在您的使用期间编辑手册并重新发布您修改后的文本。
              </p>
            </div>
            <div style="margin-top: 100px">
              <span
                v-loading="loaddd"
                @click="generate"
                style="
                  width: 160px;
                  height: 40px;
                  float:right;
                  text-align:center;
                  line-height:40px;
                  display:inline-block;
                  background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
                  border-radius: 25px;
                  color: #FFFFFF;
                  cursor:pointer
                "
                >生成员工手册</span
              >
              <span
                @click="preview()"
                style="
                  width: 160px;
                  height: 40px;
                  float:right;
                  background: #ffffff;
                  border-radius: 25px;
                  border: 1px solid #6474c7;
                  color: #6474C7;
                  line-height:40px;
                  text-align:center;
                  display:inline-block;
                  margin-right:60px;
                  cursor:pointer
                "
                >预览员工手册</span
              >
            </div>
          </div>
      </div>
      <div v-show="!isPreview">
          <b-col lg="12" v-loading="loading">
            <iq-card style="background:#F8F9FA;">
                <div style="background:#FFF;line-height:51px;padding:0px 14px;border-radius: 15px 15px 0px 0px;white-space: wrap;">
                    <span style="color: #909399;margin-right:14px;">文件名称</span>
                    <span style="color: #333333;margin-right:18%;">{{manualName}}</span>
                    <span 
                        style="margin-right:40px;
                          display:inline-block;
                          width: 30px;
                          height: 30px;
                          background: #F8F9FA;
                          position: relative;left: 0px;top: 10px;
                          border: 1px solid #E2E6ED;">
                      <i class="ri-arrow-up-s-fill" style=" position: absolute;left: 3px;top: -15px;font-size:22px; cursor:pointer" @click="Add"></i>
                      <i class="ri-arrow-down-s-fill" style=" position: absolute;left: 3px;top: -4px;font-size:22px;cursor:pointer" @click="reduce"></i>
                    </span>
                    <span 
                      @click="imgrotate"
                      style="
                      width: 30px;
                      height: 30px;
                      display:inline-block;
                      background: #F8F9FA;
                      border: 1px solid #E2E6ED;
                       cursor:pointer;
                      position: relative;left: 0px;top: 10px;margin-right:3%; ">
                        <i class="ri-anticlockwise-2-line" style="font-size:22px;position: absolute;left: 4px;top: -10px;font-size:22px"></i>
                    
                    </span>
                    
                    <i class="ri-skip-back-mini-fill" style="margin-right:30px;font-size:22px;cursor:pointer" @click="Add"></i>
                    <i class="ri-arrow-left-s-line" style="margin-right:30px;font-size:22px; cursor:pointer" @click="Add"></i>
                    <span style="margin-right:30px;width: 100px;
                                  height: 30px;
                                  line-height:30px;
                                  text-align:center;
                                  background: #F8F9FA;
                                  border-radius: 4px;
                                  display:inline-block;
                                  border: 1px solid #E2E6ED;">
                                  <span style="color: #333333;">{{initial}}</span>/{{Photo.length}}
                    </span>
                    <i class="ri-arrow-right-s-line" style="margin-right:30px;font-size:22px; cursor:pointer" @click="reduce"></i>
                    <i class="ri-skip-forward-mini-fill" style="margin-right:80px;font-size:22px; cursor:pointer" @click="reduce"></i>
                    <i class="ri-subtract-line" style="font-size:22px; cursor:pointer" @click="narrow"></i>
                    <span style="margin:0px 30px;
                              width: 100px;
                              height: 30px;
                               line-height:30px;
                              text-align:center;
                              display:inline-block;
                              background: #F8F9FA;
                              border-radius: 4px;
                              color: #333333;
                              border: 1px solid #E2E6ED;
                              ">{{scale}}%</span>
                    <i class="ri-add-line" style="font-size:22px; cursor:pointer;margin-right:2%" @click="enlarge"></i>
                    <i class="ri-arrow-go-back-line" style="font-size:22px; cursor:pointer" @click="goback"></i>
                </div>
                <div class="mainBox">
                  <div class="PhotoLeft">
                    <div style="margin-bottom:30px;text-align:center;" v-for="(item,index) in Photo" :key="index" @click="CLICKPhoto(index)">
                          <div ref="borShow" style="width: 224px;height: 308px;"> 
                              <img :src="item" alt="" style="width: 216px;height: 300px;">
                          </div>
                          <span style="margin-top:10px;" ref="borColor">第{{index+1}}页</span>
                    </div>
                  </div>
                <div class="PhotoRight">
                  <div style=" overflow:hidden; width: calc(100% - 12px);" class="rightScorll"> 
                   <div style="text-align:center;margin-bottom:30px;margin-right:-17px" v-for="(item,index) in Photo" :key="index" class="scrollHe">
                          <img :src="item" alt="" style="width: 100%;" class="imgWidth">
                          <!-- <div style="height:30px;background:#F8F9FA;margin:0px -50px"></div> -->
                    </div>
                  </div>
                  </div>
                </div>
            </iq-card>
          </b-col>
      </div>
  </div>
</template>

<script>
import Tdrag from "@/Utils/Tdrag";
import MyEditor from "./details/MyEditor.vue";
import { Toolbar } from "@wangeditor/editor-for-vue";
import { DomEditor } from "@wangeditor/editor";
import { viewInitEditor } from "@/Utils/viewonlineEdit";
import {
  EditManual,
  upManual,
  DelManual,
  recoveryManaul,
  getSection,
  getlabourlaw,
  generateWord,
  addSection,
  Addpreview,
  upTitlte,
  addSideTitle,
  initialization
} from "@/FackApi/api/employee-handbook";
export default {
  components: {
    MyEditor,Toolbar,
    "remote-js": {
      render(createElement) {
        return createElement("script", {
          attrs: { type: "text/javascript", src: this.src },
        });
      },
      props: {
        src: { type: String, required: true },
      },
    },
  },
  data() {
    return {
      editor: null,
       mode: 'default', 
       toolbarConfig: {
        /* 显示哪些菜单，如何排序、分组 */
        toolbarKeys: [
          "undo",
          "redo",
          "|",
          "headerSelect",
          "fontFamily",
          "fontSize",
          "|",
          "bold",
          "italic",
          "underline",
          "color",
          "bgColor",
          "|",
          "bulletedList",
          "numberedList",
          {
            iconSvg:
              '<svg viewBox="0 0 1024 1024"><path d="M768 793.6v102.4H51.2v-102.4h716.8z m204.8-230.4v102.4H51.2v-102.4h921.6z m-204.8-230.4v102.4H51.2v-102.4h716.8zM972.8 102.4v102.4H51.2V102.4h921.6z"></path></svg>',
            key: "group-justify",
            menuKeys: [
              "justifyLeft",
              "justifyRight",
              "justifyCenter",
              "justifyJustify",
            ],
            title: "对齐",
          },
          {
            iconSvg:
              '<svg viewBox="0 0 1024 1024"><path d="M0 64h1024v128H0z m384 192h640v128H384z m0 192h640v128H384z m0 192h640v128H384zM0 832h1024v128H0z m0-128V320l256 192z"></path></svg>',
            key: "group-indent",
            menuKeys: ["indent", "delIndent"],
          },
          "divider",
          // {
          //   iconSvg:
          //     '<svg viewBox="0 0 1024 1024"><path d="M959.877 128l0.123 0.123v767.775l-0.123 0.122H64.102l-0.122-0.122V128.123l0.122-0.123h895.775zM960 64H64C28.795 64 0 92.795 0 128v768c0 35.205 28.795 64 64 64h896c35.205 0 64-28.795 64-64V128c0-35.205-28.795-64-64-64zM832 288.01c0 53.023-42.988 96.01-96.01 96.01s-96.01-42.987-96.01-96.01S682.967 192 735.99 192 832 234.988 832 288.01zM896 832H128V704l224.01-384 256 320h64l224.01-192z"></path></svg>',
          //   key: "group-image",
          //   menuKeys: ["insertImage", "uploadImage"],
          //   title: "图片",
          // },
        ],
        // excludeKeys: [ ], /* 隐藏哪些菜单 */
      },
      scale:100,
      scaleca:1,
      rotate:0,
      throttle:false,//节流
      tipShow:true,
      insertId:0,//章节id
      isLookWrit: false,
      sideOptionsTitle:'',
      sideOptions: "", //新增章节选中章节
      chapterOptions: "", //新增内容选中章节
      contenttitle: "",
      contentSlect: "",
      sideOptions: "", //章节列表
      newSideTitle: "",//新增章节name
      Sideradio: "1",
      bgcolor: -1, //背景颜色
      parentId: 0,
      subvid: 0,
      PageIndex: 1,
      PageSize: 10,
      tid: 0,
      manualWord: [],
      mamaultitle: "",
      subtitle: "",
      detContent: "", //编辑器展示内容
      deitShow: false, //编辑器显示
      pdpx: "10%", //全局样式
      GlobalHtml: "进入全局模式",
      drawer: false,
      keyWord: "", //关键字
      activeName: "drafirst",
      law: "",
      fatiaodata: [], //获取的法律法规数组
      fatiaocount: 0, //法条数量
      fatiaoTips: "法条法规",
      canshudata: [], //获取的章节对应的参数数组
      canshucount: 0, //参数数量
      fatiaopage: 1, //页码
      fatiaopages: 0, //总页码
      fatiaoKeywords: "", //法条搜索关键词
      isLookfatiao: false, //显示隐藏 法条详情
      fatiaotitle: "", //法条标题
      fatiaocontent: "", //法条内容
      canshuTips: "参数",
      isShowLookFP: false, //是否显示 查看法条参数图标
      fatiaourl: "", //法条源文件地址pdf
      isfatiaopdf: 0, //0 不是，1 是
      fatiaosourceurl: "", //法条 原文地址
      isfatiaosourceurl: 0, //是否有原文地址
      dataloading: false, //数据加载提示
      dataloadingfirst: false, //法条解释
      isShowfiledetail: false, //显示文件详情
      isShowfilelist: true, //显示文件列表
      recoveryshow: false,
      isinitialization: false,
      manualName: "", //手册名称
      isPreview:true,//预览显示
      Photo:[],//照片列表
      initial:0,//初始页
      loading:false,//加载
      loaddd:false,//生成加载
      loaddding:false,
      scrollTop :0,
    };
  },
  mounted() {
    let id = this.$parent.manualId;
    this.parentId = id;
    this.getEditman(id);
    this.CLICKPhoto(this.initial)
  },
  watch: {
    subvid: {
      handler(newVal, oldVal) {
        this.getTitle(newVal);
        this.getlabour(this.alwdata);
      },
    },
  },
  methods: {
    // 选择章
    CheckTitle(val){
      // console.log(val);
      this.sideOptionsTitle = val.title
      this.insertId = val.id
    },
    // 添加章
    addSideTitle(){
      let data = {
           "position": this.Sideradio,// 位置：0 插入选择点之前；1 插入选择点之后
           "insertId": this.insertId,//选择的章ID
           "title": this.newSideTitle//标题
      }
      addSideTitle(data).then(res=>{
        // console.log(res);
        if(res.code==0){
           this.getEditman(this.parentId )
           this.bgcolor = -1;
            this.getTitle(this.subvid);
          this.$message({
            type:'success',
            message:res.msg,
             offset:400,
              duration:800
          })
        }
      })
    },
    // 添加内容
    addSection(){
      let data = {
          "employeeHandbookSetId": this.parentId,//手册设置记录ID
          "title": this.contenttitle,
          "explain": "无解释",
          "remark": this.detContent,
          "parentId":  this.insertId//章ID
      }
      addSection(data).then(res=>{
        // console.log(res);
        if(res.code==0){
          this.getEditman(this.parentId )
          this.bgcolor = -1;
          this.getTitle(this.subvid);
           this.$message({
            type:'success',
            message:res.msg,
             offset:400,
              duration:800
          })
        }
      })
    },
    //
    lookWord(item) {
      console.log(item);
      this.fileInfo = item;
      this.isLookWrit = true;
      this.createId = item.id;
      this.$nextTick(() => {
        let el = document.getElementById('box')
        el.innerHTML = '<div id="placeholder"></div>';
        viewInitEditor(
          item.title,
          item.path,
          true,
          true,
          true,
          true,
          String(item.employeeHandbookSetId),
          "eide",
          "desktop|mobile",
          {
            comment: true,
            download: false,
            edit: true,
            print: false,
            review: true,
          },
          item.title,
          this.COOKIE_TOKEN,
          100
        );
      });
    },
    // 预览返回
    goback(){
       this.isPreview = true
    },
    // 左边导航点击
    CLICKPhoto(index){
       setTimeout(() => {
            this.initial = index+1
            let oDiv = this.$refs.borShow? this.$refs.borShow:[]
            let oSpan = this.$refs.borColor?this.$refs.borColor:[]
            for (let i = 0; i < oDiv.length; i++) {
              oDiv[i].classList.remove('bord')
            }
            for (let i = 0; i < oSpan.length; i++) {
              oSpan[i].classList.remove('borColor')
            }
            oDiv[index].classList.add('bord')
            oSpan[index].classList.add('borColor')
            let scrollHe = document.getElementsByClassName('scrollHe')[0].clientHeight
            let imgHe = document.getElementsByClassName('imgWidth')[0].clientHeight
            if(index==1){
                document.getElementsByClassName('PhotoLeft')[0].scrollTop = index*392
                document.getElementsByClassName('PhotoRight')[0].scrollTop = index*(imgHe+30)
            }else if(index==0){
                document.getElementsByClassName('PhotoLeft')[0].scrollTop = 0
                document.getElementsByClassName('PhotoRight')[0].scrollTop = 0
            }else{
                document.getElementsByClassName('PhotoLeft')[0].scrollTop = ((index-1)*362) + 392
                document.getElementsByClassName('PhotoRight')[0].scrollTop = (index - 1)*(imgHe + 30) + imgHe+30
            }
       }, 200);
    },
    // 上一页
    Add(){
      if(this.initial == 1){
        return
      }
      this.initial = this.initial-1==0?1:this.initial-1
      let index = this.initial-1==0?0:this.initial-1
      if(this.initial<=0){
          this.initial = 1
      }
      this.CLICKPhoto(index)
    },
    // 下一页
    reduce(){
      let index = this.initial+1>=this.Photo.length?this.Photo.length-1:this.initial
      this.CLICKPhoto(index)
    },
    // 新增内容点击
    addContent(val) {
      this.bgcolor = val;
      this.detContent = "";
      this.tipShow = false
      this.drawer = false
    },
    // 返回
    backStep() {
      this.bgcolor = -1;
      this.getTitle(this.subvid);
    },
    // 生成文件
    generate() {
      this.loaddd = true
      let than = this;
      this.throttle = true
      generateWord(this.parentId).then((res) => {
        // console.log(res);
        if (res.code == 0) {
          this.throttle = false
          this.loaddd =false
          this.$message({
            type: "success",
            message: res.msg,
             offset:400,
              duration:800
          });
          than.$parent.tabClick("0");
        }
      });
    },
    // 预览
    preview(){
      if(this.Photo && this.Photo.length!=0){
        this.isPreview = false
      }else{
        this.isPreview = false
        this.loading=true
        // console.log(this.$parent.ListId);
        Addpreview(this.$parent.ListId).then(res=>{
          console.log(res);
          if(res.code==0){
            this.loading=false
            this.Photo = res.data
          }
        })
      }
    },
    // 获取展示数据
    getEditman(id) {
      // console.log(id);
      if(!id){
        setTimeout(() => {
          this.$message({
            type:'error',
            message:'暂无相关手册文件或者文件已协作交付，选择客户列表在线编辑进入！',
            offset:400,
            duration:800
        })
        }, 20);
        this.$parent.tabClick("0");
        return
      }
      this.loaddding = true
      EditManual(id).then((res) => {
        // console.log(res);
        if(res.code==404){
           this.$parent.tabClick("0");
           return
        }
        this.loaddding = false
        this.manualName = res.title;
        this.manualWord = res.data;
        this.sideOptions = res.data
        if( res.data[0].sub.length>0){
            let vid = res.data[0].sub[0].id;
            this.subvid = vid;
        }
      });

      this.getHtml();
    },
    // 节选择
    getTitle(vid) {
      this.tipShow = true
      this.bgcolor = -1;
      this.subvid = vid;
      this.dataloading = true;
      getSection(vid).then((res) => {
        // console.log(res);
        if (res.code == 0) {
          this.dataloading = false;
          this.law = res.data.explain;
          this.detContent = res.data.remark;
          this.mamaultitle = res.data.title;
          this.subtitle = res.data.title;
          this.getHtml();
          this.deitShow = false;
          this.getlabour();
          EditManual(this.parentId).then((res) => {
            // console.log(res);
            this.manualWord = res.data;
          });
          this.recoveryshow = res.data.isDelete == 1 ? true : false;
          this.isinitialization = res.data.isEdit == 1 ? true : false;
        }
      });
      // this.getEditman(19)
    },
    //法条搜索
    ftSearch() {
      this.getlabour()
    },
    //获取法规文件详情
    ftdetail(secid) {
      var link = document.createElement('a')
      link.href = secid
      link.target = '_blank'
      link.click()
    },
    //关闭法条详情
    lookFatiaoClose() {
      this.isShowfilelist = true;
      this.isShowfiledetail = false;
    },
    //查看法规文件原文地址或源文件
    ftsourceUrl(surl) {
      console.log();
      window.open(surl, "_blank");
    },
    //点击复制参数信息
    canshuCopy(item) {
      //去除html格式
      let content = item.content.replace(/<[^>]*>/g, "");
      this.link = item.title + "：" + content;
      let that = this;
      this.$copyText(this.link).then(
        function (e) {
          that.$notify({
            title: "复制成功!",
            type: "success",
          });
        },
        function (e) {
          that.$notify({
            title: "复制失败!",
            type: "error",
          });
        }
      );
    },
    // 鼠标按下
    downMouse() {
      this.$nextTick(() => {
        $(".Drawer").Tdrag(
        );
      });
    },
    // 鼠标松开
    upMouse() {
      $.disable_cloose()
    },
    //复制鼠标选中的文字
    handleMouseSelect() {
      let text = window.getSelection().toString();
      this.link = text;
      let that = this;
      this.$copyText(this.link).then(
        function (e) {
          that.$notify({
            title: "已复制选中的文字!",
            type: "success",
          });
        },
        function (e) {
          that.$notify({
            title: "复制失败!",
            type: "error",
          });
        }
      );
    },
    //滚动加载 法律法规
    fatiaoload() {
      this.fatiaopage = this.fatiaopage + 1;
      if (this.fatiaopage <= this.fatiaopages) {
        //----获取相关法律法规、参数
        let params = {
          pageIndex: this.fatiaopage,
          pageSize: 10,
          keyword: this.fatiaoKeywords,
          cityId: 487,
          cityName: "",
          secId: this.nodeId,
        };
        this.$api.getEmployeeLawSearchList(params).then((res) => {
          this.fatiaodata = this.fatiaodata.concat(res.data);
        });
        //----end
      } else {
        return;
      }
    },
    // 自定义
    changeCon(val) {
      this.detContent = val;
      this.getHtml();
    },
    // 保存
    preservation() {
      console.log(666);
      this.deitShow = false;
      let data = {
        id: this.subvid,
        title: this.subtitle,
        remark: this.detContent,
        isEdit: 1,
      };
      upManual(data).then((res) => {
        // console.log(res);
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: res.msg,
             offset:400,
              duration:800
          });
        }
      });
    },
    //删除
    DelMan() {
      DelManual(this.subvid).then((res) => {
        // console.log(res);
        if (res.code == 0) {
          this.deitShow = false;
          this.getTitle(this.subvid);
          this.$message({
            type: "success",
            message: res.msg,
             offset:400,
              duration:800
          });
        }
      });
    },
    // 恢复
    recovery() {
      recoveryManaul(this.subvid).then((res) => {
        if (res.code == 0) {
          // console.log(res);
          this.getTitle(this.subvid);
          this.$message({
            type: "success",
            message: res.msg,
             offset:400,
              duration:800
          });
        }
      });
    },
    // 内容初始化
    initialization(){
      initialization(this.subvid).then((res) => {
        if (res.code == 0) {
          console.log(res);
          this.getTitle(this.subvid);
          this.$message({
            type: "success",
            message: res.msg,
             offset:400,
              duration:800
          });
        }
      });
    },
    // /弹窗
    drawerShow(val) {
      if (val == 1) {
        this.drawer = false;
      } else {
        this.drawer = true;
      }
    },
    // 获取劳动法规信息
    getlabour() {
      let data = {
        vid: this.subvid,
        PageSize: 999,
        PageIndex: 1,
        Keyword:this.keyWord
      };
      getlabourlaw(data).then((res) => {
        // console.log(res,66);
        if (res.code == 0) {
          this.fatiaodata = res.data;
          this.fatiaocount = res.data.length;
        }
      });
    },
    getHtml() {
      let word = document.getElementsByClassName("TextContent")[0];
      if(word){
           word.innerHTML = this.detContent;
          let p = word.getElementsByTagName("p");
          for (let i = 0; i < p.length; i++) {
            p[i].style.textIndent = "2em";
          }
      }
     
    },
    // 修改章节名称
    modifyTitle(val,id) {
      if (val == 0) {
        this.$prompt("请输入修改后的名称", "温馨提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(({ value }) => {
            let data = {
              "id": this.parentId,
              "title": value
            }
            upTitlte(data).then(res=>{
              // console.log(res);
              if(res.code==0){
                this.getEditman(this.parentId)
                  this.$message({
                  type: "success",
                  message: "名称已修改为: " + value,
                   offset:400,
                  duration:800
                });
              }
            })
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "取消输入",
               offset:400,
              duration:800
            });
          });
      } else {
        this.$prompt("请输入修改后的标题", "温馨提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(({ value }) => {
            let data = {
              id: id,
              title: value,
              isEdit: 1,
            }
            upManual(data).then(res=>{
              // console.log(res);
              if(res.code==0){
                this.getEditman(this.parentId)
                  this.$message({
                  type: "success",
                  message: "标题已修改为: " + value,
                   offset:400,
                  duration:800
                });

              }
            })
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "取消输入",
               offset:400,
              duration:800
            });
          });
      }
    },
    // 编辑
    EditWord() {
      this.deitShow = true;
    },
    // 全局
    // GlobalModel() {
    //   if (this.pdpx == "0px") {
    //     this.pdpx = "10%";
    //     this.GlobalHtml = "进入全局模式";
    //   } else {
    //     this.pdpx = "0px";
    //     this.GlobalHtml = "退出全局模式";
    //   }
    // },
    // 取消
    cancl() {
      this.deitShow = false;
      this.getHtml();
    },
    // 放大
    enlarge(){
        let imgHe = document.getElementsByClassName('imgWidth')
        this.scale = this.scale+10
        this.scaleca = this.scaleca +0.1
        for (let i = 0; i < imgHe.length; i++) {
          imgHe[i].style.transform = `scale(${this.scaleca})`
        }
    },
    // 缩小
    narrow(){
      let imgHe = document.getElementsByClassName('imgWidth')
      this.scale = this.scale-10
      this.scaleca = this.scaleca -0.1
      for (let i = 0; i < imgHe.length; i++) {
        imgHe[i].style.transform = `scale(${this.scaleca})`
      }
    },
    // 旋转
    imgrotate(){
      let imgHe = document.getElementsByClassName('imgWidth')
      this.rotate = this.rotate + 90
      for (let i = 0; i < imgHe.length; i++) {
        imgHe[i].style.transform = `rotate(${this.rotate}deg)`
      }
    },
  },
};
</script>
<style lang="less" scoped>
.top-box {
      position: absolute;
      top: 0;
      left: 0;
      width: 374px;
      border-radius: 18px 18px 0 0;
      overflow: hidden;
      button {
        width: 100%;
        height: 40px;
        font-size: 18px;
        background-color: #6a87d2;
        color: #fff;
        border: none;
      }
    }

.PhotoLeft{
    width: 320px;
    height: calc(100% - 30px);
    background: #E0E4E8;
    display:inline-block;
    padding:30px 52px;
    overflow: auto;
}
.PhotoRight{
  width: calc(100% - 622px);
  height: calc(100% - 30px);
  display:inline-block;
  background: #F8F9FA;
  box-shadow: 0px 0px 10px 0px rgba(152,172,190,0.2);
  margin:26px 150px 0px 150px;
  overflow: auto;
}

 /*滚动条样式*/
 ::-webkit-scrollbar{
  display: none;
 }
 ::-webkit-scrollbar-track{
  background: #fff ;
 }
 ::-webkit-scrollbar-thumb {
    background: #fff ;
 }
 .youcheDrag{
  overflow-y: scroll;
  padding: 0px 20px;
  height:500px;
  &::-webkit-scrollbar{
  display: inline !important;
 }
  &::-webkit-scrollbar-track{
    background: #e8e8e8 !important;
  }
  &::-webkit-scrollbar-thumb {
      background: #6a87d2 !important;
  }
}
.bord{
  border: 4px solid #79B9F1;
}
.borColor{
  color: #6474C7;
}
.newAddShow {
  background: #fc5c47 !important;
}
.newAdd {
  margin-bottom: 2px;
}
.posabs {
  width: 100%;
  height: calc(100vh - 0px);
  overflow: auto;
  display: flex;
  margin-bottom: 30px;
  border-radius: 8px;
  justify-content: flex-start;
  .side-bars {
    width: 220px;
    background: #ffffff;
    // max-height: 1885px;
    overflow: auto;
    box-shadow: 0px 0px 10px 0px rgba(152, 172, 190, 0.2);
    border-radius: 16px;
    margin: -4px 30px 0px 20px;
    line-height: 35px;
    font-size: 14px;
    color: #333333;
    .side-title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      color: #ffffff;
      height: 35px;
      padding: 0px 10px 0px 35px;
      background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
    }
    .side-section {
      width: 100%;
      overflow: hidden;
      padding-left: 15px;
      color: #ffffff;
      background: #fc5c47;
      height: 35px;
      line-height: 35px;
    }
    .el-icon-circle-check {
      margin-right: 6px;
      color: #6474c7;
    }
    .el-icon-circle-plus-outline {
      color: #45cfa0;
      margin-right: 6px;
    }
    .el-icon-circle-close {
      margin-right: 6px;
      color: #fc5c47;
    }
    .ri-pushpin-line {
      width: 15px;
      height: 15px;
      display: inline-block;
      color: #fc5c47;
    }
    img {
      display: inline-block;
      width: 15px;
      height: 15px;
      margin: 10px 4px 10px 0px;
    }
  }
}
.manuslMain {
  position: relative;
  flex: 1;
  margin: 0px 20px 0px 0px;
  background: #fff;
  padding: 20px;
  border-radius: 18px;
  max-height: 1885px;
  overflow: auto;
}
// .Global {
//   width: 120px;
//   height: 40px;
//   line-height: 40px;
//   text-align: center;
//   display: inline-block;
//   background: linear-gradient(270deg, #f0e4fd 0%, #e6e4fd 100%);
//   border-radius: 8px;
//   font-weight: bold;
//   color: #837af3;
//   font-size: 14px;
// }
.openCard {
  position: fixed;
  right: 15px;
  top: 50%;
}
.Card1 {
  right: 0;
}
.Drawer {
  // cursor: all-scroll;
  position: fixed !important;
  z-index: 999;
  height: 600px;
  right: 0px;
  top: 30px;
  width: 374px !important;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(152, 172, 190, 0.2);
  border-radius: 18px;
  max-height: 1700px;
  input {
    width: 312px;
    height: 40px;
    background: #eaf5ff;
    border-radius: 8px;
    border: none;
    text-indent: 2em;
  }
  .ri-search-line {
    position: absolute;
    left: 15px;
    top: 29px;
  }
}
/deep/.el-tabs__item {
  font-weight: bold;
}
/deep/ .el-tabs__item.is-active {
  color: #837af3;
}
/deep/.el-tabs__item:hover {
  color: #837af3;
  cursor: pointer;
}
/deep/ .el-tabs__active-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  // width: 104px !important;
  height: 4px;
  background: linear-gradient(270deg, #f0e4fd 0%, #e6e4fd 100%);
  border-radius: 2px 2px 0px 0px;
  z-index: 1;
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  list-style: none;
}
</style>
<style lang="less" scoped>
.draTabs {
  width: 100%;
  margin: 0 auto;
}
.main .draTabs ::v-deep .el-tabs__header {
  margin-bottom: 0px;
}
.main .draTabs ::v-deep .el-tabs__nav {
  width: 100%;
  font-family: MicrosoftYaHei;
}
.main .draTabs ::v-deep .el-tabs__nav-scroll {
  border-bottom: 2px solid #dcdfe6;
  padding: 0 8%;
}
.main .draTabs ::v-deep .el-tabs__item {
  background: none;
  border-radius: 0;
  width: 33.3%;
  text-align: center;
  padding: 0px;
}
.main .draTabs ::v-deep .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
  padding: 0px;
}
.main .draTabs ::v-deep .el-tabs__item.is_top {
  padding: 0px;
}
.main .draTabs ::v-deep .el-tabs__item.is-active {
  background: none;
  color: #1890ff;
  border-radius: 0;
  border: none;
  border-bottom: solid 4px #02538e;
}
.dranr {
  width: 84%;
  line-height: 25px;
  font-size: 13px;
  color: #606266;
  margin: 0 auto;
  padding: 10px 0;
}
.dranr p {
  text-indent: 2em;
  margin: 10px auto;
}
.main .draTabs ::v-deep .el-drawer__body {
  height: 100%;
  overflow: hidden;
 
}
.main .draTabs ::v-deep .el-tabs__content {
  height: 100%;
  overflow: hidden;
}
.main .draTabs ::v-deep .el-tab-pane {
  height: calc(100vh - 220px);
  overflow-y: auto;
}
.dranr2 {
  width: 100%;
  line-height: 25px;
  font-size: 13px;
  color: #666666;
  margin: 0 auto;
}
.dranr2 .drafatiao h2 {
  font-size: 13px;
  padding: 10px 0 0;
  margin: 0;
  color: #333333;
 
}
.dranr2 .drafatiao p {
  text-indent: 2em;
  color: #666666;
}
.dranr2 .drafatiao {
  padding: 0 8%;
}
.dranr2 .drafatiao:hover {
  background: #f2f2f2;
}
.dranr2 .drafatiao a,
.dranr2 .drafatiao a:hover {
  font-size: 13px;
  width: 100%;
  text-align: right;
  margin-bottom: 12px;
  display: inline-block;
  text-decoration: none;
}
.contract .fatiaomodal ::v-deep .el-dialog__header {
  background: #f5f7fa;
}
.contract .fatiaomodal ::v-deep .el-dialog__title {
  color: #333;
}
#fatiaocontent {
  line-height: 25px;
}
#fatiaocontent p {
  line-height: 25px;
  text-indent: 2em;
}

::v-deep .el-drawer__wrapper {
  width: 450px;
  /*height: calc(100vh-220px);*/
  height: 100%;
  left: auto;
}
::v-deep .el-drawer {
  -webkit-box-shadow: 0 8px 10px -5px rgb(0 0 0 / 10%),
    0 16px 24px 2px rgb(0 0 0 / 10%), 0 6px 10px 5px rgb(0 0 0 / 12%);
  box-shadow: 0 8px 10px -5px rgb(0 0 0 / 10%), 0 16px 24px 2px rgb(0 0 0 / 10%),
    0 6px 10px 5px rgb(0 0 0 / 12%);
}
.emptydata {
  width: 100%;
  height: 200px;
  margin: 30px auto;
  display: block;
  text-align: center;
  line-height: 100px;
  color: #c0c4cc;
  font-size: 13px;
}
.marginLeft {
  margin-left: 20px;
}
.yuanwen {
  position: absolute;
  right: 0;
  margin-top: 15px;
  margin-right: 30px;
}
.filedetailshow {
  width: 84%;
  margin: 10px auto;
  font-size: 13px;
  line-height: 28px;
}
.filedetailshownr h2.filedetailshownrtitle {
  width: 100%;
  text-align: center;
  color: #333333;
}
.main .draTabs .el-tab-pane h2 {
  font-size: 14px;
  width: 84%;
  margin: 15px auto 0;
}

.mainBox{
  height: calc(100vh + 100px);
  padding-bottom: 30px;
}
</style>