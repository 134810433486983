<template>
  <div>
    <div v-show="isAIShow" style="margin-top:20px;">
      <b-col lg="12">
        <iq-card>
          <div style="padding: 20px 20px 0px 20px">
             <BreadCrumb class="crumbs"/>
            <div
              style="
                width: 100%;
                background: #ffffff;
                font-size: 14px;
                font-weight: bold;
                color: #333333;
                border-bottom: 1px solid #dee2e6;
              "
            >
              <div style="display: inline-block" @click="tabClick('0')">
                <span class="tabsc" style=" cursor:pointer ;">协作审查</span>
                <div class="tabsactive" v-if="tabindex == '0'"></div>
              </div>
              <div style="display: inline-block" @click="tabClick('1')">
                <span class="tabsc" style=" cursor:pointer ;">手册编辑</span>
                <div class="tabsactive" v-if="tabindex == '1'"></div>
              </div>
              <!-- <div style="display: inline-block" @click="tabClick('2')">
                <span class="tabsc" style=" cursor:pointer ;">手册设置</span>
                <div class="tabsactive" v-if="tabindex == '2'"></div>
              </div> -->
              <div style="display: inline-block" @click="tabClick('2')">
                <span class="tabsc" style=" cursor:pointer ;">发布交付</span>
                <div class="tabsactive" v-if="tabindex == '2'"></div>
              </div>
              <div style="display: inline-block" @click="tabClick('3')">
                <span class="tabsc" style=" cursor:pointer ;">数字档案</span>
                <div class="tabsactive" v-if="tabindex == '3'"></div>
              </div>
            </div>
            <div v-if="tabindex == '0' && checkEdit==0">
              <div class="boxtop">
                <div class="topleft">
                  <el-checkbox
                    class="qxbox"
                    v-model="checkAll"
                    :indeterminate="isIndeterminate"
                    @change="handleCheckAllChange"
                  >
                    <span class="qxText" style=" cursor:pointer ;"> 批量操作 </span>
                  </el-checkbox>
                  <span class="shouze" @click="newAdd" style=" cursor:pointer ;">新增手册</span>
                  <span
                    style=" cursor:pointer ;"
                    class="shouze cdel"
                    :class="{ disb: disb }"
                    @click="delManualdata"
                    >删除</span
                  >
                </div>
                <div class="topright">
                  <label
                    >搜索<input type="text" placeholder="请输入文件名称" v-model="ManualList.Keyword"  @blur="ftSearch()" @keyup.enter="ftSearch()"
                  /></label>
                  <img
                    @click="switchIcon(0)"
                    style="
                        margin: 0 10px 0 52px;
                         30px;height: 30px;
                        display: inline-block;
                         cursor:pointer ;
                      "
                    :src="iconSrc"
                    alt=""
                  />
                  <img
                    @click="switchIcon(1)"
                    style="width: 30px; height: 30px; display: inline-block; cursor:pointer ;"
                    :src="iconSrc1"
                    alt=""
                  />
                </div>
              </div>

              <template>
                <div v-if="tabShow">
                  <el-table
                    ref="multipleTable"
                    :data="tableData"
                    tooltip-effect="dark"
                    style="width: 100%; margin-top: 30px"
                    @selection-change="handleSelectionChange"
                    :header-cell-style="headerStyleEvent"
                    :header-cell-class-name="cellClass"
                    :row-key="getRowKeys"
                    :expand-row-keys="expands"
                    @expand-change="expandSelect"
                  >
                    <el-table-column type="selection" width="30" align="left">
                    </el-table-column>
                    <el-table-column label="编号">
                      <template slot-scope="scope">
                        <div>
                          <div>{{ scope.row.serailNo }}</div>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="customerName" label="客户" width="">
                    </el-table-column>
                    <el-table-column prop="creator" label="创建人" width="120">
                    </el-table-column>
                    <el-table-column
                      prop="createTime"
                      label="创建时间"
                      width="160"
                    >
                    </el-table-column>
                    <el-table-column prop="name" label="状态" width="">
                      <template slot-scope="scope">
                        <div>
                          <span
                            v-if="scope.row.isSynergy == 0"
                            style="color: #fcc647"
                            >待协作</span
                          >
                          <span
                            v-if="scope.row.isSynergy == 1"
                            style="color: #fc5c47"
                            >协作中</span
                          >
                          <span
                            v-if="scope.row.isSynergy == 2"
                            style="color: #8279f3"
                            >已完成</span
                          >
                          <!-- <span
                            v-if="scope.row.isReview == 2"
                            style="color: #8279f3"
                            >已完成</span
                          > -->
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="name" label="发起协作" width="140">
                      <template slot-scope="scope">
                        <div>
                          <button
                            v-if="scope.row.isSynergy == 0"
                            class="tabbtn coop"
                            style="color: #ffffff"
                            @click="deliverWord(scope.row, 0)"
                          >
                            发起协作
                          </button>
                          <span class="tabbtn" v-if="scope.row.isSynergy == 1">
                            <button
                              class="okcan okbtn"
                              @click="cancalcooperation(scope.row)"
                            >
                              确认
                            </button>
                            <button
                              class="okcan cancal"
                              @click="cooperation(scope.row)"
                            >
                              取消
                            </button>
                          </span>
                          <button
                            v-if="scope.row.isSynergy == 2"
                            class="tabbtn coop"
                            style="color: #bbbbbb; background: #f1f2f1"
                          >
                            协作完成
                          </button>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="name" label="AI审查" width="140">
                      <template slot-scope="scope">
                        <div>
                          <button
                            v-if="scope.row.isSynergy != 1"
                            class="tabbtn exam"
                            @click="AImax(scope.row)"
                          >
                            AI审查
                          </button>
                          <button
                            v-if="scope.row.isSynergy == 1"
                            class="tabbtn exam"
                            style="color: #bbbbbb; background: #f1f2f1"
                          >
                            AI审查
                          </button>
                          <button
                            v-if="scope.row.isReview == 2"
                            class="tabbtn coop"
                            style="color: #bbbbbb; background: #f1f2f1"
                          >
                            审查完成
                          </button>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="name"
                      label="操作"
                      width="300"
                      align="center"
                    >
                      <template slot-scope="scope">
                        <div>
                          <span style="color: #6474c7; margin-right: 10%" v-if="scope.row.isSynergy != 1">
                            <i
                              class="ri-login-circle-line"
                              style="
                                display: inline-block;
                                margin-top: -2px;
                                font-size: 16px;
                                margin-right: 10px;
                                cursor:pointer ;
                              "
                            ></i>
                            <span style=" cursor:pointer ;"  @click="Tobedelivered(scope.row)"
                              >转入待交付</span
                            >
                          </span>
                          <span style="color: #6474c7; margin-right: 10%;color:#bbb;" v-if="scope.row.isSynergy == 1">
                            <i
                              class="ri-login-circle-line"
                              style="
                                display: inline-block;
                                margin-top: -2px;
                                font-size: 16px;
                                margin-right: 10px;
                                cursor:pointer ;
                              "
                            ></i>
                            <span style=" cursor:pointer ;"
                              >转入待交付</span
                            >
                          </span>
                          <span style="color: #45cfa0; margin-right: 10%"  v-if="scope.row.isSynergy != 1">
                            <i
                              class="ri-edit-2-line"
                              style="
                                display: inline-block;
                                margin-top: -2px;
                                font-size: 16px;
                                margin-right: 10px;
                                 cursor:pointer ;
                              "
                            ></i>
                            <span @click="EditManual(scope.row)" style=" cursor:pointer ;" >在线编辑</span>
                          </span>
                          <span style="color: #45cfa0; margin-right: 10%;color:#bbb"  v-if="scope.row.isSynergy == 1">
                            <i
                              class="ri-edit-2-line"
                              style="
                                display: inline-block;
                                margin-top: -2px;
                                font-size: 16px;
                                margin-right: 10px;
                                 cursor:pointer ;
                              "
                            ></i>
                            <span style=" cursor:pointer ;" >在线编辑</span>
                          </span>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column type="expand" width="1">
                      <template slot-scope="scope">
                        <div
                          v-if="ManualWord"
                          style="
                            margin-left: 40px;
                            padding: 10px 20px 0px 0px;
                            height: 100px;
                          "
                        >
                          <img
                            src="../../assets/images/home/word.png"
                            alt=""
                            style="
                              width: 70px;
                              height: 80px;
                              display: inline-block;
                              margin-right: 10px;
                              float: left;
                            "
                          />
                          <div
                            style="
                              display: inline-block;
                              float: left;
                              width: 216px;
                            "
                          >
                            <span style="color: #909399">手册名称：</span>
                            <div
                              style="
                                color: #333333;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                              "
                            >
                              {{ ManualWord.title }}
                            </div>
                            <div style="color: #909399">
                              编辑次数：<span style="color: #333333">{{
                                ManualWord.isEdit
                              }}</span>
                            </div>
                          </div>
                          <div
                            style="
                              display: inline-block;
                              float: left;
                              margin-top: -12px;
                            "
                          >
                            <span
                              style="
                                display: inline-block;
                                height: 100px;
                                line-height: 100px;
                                position: absolute;
                                left: 51.2%;
                                transform: translate(-42px);
                              "
                              :style="{ color: tabStatus }"
                              >{{
                                ManualWord.isSign == 0 ? "未签署" : "已签署"
                              }}</span
                            >
                          </div>
                          <div
                            style="
                              display: inline-block;
                              float: right;
                              margin-top: 22px;
                            "
                          >
                            <button
                              class="editword"
                              @click="lookWord(ManualWord)"
                            >
                              编辑文件
                            </button>
                            <button
                              @click="deliverWord(scope.row, 1)"
                              class="editword"
                              style="
                                border: 1px solid #fcc647;
                                color: #fcc647;
                                margin: 0px 15px;
                              "
                            >
                              文件交付
                            </button>
                            <!-- <button
                              class="editword"
                              style="border: 1px solid #6474c7; color: #6474c7"
                            >
                              电子签署
                            </button> -->
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                  <el-pagination
                    align="center"
                    style="margin-top: 20px"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="ManualList.PageIndex"
                    :page-count="totalPages"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="ManualList.PageSize"
                    layout=" prev, pager, next, jumper,sizes,total"
                    :total="total"
                  >
                  </el-pagination>
                </div>
                <div v-else class="listCard">
                  <el-card
                    class="box-card"
                    ref="posRel"
                    v-for="(item, index) in tableData"
                    :key="item.id"
                  >
                    <div style="position: relative">
                      <div
                        style="display: flex; justify-content: space-between"
                      >
                        <span style="color: #999">No.{{ item.serailNo }}</span
                        ><span
                          v-if="item.isSynergy == 0"
                          style="
                            width: 80px;
                            height: 26px;
                            border-radius: 18px;
                            text-align: center;
                            line-height: 26px;
                            color: #fcc647;
                            background: rgba(252, 198, 71, 0.15);
                          "
                          >待协作</span
                        >
                        <span
                          v-if="item.isSynergy == 1"
                          style="
                            width: 80px;
                            height: 26px;
                            border-radius: 18px;
                            text-align: center;
                            line-height: 26px;
                            color: #fc5c47;
                            background: rgba(252, 92, 71, 0.15);
                          "
                          >协作中</span
                        >
                        <span
                            v-if="item.isSynergy == 2"
                            style="
                            color: #8279f3;
                            width: 80px;
                            height: 26px;
                            border-radius: 18px;
                            text-align: center;
                            line-height: 26px;
                            background: rgba(130, 121, 243, 0.15);
                            "
                            >已完成</span
                          >
                      </div>
                      <div style="margin: 14px 0px">
                        <span style="color: #999">客户</span><br /><span>{{
                          item.customerName
                        }}</span>
                      </div>
                      <div style="display: flex; justify-content: flex-start">
                        <div>
                          <span style="color: #999">创建人</span><br /><span>{{
                            item.creator
                          }}</span>
                        </div>
                        <div style="margin-left: 74px">
                          <span style="color: #999">创建时间</span><br /><span
                            >{{ item.createTime }}</span
                          >
                        </div>
                      </div>
                      <div style="margin-top: 14px">
                        <span style="margin-right: 22px">
                          <button
                            class="tabbtn coop"
                            style="width: 100px; color: #ffffff"
                            @click="deliverWordList(index, 0)"
                            v-if="item.isSynergy == 0"
                          >
                            发起协作
                          </button>
                          <button
                           v-if="item.isSynergy == 2"
                                style="
                                  width: 100px;
                                  height: 30px;
                                  color: #A8A8A8;
                                  background: #e5e5e5;
                                  border-radius: 8px;
                                  border:none
                                "
                              >
                                协作完成
                              </button>
                          <span class="tabbtn"  v-if="item.isSynergy == 1">
                              <button class="okcan okbtn" @click="cancalcooperation(item)">确认</button>
                              <button class="okcan cancal" @click="cooperation(item)">取消</button>
                          </span>
                        </span>
                        <button
                        v-if="item.isSynergy != 1"
                          @click="AImax(item)"
                          class="tabbtn exam"
                          style="
                            width: 100px;
                            margin-right: 70px;
                            color: #ffffff;
                          "
                        >
                          AI审查
                        </button>
                         <button
                          v-if="item.isSynergy == 1"
                          class="tabbtn exam"
                          style="
                           background: #e5e5e5;
                            width: 100px;
                            margin-right: 70px;
                            color: #bbb;
                          "
                        >
                          AI审查
                        </button>

                        <el-popover
                          v-if="item.isSynergy != 1"
                          placement="top"
                          width="146"
                          trigger="click"
                          style="text-align: center;cursor:pointer ;"
                        >
                          <div style="color: #606266">
                            <i
                              class="ri-login-circle-line"
                              style="
                                display: inline-block;
                                font-size: 14px;
                                margin-right: 10px;
                                cursor:pointer ;
                              "
                            ></i>
                            <span @click="TobedeliveredList(index)" style=" cursor:pointer ;"
                              >转入待交付</span
                            >
                          </div>
                          <div style="color: #606266" @click="EditManual(item)">
                            <i
                              class="ri-edit-2-line"
                              style="
                                display: inline-block;
                                font-size: 14px;
                                margin-right: 10px;
                              "
                            ></i>
                            <span style=" cursor:pointer;">在线编辑</span>
                          </div>
                          <i class="ri-more-line iconmus" slot="reference"></i>
                        </el-popover>
                        <el-popover
                          v-if="item.isSynergy == 1"
                          disabled
                          placement="top"
                          width="146"
                          trigger="click"
                          style="text-align: center;cursor:pointer ;"
                        >
                          <div style="color: #606266">
                            <i
                              class="ri-login-circle-line"
                              style="
                                display: inline-block;
                                font-size: 14px;
                                margin-right: 10px;
                                cursor:pointer ;
                              "
                            ></i>
                            <span @click="TobedeliveredList(index)" style=" cursor:pointer ;"
                              >转入待交付</span
                            >
                          </div>
                          <div style="color: #606266" @click="EditManual(item)">
                            <i
                              class="ri-edit-2-line"
                              style="
                                display: inline-block;
                                font-size: 14px;
                                margin-right: 10px;
                              "
                            ></i>
                            <span style=" cursor:pointer;">在线编辑</span>
                          </div>
                          <i class="ri-more-line iconmus" slot="reference" style="background:#e5e5e5;color:#bbb;"></i>
                        </el-popover>
                        <span
                          @click="isshowWord(index)"
                          style="margin-left: 22px;cursor:pointer ;"
                          ><i
                            class="ri-arrow-down-s-line"
                            v-if="!item.show"
                          ></i>
                          <i class="ri-arrow-up-s-line" v-else></i>
                        </span>
                      </div>
                      <div class="posAbs">
                        <div
                          style="
                            border-radius: 18px;
                            border: 1px solid #d7dbda;
                            width: 100px;
                            text-align: center;
                            margin-right: 20px;
                          "
                        >
                          <div style="margin: 18px">
                            <img
                              style="display: block; width: 64px; height: 72px"
                              src="../../assets/images/home/word.png"
                              alt=""
                            />
                          </div>
                          <span
                            style="
                              width: 100px;
                              height: 24px;
                              display: inline-block;
                              color: #ffffff;
                              background: #6474c7;
                              border: 1px solid #6474c7;
                              border-radius: 0px 0px 18px 18px;
                              position: relative;
                              left: -1px;
                              bottom: -2px;
                            "
                            >{{
                              ManualWord.isSign == 0 ? "未签署" : "已签署"
                            }}</span
                          >
                        </div>
                        <div
                          style="
                            display: flex;
                            justify-content: space-between;
                            flex-direction: column;
                          "
                        >
                          <div>
                            <p
                              style="
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                              "
                            >
                              <span
                                style="
                                  color: #909399;
                                  margin-right: 10px;
                                  display: inline-block;
                                "
                                >手册名称 </span
                              ><span>{{ ManualWord.title }}</span>
                            </p>
                            <p>
                              <span
                                style="
                                  color: #909399;
                                  margin-right: 10px;
                                  display: inline-block;
                                "
                                >编辑次数 </span
                              ><span>{{
                                ManualWord.isEdit
                              }}</span>
                            </p>
                          </div>
                          <div>
                            <span
                              @click="lookWord(ManualWord)"
                              style="
                                border-bottom: 1px solid #45cfa0;
                                color: #45cfa0;
                                display: inline-block;
                                cursor:pointer ;
                              "
                              >编辑文件</span
                            >
                            <span
                              @click="deliverWordList(index, 1)"
                              style="
                                border-bottom: 1px solid #fcc647;
                                color: #fcc647;
                                margin: 0px 15px;
                                font-size: 14px;
                                display: inline-block;
                                cursor:pointer ;
                              "
                            >
                              文件交付
                            </span>
                            <span
                              style="
                                border-bottom: 1px solid #6474c7;
                                color: #6474c7;
                                font-size: 14px;
                                display: inline-block;
                                cursor:pointer ;
                              "
                            >
                              电子签署
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </el-card>
                  <i
                    v-for="(item, index) in tableData.length - 1"
                    :key="index"
                  ></i>
                  <!-- 分页器 -->
                  <el-pagination
                    align="center"
                    style="margin-top: 20px"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="ManualList.PageIndex"
                    :page-count="totalPages"
                    :page-sizes="[5, 10, 20, 30]"
                    :page-size="ManualList.PageSize"
                    layout=" prev, pager, next, jumper,sizes,total"
                    :total="total"
                  >
                  </el-pagination>
                </div>
                <!-- 协作弹窗 -->
                <div class="popNotification" v-if="popShow">
                  <div
                    style="
                      height: 61px;
                      line-height: 60px;
                      text-align: center;
                      font-size: 16px;
                      font-weight: bold;
                      color: #6474c7;
                      margin-bottom: 21px;
                      background: #eaf5ff;
                      border-radius: 18px 18px 0px 0px;
                    "
                  >
                    {{ ischoice == 0 ? "添加协作人" : "添加接收人" }}
                  </div>
                  <div
                    v-if="!newnewReceshow"
                    style="
                      margin: 0px 31px;
                      height: 325px;
                      background: #ffffff;
                      border-radius: 8px;
                      border: 1px solid #d7dbda;
                    "
                  >
                    <div
                      style="
                        font-weight: bold;
                        color: #ffffff;
                        width: 597px;
                        height: 44px;
                        text-align: center;
                        line-height: 44px;
                        background: linear-gradient(90deg, #6878c8 0%,#79baf2 100%);
                        border-radius: 7px 7px 0px 0px;
                      "
                    >
                      企业
                    </div>
                    <div style="margin-top: 30px; padding-left: 20px">
                      <label style="width: 64px; color: #909399">客户名称</label
                      ><el-input
                        v-model="EnterpriseName"
                        disabled
                        type="text"
                        placeholder="请选择客户名称"
                        style="
                          width: 464px;
                          height: 40px;
                          background: #ffffff;
                          border-radius: 8px;
                          color: #333333;
                        "
                      />
                    </div>
                    <div style="padding-left: 20px; margin-top: 20px">
                      <label style="width: 64px; color: #909399">{{
                        ischoice == 0 ? "协作人" : "接收人"
                      }}</label
                      ><el-select
                        style="
                          color: #333333;
                          width: 372px;
                          height: 40px;
                          background: #ffffff;
                          border-radius: 8px;
                        "
                        v-model="ReceiverName"
                        :placeholder="isplaceholder"
                        @change="changeReceiverName"
                      >
                        <el-option
                          v-for="item in CusDetil"
                          :key="item.id"
                          :label="item.name"
                          :value="item"
                        >
                        </el-option>
                      </el-select>
                      <span
                        @click="newRece"
                        style="
                          border-bottom: 1px solid #6474c7;
                          color: #6474c7;
                          margin-left: 12px;
                          cursor:pointer
                        "
                        >{{ ischoice == 0 ? "新增协作人" : "新增接收人" }}</span
                      >
                    </div>
                    <div v-if="ReceiverName">
                      <div style="padding-left: 20px; margin-top: 20px">
                        <label style="width: 64px; color: #909399">手机号</label
                        ><span
                          v-if="!phoneShow"
                          style="
                            color: #333333;
                            width: 372px;
                            display: inline-block;
                          "
                          >{{ phone }}</span
                        ><el-input
                          v-else
                          type="text"
                          ref="phoneInput"
                          v-model="phone"
                          @blur="phoneBlur"
                          focus="true"
                          style="
                            color: #333333;
                            width: 372px;
                            height: 40px;
                            background: #ffffff;
                            border-radius: 8px;
                          "
                        /><span
                          @click="modifyPhone"
                          style="
                            color: #6474c7;
                            border-bottom: 1px solid #6474c7;
                            margin-left: 12px;
                            cursor:pointer
                          "
                          >修改</span
                        >
                      </div>
                      <div style="padding-left: 20px; margin-top: 20px">
                        <label style="width: 64px; color: #909399">邮箱号</label
                        ><span  v-if="!emailShow"
                            style="color: #333333;
                            width: 372px;
                            height: 40px;
                            display: inline-block;
                            background: #ffffff;
                            border-radius: 8px;">{{ email }}</span>
                        <el-input  v-else v-model="email" @blur="emailBlur" ref="emailInput" focus="true"
                            style="
                            color: #333333;
                            width: 372px;
                            height: 40px;
                            background: #ffffff;
                            border-radius: 8px;
                          "></el-input><span
                          @click="modifyEmail"
                          style="
                            color: #6474c7;
                            border-bottom: 1px solid #6474c7;
                            margin-left: 12px;
                            cursor:pointer
                          "
                          >修改</span
                        >
                      </div>
                    </div>
                    <div style="margin-top: 70px; padding-left: 280px">
                      <span
                        @click="cancl"
                        style="
                          width: 140px;
                          height: 50px;
                          line-height: 50px;
                          text-align: center;
                          background: #f1f2f1;
                          border-radius: 25px;
                          margin-right: 20px;
                          color: #bbbbbb;
                          display: inline-block;
                          font-weight: bold;
                          font-size: 14px;
                          cursor:pointer
                        "
                        >取消</span
                      ><button
                        :disabled="rollDis"
                        @click="submit"
                        style="
                          width: 140px;
                          height: 50px;
                          line-height: 50px;
                          text-align: center;
                          display: inline-block;
                          background: linear-gradient(90deg, #6878c8 0%,#79baf2 100% );
                          border-radius: 25px;
                          border: none;
                          color: #ffffff;
                          font-weight: bold;
                          font-size: 14px;
                        "
                      >
                        {{ rollDis ? "发送中" : "确认" }}
                      </button>
                    </div>
                  </div>
                  <div
                    v-else
                    style="
                      margin: 0px 31px;
                      height: 325px;
                      background: #ffffff;
                      border-radius: 8px;
                      border: 1px solid #d7dbda;
                    "
                  >
                    <div
                      style="
                        font-weight: bold;
                        color: #ffffff;
                        width: 597px;
                        height: 44px;
                        text-align: center;
                        line-height: 44px;
                        background: linear-gradient( 90deg,#6878c8 0%,#79baf2 100% );
                        border-radius: 7px 7px 0px 0px;
                      "
                    >
                      企业
                    </div>
                    <div style="margin-top: 30px; padding-left: 20px">
                      <label>{{ ischoice == 0 ? "协作人" : "接收人" }}</label
                      ><el-input
                        v-model="newReceiver.name"
                        :placeholder="isplaceholder"
                        style="
                          width: 498px;
                          height: 40px;
                          background: #ffffff;
                          border-radius: 8px;
                          color: #333333;
                          margin-left: 20px;
                        "
                      ></el-input>
                    </div>
                    <div style="margin-top: 30px; padding-left: 20px">
                      <label>手机号</label
                      ><el-input
                        v-model="newReceiver.phoneText"
                        placeholder="请输入手机号"
                        style="
                          width: 498px;
                          height: 40px;
                          background: #ffffff;
                          border-radius: 8px;
                          color: #333333;
                          margin-left: 20px;
                        "
                      ></el-input>
                    </div>
                    <div style="margin-top: 30px; padding-left: 20px">
                      <label>邮箱号</label
                      ><el-input
                        v-model="newReceiver.email"
                        placeholder="请输入邮箱号"
                        style="
                          width: 498px;
                          height: 40px;
                          background: #ffffff;
                          border-radius: 8px;
                          color: #333333;
                          margin-left: 20px;
                        "
                      ></el-input>
                    </div>
                    <div style="margin-top: 100px; padding-left: 280px">
                      <span
                        @click="newcancl"
                        style="
                          width: 140px;
                          height: 50px;
                          line-height: 50px;
                          text-align: center;
                          background: #f1f2f1;
                          border-radius: 25px;
                          margin-right: 20px;
                          color: #bbbbbb;
                          display: inline-block;
                          font-weight: bold;
                          font-size: 14px;
                        "
                        >取消</span
                      ><span
                        @click="submitnewReceiver"
                        style="
                          width: 140px;
                          height: 50px;
                          line-height: 50px;
                          text-align: center;
                          display: inline-block;
                          background: linear-gradient(90deg,#6878c8 0%,#79baf2 100%);
                          border-radius: 25px;
                          color: #ffffff;
                          font-weight: bold;
                          font-size: 14px;
                        "
                        >确认</span
                      >
                    </div>
                  </div>
                </div>
                <!-- ai弹窗 -->
                <div class="AIfrim" v-if="aiConfirmDialogVisible">
                  <div
                    style="
                      font-weight: bold;
                      font-size: 16px;
                      color: #6474c7;
                      height: 60px;
                      background: #eaf5ff;
                      border-radius: 18px 18px 0px 0px;
                      line-height: 60px;
                      text-align: center;
                    "
                  >
                    AI审查
                  </div>
                  <div
                    style="
                      margin-top: 40px;
                      text-align: center;
                      font-size: 14px;
                      color: #909399;
                    "
                  >
                    通过智能AI快速审查内容中的错误，帮助您进行纠错修改。
                  </div>
                  <div style="margin-top: 40px; padding-left: 330px">
                    <span
                      @click="aiConfirmDialogVisible = false"
                      style="
                        width: 140px;
                        height: 50px;
                        line-height: 50px;
                        text-align: center;
                        background: #f1f2f1;
                        border-radius: 25px;
                        margin-right: 20px;
                        color: #bbbbbb;
                        display: inline-block;
                        font-weight: bold;
                        font-size: 14px;
                        cursor:pointer ;
                      "
                      >取消</span
                    ><span
                      @click="startCheck()"
                      style="
                        width: 140px;
                        height: 50px;
                        line-height: 50px;
                        text-align: center;
                        display: inline-block;
                        background: linear-gradient(90deg,#6878c8 0%, #79baf2 100%);
                        border-radius: 25px;
                        color: #ffffff;
                        font-weight: bold;
                        font-size: 14px;
                        cursor:pointer ;
                      "
                      >确认</span
                    >
                  </div>
                </div>
                <!-- 进度跳 -->
                <el-dialog
                  title="Loading"
                  :visible.sync="processDialogVisible"
                  width="30%"
                  top="35vh"
                  :show-close="false"
                  :close-on-click-modal="false"
                  :close-on-press-escape="false"
                  center
                  customClass="processDialogClass"
                  @open="handleOpened"
                >
                  <el-progress
                    style="margin-left:10px;"
                    :stroke-width="10"
                    :percentage="percentage"
                    :format="format"
                    :color="processColor"
                  ></el-progress>
                </el-dialog>
                <!-- 编辑框 -->
                <el-dialog
                  title="编辑文件"
                  :visible.sync="isLookWrit"
                  height="1000px"
                  width="1200px"
                  center
                  top="50px"
                >
                  <div id="box">
                    <div id="placeholder"></div>
                  </div>
                  <p class="btn">
                    <el-button round @click="isLookWrit = false">取消</el-button>
                    <el-button round @click="isLookWrit = false"
                      >保存</el-button
                    >
                  </p>
                </el-dialog>
                <remote-js :src="CX_Api" />
              </template>
            </div>
            <setManual
              v-if="tabindex == '0' && checkEdit !=0"
              @tabCheck="tabCheckTo"
              @getmanual="getManualList(ManualList)"
            />
            <ReleaseDelivery v-if="tabindex == '2'"></ReleaseDelivery>
            <archives v-if="tabindex == '3'"></archives>
          </div>
        </iq-card>
      </b-col>
      <manualEdit v-if="tabindex == '1'"></manualEdit>
    </div>
    <div  v-show="!isAIShow">
        <b-col lg="12">
           <iq-card>
              <AIexamination
              :AIDataList="AIData"
              @tabCheck="tabCheckTo"/>
           </iq-card>
        </b-col>
    </div>

  </div>
</template>

<script>
import { viewInitEditor } from '@/Utils/viewonlineEdit'
import AIexamination from '@/views/Forms/AIexamination.vue'
import setManual from './setManual .vue'
import { core } from '../../config/pluginInit'
import manualEdit from './manual-editing.vue'
import ReleaseDelivery from './ReleaseDelivery.vue'
import detailsPage from './details/detailsPage'
import archives from './archives.vue'
import BreadCrumb from '../../components/core/breadcrumbs/BreadCrumb.vue'
import {
  getManual,
  delManual,
  getexamination,
  launch,
  Todelivered,
  getCusDetil,
  addCustomer,
  cancooperation,
  offcooperation
} from '@/FackApi/api/employee-handbook'
export default {
  name: 'FormValidates',
  components: {
    BreadCrumb,
    detailsPage,
    setManual,
    manualEdit,
    ReleaseDelivery,
    archives,
    AIexamination,
    'remote-js': {
      render (createElement) {
        return createElement('script', {
          attrs: { type: 'text/javascript', src: this.src }
        })
      },
      props: {
        src: { type: String, required: true }
      }
    }
  },
  mounted () {
    core.index()
    this.tabindex = '0'
    this.tabClick('0')
  },
  data () {
    return {
      checkEdit: 0,
      isAIShow: true, // AI审查页面
      isLookWrit: false,
      createId: '', // 创建ID
      fileInfo: {},
      manualWord: null,
      manualId: '', // 客户id
      tabindex: '0',
      tabShow: true, // 审查列表
      iconSrc: require('../../assets/images/home/卡片.png'),
      iconSrc1: require('../../assets/images/home/列表-选择.png'),
      tabStatus: '#FCC647', // 状态
      bgStatus: 'rgba(252,198,71,0.15)', // 状态
      disb: true, // 删除按钮
      checkAll: false, // 全选
      /* 全选的问题 */
      multipleSelection: [],
      isIndeterminate: false,
      // 获取row的key值
      getRowKeys (row) {
        return row.id
      },
      // 要展开的行，数值的元素是row的key值
      expands: [],
      tableData: [],
      ManualList: {
        PageIndex: 1,
        PageSize: 10,
        Keyword: ''
      },
      total: 0,
      totalPages: 0,
      ManualWord: {}, // 文档数据
      ManualWordShow: false, // 文档显示
      phoneText: '123456789', // 手机
      emailText: '', // 手机
      phoneShow: false, // input显示
      emailShow: false, // input显示
      popShow: false,
      EnterpriseName: '', // 企业客户名称
      ReceiverName: '', // 接收人
      email: '',
      phone: '',
      Receiver: [],
      newnewReceshow: false,
      newReceiver: {
        name: '',
        phoneText: '',
        email: ''
      },
      aiConfirmDialogVisible: false, // 显示执行AI审核的提示
      processDialogVisible: false, // 显示进度条的dialog
      percentage: 0, // 百分比
      processColor: '#409eff', // 进度条颜色
      rollDis: false, // 节流
      CusDetil: [], // 】联系人列表
      ReceiId: '', // 协作人id
      wordId: '', // 文件id
      ListId: '', // 列表id
      ischoice: 0, // 0协作，1交付
      isplaceholder: '请选择协作人',
      AIData: []
    }
  },
  mounted () {
    core.index()
    const tabsc = document.getElementsByClassName('tabsc')
    for (let i = 0; i < tabsc.length; i++) {
      tabsc[i].style.color = '#333333'
    }
    const index = Number(this.tabindex)
    tabsc[index].style.color = '#837AF3'

    this.getManualList(this.ManualList)
  },
  methods: {
    // 搜索
    ftSearch () {
      this.getManualList(this.ManualList)
      // this.ManualList.Keyword= ""
    },
    // 选择协作人
    changeReceiverName (val) {
      // console.log(val);
      this.ReceiverName = val.name
      this.phone = val.mobile
      this.email = val.email
      this.ReceiId = val.id
    },
    // 文件编辑
    lookWord (item) {
      console.log(item)
      this.fileInfo = item
      const url = 'https://api.ilaas.cn' + item.path
      // console.log(url);
      this.isLookWrit = true
      this.createId = item.id
      const serailNo = item.serailNo
      this.$nextTick(() => {
        const el = document.getElementById('box')
        el.innerHTML = '<div id="placeholder"></div>'
        viewInitEditor(
          item.title,
          url,
          true,
          true,
          true,
          true,
          String(serailNo),
          'eide',
          'desktop|mobile',
          {
            comment: true,
            download: false,
            edit: true,
            print: false,
            review: true
          },
          item.title,
          this.COOKIE_TOKEN,
          100
        )
      })
    },
    // 点击下载
    // download() {
    //   // this.isLookWrit = false;
    //   let ext = this.fileInfo.path.substring(
    //     this.fileInfo.path.lastIndexOf(".")
    //   );
    //   this.getBlob(this.fileInfo.path).then((blob) => {
    //     this.DownloadItem(blob, this.fileInfo.title + ext);
    //   });
    // },
    // 下载 重命名 3
    // getBlob(url) {
    //   return new Promise((resolve) => {
    //     const xhr = new XMLHttpRequest();
    //     xhr.open("GET", url, true);
    //     xhr.responseType = "blob";
    //     xhr.onload = () => {
    //       if (xhr.status === 200) {
    //         resolve(xhr.response);
    //       }
    //     };
    //     xhr.send();
    //   });
    // },
    // DownloadItem(blob, fileName) {
    //   let link = document.createElement("a");
    //   link.href = window.URL.createObjectURL(blob);
    //   link.download = fileName;
    //   link.click();
    // },
    // 在线编辑
    EditManual (row) {
      // console.log(row);
      // return
      this.ListId = row.id
      this.manualId = row.id
      this.tabClick('1')
    },
    // 新增
    newAdd () {
      this.tabClick('0')
      this.checkEdit = 1
    },
    handleCurrentChange (val) {
      // console.log(val);
      this.ManualList.PageIndex = val
      this.getManualList(this.ManualList)
    },
    handleSizeChange (val) {
      // console.log(val);
      this.ManualList.PageSize = val
      this.getManualList(this.ManualList)
    },
    // 删除
    delManualdata () {
      const data = []
      const len = this.multipleSelection
      if (len.length == 0) {
        this.$message({
          type: 'error',
          message: '请选择要删除的内容',
          offset: 400,
          duration: 800
        })
        return
      }
      len.forEach((item) => {
        data.push(item.id)
      })
      delManual(data.toString()).then((res) => {
        // console.log(res);
        if (res.code == 0) {
          this.$message({
            message: res.msg,
            type: 'success',
            offset: 400,
            duration: 800
          })
          this.getManualList(this.ManualList)
        }
      })
    },
    // 获取手册列表  bgshow
    getManualList (val) {
      // console.log(val);
      if (!val) {
        val = this.ManualList
      }
      getManual(val).then((res) => {
        // console.log(res);
        if (res.code == 0) {
          if (res.msg == '暂无查询到记录') {
            this.$message({
              type: 'error',
              message: '暂无查询到记录！！',
              offset: 400,
              duration: 800
            })
          } else {
            this.tableData = res.data
            this.tableData.forEach((item) => {
              item.show = false
            })
            this.total = res.page.totalCount
            this.totalPages = res.page.totalPages
          }
        }
      })
    },
    // 取消协作
    cooperation (row) {
      // console.log(row);
      offcooperation(row.id).then((res) => {
        console.log(res)
        if (res.code == 0) {
          this.$message({
            type: 'success',
            message: '取消成功',
            offset: 400,
            duration: 800
          })
          this.getManualList(this.ManualList)
        }
      })
    },
    // 确认协作
    cancalcooperation (row) {
      cancooperation(row.id).then((res) => {
        // console.log(res);
        if (res.code == 0) {
          this.$message({
            type: 'success',
            message: '操作成功',
            offset: 400,
            duration: 800
          })
          this.getManualList(this.ManualList)
        }
      })
    },
    tabClick (index) {
      this.tabindex = index
      const tabsc = document.getElementsByClassName('tabsc')
      for (let i = 0; i < tabsc.length; i++) {
        tabsc[i].style.color = '#333333'
      }
      tabsc[index].style.color = '#837AF3'
      if (index == '0') {
        this.getManualList()
      }
    },
    tabCheckTo (step) {
      // console.log(step);
      if (step == 4) {
        this.tabClick('0')
        this.isAIShow = true
        this.checkEdit = 0
      }
    },
    format (percentage) {
      return percentage === 100 ? '完成' : `${percentage}%`
    },
    // 显示AI弹窗
    AImax (row) {
      if (row.employeeHandbookFile) {
        this.percentage = 0
        this.aiConfirmDialogVisible = true
        this.ListId = row.id
      } else {
        this.$message({
          type: 'error',
          message: '该客户暂无相关文件，请重新编辑生成！！',
          offset: 400,
          duration: 800
        })
      }
    },
    // ai审核
    startCheck () {
      this.aiConfirmDialogVisible = false
      // 进度条显示
      this.processDialogVisible = true

      // this.processDialogVisible = false;
      // this.isEditor = false;
      // this.isAIShow = false;
      // this.processDialogVisible = false; this.isAIShow = false;
      // return
      let sec = 0
      const timerstat = setInterval(() => {
        sec = sec + 1
        sec > 100 ? 100 : sec
        this.percentage = sec
      }, 1000)
      getexamination(this.ListId).then(res => {
        // console.log(res);
        if (res.code == 0) {
          clearInterval(timerstat)
          console.log(res.data)
          this.AIData = res.data
          const timer = setInterval(() => {
            // 需要定时执行的代码
            sec = sec + 1
            if (sec < 100) {
              this.percentage = sec
            } else {
              this.percentage = 100
              clearInterval(timer)
              setTimeout(() => {
                // 需要定时执行的代码
                this.processDialogVisible = false
                this.isEditor = false
                this.isAIShow = false
                this.processDialogVisible = false
              }, 1000)
            }
          }, 30)
        }
      })
    },
    // 进度
    handleOpened () {},
    // 转待交付
    Tobedelivered (id) {
      if (id.isSynergy == 0) {
        Todelivered(id.id).then((res) => {
          console.log(res)
          if (res.code == 0) {
            this.$message({
              type: 'success',
              message: '转入成功',
              offset: 400,
              duration: 800
            })
          }
        })
      } else {
        this.$message({
          type: 'error',
          message: '文件已转入带交付列表，不能重复提交！',
          offset: 400,
          duration: 800
        })
      }
    },
    // 转待交付-列表
    TobedeliveredList (index) {
      const id = this.tableData[index].id
      Todelivered(id).then((res) => {
        console.log(res)
        if (res.code == 0) {
          this.$message({
            type: 'success',
            message: '转入成功',
            offset: 400,
            duration: 800
          })
        }
      })
    },
    // 展示文档
    isshowWord (index) {
      this.ManualWord = {} // 文档数据
      this.ManualWordShow = false // 文档显示
      // let id = this.tableData[index].id;
      if (this.tableData[index].employeeHandbookFile) {
        this.wordId = this.tableData[index].employeeHandbookFile.id
        this.ManualWord = this.tableData[index].employeeHandbookFile
        this.ManualWordShow = true
      } else {
        this.$message({
          type: 'error',
          message: '该客户暂无相关文件，请重新编辑生成！！',
          offset: 400,
          duration: 800
        })
      }
      setTimeout(() => {
        if (this.ManualWordShow == false) {

        } else {
          const card = document.getElementsByClassName('box-card')
          const len = this.tableData.length
          const text = card[index].style.height
          // console.log(text);
          for (let i = 0; i < len; i++) {
            this.tableData[i].show = false
          }
          for (let i = 0; i < card.length; i++) {
            card[i].style.height = 240 + 'px'
          }

          this.tableData[index].show = !this.tableData[index].show
          if (text == '410px') {
            card[index].style.height = 240 + 'px'
            this.tableData[index].show = false
          } else {
            card[index].style.height = 410 + 'px'
            this.tableData[index].show = true
          }
        }
      }, 100)
    },
    // switch切换
    switchIcon (idx) {
      if (idx == 0) {
        this.tabShow = false
        this.iconSrc = require('../../assets/images/home/卡片-选择@2x.png')
        this.iconSrc1 = require('../../assets/images/home/列表@2x.png')
      } else {
        this.tabShow = true
        this.iconSrc = require('../../assets/images/home/卡片.png')
        this.iconSrc1 = require('../../assets/images/home/列表-选择.png')
      }
    },
    // 展开行
    expandSelect (row, expandedRows) {
      this.expands = [] // 默认不展开
      this.ManualWord = {} // 文档数据
      this.ManualWordShow = false // 文档显示
      if (row.employeeHandbookFile) {
        this.wordId = row.employeeHandbookFileid
        this.ManualWord = row.employeeHandbookFile
        this.ManualWordShow = true
        this.expands = []
        if (expandedRows.length > 0) {
          row ? this.expands.push(row.id) : ''
          // console.log(this.expands);
        }
      } else {
        this.$message({
          type: 'error',
          message: '该客户暂无相关文件，请重新编辑生成！！',
          offset: 400,
          duration: 800
        })
        this.expands = [] // 默认不展开
        this.ManualWordShow = false
      }
      // getManualWord(row.id).then((res) => {
      //   // console.log(res);
      //   if (res.code == 0 && res.data != null) {
      //     this.wordId = res.data.id;
      //     this.ManualWord = res.data;
      //     this.ManualWordShow = true;
      //     this.expands = [];
      //     if (expandedRows.length > 0) {
      //       row ? this.expands.push(row.id) : "";
      //       // console.log(this.expands);
      //     }
      //   } else {
      //     this.expands = []; // 默认不展开
      //     this.ManualWordShow = false;
      //   }
      // });
    },
    // 表格内点击多选框
    handleSelectionChange (val) {
      // console.log(val);
      if (val.length) {
        this.disb = false
      } else {
        this.disb = true
      }
      const checkedCount = val.length
      this.checkAll = checkedCount === this.tableData.length
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.tableData.length

      this.multipleSelection = val
      // console.log(this.checkAll ,this.isIndeterminate,this.multipleSelection);
    },
    // 点击外部全选
    handleCheckAllChange (val) {
      // console.log(val);
      this.checkAll = val
      var rows = this.tableData
      var value = val ? rows : []
      console.log(this.$refs.multipleTable)

      if (val) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
      // console.log(this.checkAll ,this.isIndeterminate,this.multipleSelection);
    },
    // 取消表头全选
    cellClass (row) {
      if (row.columnIndex === 0) {
        return 'disabledCheck'
      }
    },
    // 表头颜色
    headerStyleEvent ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 8) {
        return {
          background: 'linear-gradient(90deg, #6878C8 0%, #79BAF2 100%)',
          color: '#FFFFFF'
        }
      } else if (
        columnIndex == 1 ||
        columnIndex == 2 ||
        columnIndex == 3 ||
        columnIndex == 4 ||
        columnIndex == 5 ||
        columnIndex == 6 ||
        columnIndex == 7 ||
        columnIndex == 0
      ) {
        return {
          background: '#EAF5FF',
          color: '#6474C7'
        }
      }
    },
    // 协作弹窗-表格
    deliverWord (row, holder) {
      this.ReceiverName = '', // 接收人
      this.email = '',
      this.phone = ''
      // console.log(row);
      if (holder == 0) {
        this.ischoice = 0
        this.isplaceholder = '请选择协作人'
        this.ListId = row.id
      } else {
        this.isplaceholder = '请选择接收人'
        this.ischoice = 1
        this.popShow = true
        this.ManualWordShow = true
        this.EnterpriseName = row.customerName
        this.manualId = row.customerId
        this.ListId = row.id
        getCusDetil(row.customerId).then((res) => {
          // console.log(res);
          if (res.code == 0) {
            this.CusDetil = res.item.contacts
          }
        })
      }
      if (row.employeeHandbookFile) {
        this.wordId = row.employeeHandbookFile.id
        this.ManualWord = row.employeeHandbookFile
        this.ManualWordShow = true
        this.EnterpriseName = row.customerName
        this.popShow = true
        this.manualId = row.customerId
        getCusDetil(row.customerId).then((res) => {
          // console.log(res);
          if (res.code == 0) {
            this.CusDetil = res.item.contacts
          }
        })
      } else {
        this.$message({
          type: 'error',
          message: '该客户暂无相关文件，请重新编辑生成！！',
          offset: 400,
          duration: 800
        })
      }
    },
    // 协作弹窗-列表
    deliverWordList (index, holder) {
      this.ReceiverName = '', // 接收人
      this.email = '',
      this.phone = ''
      if (holder == 0) {
        this.ischoice = 0
      } else {
        this.ischoice = 1
      }
      const row = this.tableData[index]
      this.ListId = row.id
      this.EnterpriseName = row.customerName

      if (row.employeeHandbookFile) {
        this.wordId = row.employeeHandbookFile.id
        this.ManualWord = row.employeeHandbookFile
        this.ManualWordShow = true
        this.EnterpriseName = row.customerName
        this.popShow = true
        // this.manualId = row.id;
        getCusDetil(row.customerId).then((res) => {
          // console.log(res);
          if (res.code == 0) {
            this.CusDetil = res.item.contacts
          }
        })
      } else {
        this.popShow = false
        this.$message({
          type: 'error',
          message: '该客户暂无相关文件，请重新编辑生成！！',
          offset: 400,
          duration: 800
        })
      }
    },
    // 修改
    modifyPhone () {
      this.phoneShow = true
      setTimeout(() => {
        this.$refs.phoneInput.focus()
      }, 200)
    },
    modifyEmail () {
      this.emailShow = true
      setTimeout(() => {
        this.$refs.emailInput.focus()
      }, 200)
    },
    // 失去焦点
    phoneBlur () {
      this.phoneShow = false
    },
    emailBlur () {
      this.emailShow = false
    },
    // 取消
    cancl () {
      this.popShow = false
      this.rollDis = false
    },
    // 提交协作/交付
    submit () {
      // console.log(111);
      const { ReceiverName, phone, email, ReceiId } = this
      if (ReceiverName && phone && email) {
      } else {
        this.rollDis = false
        this.$message({
          type: 'warning',
          message: '姓名/邮箱/手机为必填项。',
          offset: 400,
          duration: 800
        })
        return
      }
      this.rollDis = true
      if (this.ischoice == 0) {
        const str = [
          {
            id: ReceiId,
            name: ReceiverName,
            email,
            mobile: phone,
            synergyType: 0
          }
        ]
        // console.log(str);
        launch(str, this.ListId).then((res) => {
          console.log(res)
          if (res.code == 0) {
            this.popShow = false
            this.rollDis = false
            this.$message({
              type: 'success',
              message: res.msg,
              offset: 400,
              duration: 800
            })
            this.getManualList(this.ManualList)
          }
        })
      } else {
        const str = [
          {
            id: ReceiId,
            name: ReceiverName,
            email,
            mobile: phone,
            synergyType: 1
          }
        ]
        // console.log(str,this.wordId);
        launch(str, this.wordId).then((res) => {
          // console.log(res);
          if (res.code == 0) {
            this.popShow = false
            this.rollDis = false
            this.$message({
              type: 'success',
              message: res.msg,
              offset: 400,
              duration: 800
            })
            this.getManualList(this.ManualList)
          }
        })
      }
    },
    // 新增接收人
    newRece () {
      this.newnewReceshow = true
    },
    // 新增取消
    newcancl () {
      this.popShow = true
      this.newnewReceshow = false
    },
    // 提交新增联系人
    submitnewReceiver () {
      const emailReg =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      const phoneReg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/
      if (this.newReceiver.name == '') {
        this.$message({
          type: 'error',
          message: '协作人不能为空！',
          offset: 400,
          duration: 800
        })
        return
      }
      if (phoneReg.test(this.newReceiver.phoneText) == false) {
        this.$message({
          type: 'error',
          message: '请输入正确的手机号！',
          offset: 400,
          duration: 800
        })
        return
      }
      if (emailReg.test(this.newReceiver.email) == false) {
        this.$message({
          type: 'error',
          message: '请输入正确的邮箱！',
          offset: 400,
          duration: 800
        })
        return
      }
      const data = {
        name: this.newReceiver.name,
        mobile: this.newReceiver.phoneText,
        email: this.newReceiver.email,
        customerId: this.manualId
      }
      // console.log(data);
      addCustomer(data).then((res) => {
        console.log(res)
        if (res.code == 0) {
          this.$message({
            type: 'success',
            message: res.msg,
            offset: 400,
            duration: 800
          })
          getCusDetil(this.manualId).then((res) => {
            if (res.code == 0) {
              this.CusDetil = res.item.contacts
            }
          })
        }
      })
      this.newnewReceshow = false
      this.popShow = true
    }
  }
}
</script>
<style lang="less" scoped>
.AIfrim {
  width: 660px;
  height: 241px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(152, 172, 190, 1);
  border-radius: 18px;
}
.popNotification {
  z-index: 99;
  width: 660px;
  height: 515px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(152, 172, 190, 0.2);
  border-radius: 18px;
}
/deep/.iq-card-body {
  padding: 20px;
  margin-top: -6px;
}
.card-bodyel-tabs,
.el-tabs--top {
  margin-top: -20px;
  /deep/.el-tabs__item {
    font-size: 14px;
    // font-weight: bold;
  }
  /deep/.el-tabs__item.is-top:nth-child(2) {
    padding-left: 18px;
  }

  /deep/.el-tabs__item.is-active {
    color: #837af3;
  }
  /deep/.el-tabs__item:hover {
    color: #837af3;
    cursor: pointer;
  }
  /deep/.el-tabs__active-bar.is-top {
    width: 92px !important;
  }
  /deep/.el-tabs__active-bar {
    left: -18px;
    width: 100px;
    height: 6px;
    background: linear-gradient(270deg, #f0e4fd 0%, #e6e4fd 100%);
    border-radius: 4px 4px 0px 0px;
  }
  /deep/.el-tabs__item {
    padding: 0 23px;
    margin-bottom: 4px;
  }
}
label {
  color: var(--iq-dark);
}
// 深度选择器 去掉全选按钮
::v-deep .el-table .disabledCheck .cell .el-checkbox__inner {
  display: none;
}

::v-deep .el-table .disabledCheck .cell::before {
  content: "";
  text-align: center;
  line-height: 37px;
}
// 批量操作
/deep/.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #777777;
}
.qxText {
  color: #777777;
  margin-right: 36px;
}

/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #6474c7;
  border-color: #6474c7;
}
/deep/.el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #6474c7;
}
/deep/.el-checkbox__inner {
  top: 3px;
  right: 0;
}
.qxbox /deep/.el-checkbox__inner {
  top: 0px;
  right: 0;
}
/deep/.el-table-column--selection .cell {
  padding-left: 14px;
  padding-right: 18px;
}
// 表格
/deep/.el-table__header-wrapper {
  border-radius: 18px 18px 0px 0px !important;
  /deep/.el-table_1_column_8 {
    color: #ffffff;
    background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%) !important;
  }
  /deep/.el-checkbox {
    width: 16px;
    height: 16px;
  }
}
.shouze {
  display: inline-block;
  width: 120px;
  height: 40px;
  margin-right: 20px;
  text-align: center;
  line-height: 40px;
  color: #ffffff;
  background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
  border-radius: 8px;
  border: 1px solid linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
}
.cload {
  color: #6474c7;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #6474c7;
}
.cdel {
  background: #fcc647;
  color: #ffffff;
}
.disb {
  background: rgba(152, 172, 190, 0.2);
  color: #bbbbbb;
}
.boxtop {
  width: 100%;
  height: 40px;
  margin-top: 30px;
}
.topleft {
  float: left;
}
.topright {
  float: right;
  position: relative;
  top: 0px;
  right: 0;
  color: #333333;
  input {
    width: 300px;
    height: 40px;
    color: #909399;
    background: #eaf5ff;
    border-radius: 10px;
    margin-left: 10px;
    outline: none;
    border: none;
    text-indent: 20px;
  }
}
.tabbtn {
  width: 100px;
  height: 30px;
  display: inline-block;
  background: #45cfa0;
  border-radius: 10px !important;
  border: none;
}
.exam {
  background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
  border-radius: 8px;
  color: #fff;
}
.bgshow {
  background: #f1f2f1;
  border-radius: 8px;
  color: #bbbbbb;
}
.okcan {
  width: 50px;
  height: 30px;
  display: inline-block;
  background: #45cfa0;
  border: none;
  text-align: center;
  color: #fff;
}
.okbtn {
  border-radius: 8px 0px 0px 8px !important;
}
.cancal {
  background: #fff;
  color: #45cfa0;
  border-radius: 0px 8px 8px 0px !important;
  border: 1px solid #45cfa0;
}
/* /deeep/样式穿透*/
/*1.取消原本展开的旋转动效*/
/deep/.el-table__expand-icon {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
/*2.展开按钮未点击的样式*/
/deep/.el-table__expand-icon .el-icon-arrow-right:before {
  content: "";
}
/*2.按钮已点击展开之后的样式*/
/deep/.el-table__expand-icon--expanded .el-icon-arrow-right:before {
  content: "\E6E1";
}

/deep/.el-table__expand-icon {
  position: absolute;
  right: 40px;
  top: 12px;
}
/deep/.el-table__expand-icon > .el-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -5px;
  margin-top: 0px;
}
.editword {
  width: 80px;
  height: 30px;
  background: #ffffff;
  border-radius: 8px !important;
  border: 1px solid #45cfa0;
  color: #45cfa0;
}
/deep/.listCard {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  color: 14px;
  margin-right: -18px;
  .box-card {
    width: 400px;
    margin-right: 32px;
    margin: 30px 24px 44px 0px !important;
    border-radius: 18px;
    border: 1px solid #d7dbda;
    color: 14px;
  }
}
.listCard > i {
  width: 400px;
  margin-right: 18px;
}

.iconmus {
  display: inline-block;
  width: 25px;
  height: 25px;
  background: linear-gradient(270deg, #f0e4fd 0%, #e6e4fd 100%);
  border-radius: 5px;
  text-align: center;
  margin-right: 2px;
}
.posAbs {
  position: absolute;
  z-index: -999999;
  left: 0;
  top: 210px;
  z-index: 999;

  font-size: 14px;
  margin-top: 20px;
  display: flex;
  align-content: space-around;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
}
.tabsc {
  width: 100px;
  text-align: center;
  display: inline-block;
}
.tabsc :first-child(1) {
  margin-left: 0px;
}
.tabsactive {
  width: 100px;
  border-bottom: 6px solid #f0e4fd;
  border-radius: 3px;
}
::v-deep .el-dialog__body {
  width: 100%;
  height: calc(100% - 44px);
  padding: 0;
  #box {
    height: 600px;
  }
}
/deep/.is-round {
  line-height: 30px;
  margin: 25px auto 0;
  text-align: right;
  color: #606266;
  font-size: 12px;
  border-radius: 20px;
  margin-left: 10px;
  background: #fff;
  border: 1px solid #dcdfe6;
  width: 100px;
  display: inline-block !important;
  text-align: center;
  cursor: pointer;
}
.btn {
  display: block !important;
  text-align: center;
  padding: 5px;
  ::v-deep .el-button {
    width: 135px;
    height: 36px;
    line-height: 12px;
    border-radius: 18px;
    margin: 0 20px;
  }
  ::v-deep .el-button--primary {
    background-image: linear-gradient(#318dfa, #0261d5);
  }
}
</style>
<style>
.contract {
  border-radius: 10px;
  overflow: hidden;
  background: linear-gradient(to right, #f0e4fd, #e6e4fd) !important;
}
.contract .iq-card-body {
  background: white;
}
</style>
<style>
.el-message-box__wrapper .el-message-box.aiclass {
  border-radius: 20px;
}
.el-message-box__wrapper
  .el-message-box.aiclass
  .el-message-box__header
  .el-message-box__title {
  text-align: center;
  margin: 15px auto;
}
.el-message-box__wrapper .el-message-box.aiclass .el-message-box__content {
  margin: 0px auto 30px;
}
.el-message-box__wrapper
  .el-message-box.aiclass
  .el-message-box__btns
  button
  span {
  padding: 9px 13px;
}
.el-message-box__wrapper .el-message-box.aiclass .el-message-box__btns {
  padding: 5px 15px 15px;
}
.el-dialog.processDialogClass {
  height: 100px;
  border-radius: 20px;
}
.aiConfirmBtn {
  line-height: 30px;
  margin: 25px auto 0;
  text-align: right;
}
.aiConfirmBtn .bcancel {
  color: #606266;
  font-size: 12px;
  border-radius: 20px;
  margin-left: 10px;
  background: #fff;
  border: 1px solid #dcdfe6;
  width: 100px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}
.aiConfirmBtn .bconfirm {
  color: #fff;
  background-color: #1890ff;
  border-color: #1890ff;
  font-size: 12px;
  border-radius: 20px;
  margin-left: 10px;
  width: 100px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}
.crumbs{
  background: #fff;
  border-radius: 18px;
  box-shadow:none !important;
  margin: -20px;
  width: 100%;
}
::v-deep .el-table__cell{
  position: relative;
}
</style>
