<template>
  <b-col cols="12">
    <iq-card v-if="page.name != '提醒日历'">
      <b-row class="d-flex align-items-center mb-2">
        <b-col md="9">
          <div class="d-flex align-items-center">
            <h6 class="mb-0 p-3 ml-2">{{pageNmae[page.name]}} &nbsp; > &nbsp; {{ page.name }}</h6>
          </div>
        </b-col>
        <b-col md="3" class="text-right">
        </b-col>
      </b-row>
    </iq-card>
  </b-col>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'BreadCrumb',
  watch: {
    $route (to, from) {
      this.checkRoute()
    }
  },
  mounted () {
    this.currentRoute = this.navList.find(item => item.link.name === this.$route.name)
    const book = this.bookmark.find(item => item.link.name === this.$route.name)
    if (book !== undefined) {
      this.selectedBookmark = true
    }
    console.log(this.page);
  },
  computed: {
    ...mapGetters({
      page: 'Setting/activePage',
      navList: 'Setting/globalSearchState',
      bookmark: 'Setting/bookmarkState'
    })
  },
  data () {
    return {
      bookmarkSearch: '',
      currentRoute: {},
      selectedBookmark: false,
      pageNmae: {
        "我的项目": '项目管理',
        "项目任务": '项目管理',
        "我的客户": '项目管理',
        "服务记录": '项目管理',
        "劳动合同": '文件审查',
        "员工手册": '文件审查',
        "各种协议": '文件审查',
        "规章制度": '文件审查',
        "其他文件": '文件审查',
        "业务公海": '业务池',
        "领取记录": '业务池',
        "劳动法规库": '劳动法规检索',
        "劳动争议判例库": '劳动法规检索',
        "劳动法参数查询": '劳动法规检索',
        "劳动合同与协议": '文书资源',
        "人力规章体系": '文书资源',
        "法律意见书": '文书资源',
        "iLaaS Room": '智能工具',
        "员工手册定制器": '智能工具',
        "入在离风险指引": '智能工具',
        "服务报告": '智能工具',
        "会议助手": '智能工具',
      },
    }
  },
  methods: {
    ...mapActions({
      addToBookmarkState: 'Setting/addBookmarkAction',
      removeToBookmarkState: 'Setting/removeBookmarkAction'
    }),
    removeToBookmark (item) {
      this.removeToBookmarkState(item)
      this.selectedBookmark = false
    },
    addToBookmark (item) {
      this.addToBookmarkState(item)
      this.selectedBookmark = true
    },
    checkRoute () {
      this.currentRoute = this.navList.find(item => item.link.name === this.$route.name)
      const book = this.bookmark.find(item => item.link.name === this.$route.name)
      if (book !== undefined) {
        this.selectedBookmark = true
      } else {
        this.selectedBookmark = false
      }
    }
  }
}
</script>
