<template>
  <div style="padding-bottom: 30px">
    <!-- 进度条 -->
    <div style="margin: 30px 0px">
      <el-progress
        color="linear-gradient(90deg, #6878C8 0%, #79BAF2 100%)"
        :text-inside="true"
        :stroke-width="16"
        :percentage="(step-1) * 25"
      ></el-progress>
    </div>
    <!-- 提示框 -->
    <div class="massageCard">
      <div class="mass" style="float: left"></div>
      <div
        style="
          float: left;
          margin-left: 24px;
          font-weight: bold;
          color: #6474c7;
        "
      >
        {{ msgWord }}
      </div>
      <div style="color: #fc5c47; float: right; margin: 0px 30px 0px 0px">
        <span
          class="ri-information-line"
          style="display: inline-block; margin: 0px 6px 2px 0px"
        ></span
        >员工手册设置后不可更改
      </div>
    </div>
    <!-- 步骤 -->
    <div v-if="step == 1">
      <div style="width: 100%; text-align: center" v-if="showTable">
        <span style="color: #909399">选择客户：</span
        ><el-select
          v-model="customerName"
          placeholder="请选择客户"
          @change="selectCustomer"
          class="selectInp"
        >
          <el-option
            v-for="item in options"
            :key="item.name"
            :label="item.name"
            :value="item"
          >
          </el-option> </el-select
        ><span
          @click="addCust"
          style="color: #6474c7; border-bottom: 1px solid #6474c7;cursor:pointer;"
          >添加新客户</span
        >
      </div>
      <div class="manualMsg" v-if="customer">
        <div
          style="
            width: 586px;
            height: 152px;
            text-align: center;
            border-top: 1px solid #dee2e6;
            padding: 30px 0px;
          "
        >
          <div style="width: 586px; margin-bottom: 15px; height: 22px">
            <span class="msgLeft">客户名称：</span
            ><span class="msgRight">{{ customer.name }}</span>
          </div>
          <div style="width: 586px; margin-bottom: 15px; height: 22px">
            <span class="msgLeft">客户所在地域：</span
            ><span class="msgRight">{{
              customer.province + "-" + customer.city
            }}</span>
          </div>
          <div style="width: 586px; height: 22px">
            <span class="msgLeft">客户所在行业：</span
            ><span class="msgRight">{{ customer.industryName }}</span>
          </div>
        </div>
      </div>
      <div style="width: 100%; text-align: center">
        <span style="color: #909399">企业规模：</span
        ><el-select
          v-model="scale"
          placeholder="请选择客户的企业规模"
          @change="selectScale"
          class="selectInp"
        >
          <el-option
            v-for="item in EnterpriseScale"
            :key="item.value"
            :label="item.value"
            :value="item.id"
          >
          </el-option> </el-select
        ><span style="width: 70px; display: inline-block"></span>
      </div>
    </div>
    <div class="boxMain" v-if="step == 2">
      <div class="title-style wordTip">
        2、请选择并上传客户公司LOGO，员工手册支持3种格式：GIF、PNG、JPG。
      </div>
      <div class="main-style ti">
        <el-upload
          class="avatar-uploader"
          :action="objData.host"
          :data="objData"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
        >
          <img
            v-if="imageUrl"
            :src="imageUrl"
            class="avatar"
            style="width: 130px; height: 130px"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <span class="upSpan" v-if="!imageUrl">点击上传</span>
        </el-upload>
      </div>
      <div class="wordTip title-style">
        3、您是否想要在手册中填加自定义水印，以保护手册内容的安全性？
      </div>
      <div>
        <div class="waterRadio asFont">
          <template>
            <el-radio v-model="formData.isWaterMark" label="0">否</el-radio>
            <el-radio v-model="formData.isWaterMark" label="1">是</el-radio>
          </template>
        </div>
        <div v-if="formData.isWaterMark == '1'">
          <div class="wordTip">您是要增加文字水印？还是图片水印？</div>
          <div class="watermark asFont">
            <template>
              <el-radio v-model="textWaterMark" label="HHF"
                >文字</el-radio
              >
              <el-radio v-model="textWaterMark" label=""
                >图片</el-radio
              >
            </template>
          </div>
          <div v-if="textWaterMark == 'HHF'">
            <span class="Subtitle">请填写要增加文字水印的内容：</span
            ><input
              type="text"
              v-model="formData.textWaterMark"
              placeholder="请填写文字水印内容"
              style="
                width: 456px;
                height: 44px;
                background: #ffffff;
                border-radius: 6px;
                padding-left: 20px;
                border: 1px solid #d7dbda;
              "
            />
          </div>
          <div
            style="margin: 20px 0px 0px 26px"
            v-if="textWaterMark == ''"
          >
            <el-upload
              class="avatar-uploader"
              :action="objData1.host"
              :data="objData1"
              :show-file-list="false"
              :on-success="handleAvatarSuccess1"
              :before-upload="beforeAvatarUpload1"
            >
              <img
                v-if="imageUrl1"
                :src="imageUrl1"
                class="avatar"
                style="width: 130px; height: 130px"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <span class="upSpan" v-if="!imageUrl1">点击上传</span>
            </el-upload>
          </div>
        </div>

        <div class="wordTip">请填写员工手册首页的标题名称。</div>
        <div>
          <span class="Subtitle">标题名称：</span
          ><input
            v-model="formData.title"
            type="text"
            placeholder="请填写标题名称"
            style="
              width: 600px;
              height: 44px;
              background: #ffffff;
              border-radius: 6px;
              padding-left: 20px;
              border: 1px solid #d7dbda;
            "
          />
        </div>
        <div class="wordTip">您是否想要在员工手册首页添加客户公司的名称？</div>
        <div class="nameReadio asFont">
          <template>
            <el-radio v-model="formData.isAddEnterpriseName" label="0"
              >否</el-radio
            >
            <el-radio v-model="formData.isAddEnterpriseName" label="1"
              >是</el-radio
            >
          </template>
        </div>
        <div class="wordTip"  v-if="imageUrl">您是否想要在员工手册首页加入客户公司的LOGO？</div>
        <div class="LOGORadio asFont"  v-if="imageUrl">
          <template>
            <el-radio v-model="formData.isAddEnterpriseLogo" label="0"
              >否</el-radio
            >
            <el-radio v-model="formData.isAddEnterpriseLogo" label="1"
              >是</el-radio
            >
          </template>
        </div>
        <div class="wordTip">
          您是否想要在员工手册首页加入手册的最新更新日期？
        </div>
        <div class="dateRadio asFont">
          <template>
            <el-radio v-model="formData.isAddLastUpdateDate" label="0"
              >否</el-radio
            >
            <el-radio v-model="formData.isAddLastUpdateDate" label="1"
              >是</el-radio
            >
          </template>
        </div>
      </div>
    </div>
    <div v-if="step == 3">
      <div class="strpThree">
        <div class="title-style wordTip">
          4、员工手册开始于一段总括性的陈述以表述手册的目的、企业的方向和对员工的欢迎与期待，此段陈述一般由企业的CEO、总经理等代表做出，如
        </div>
        <div
          style="margin: -28px 0px 20px 26px; font-size: 16px; color: #6474c7"
        >
          果你想此陈述也由某位成员做出，请提供姓名与职务。
        </div>
        <div style="margin-left: 10px">
          <span style="margin-right: 116px"
            ><span style="width: 80px; text-align: right; display: inline-block"
              >姓名：</span
            ><input
              v-model="formData.chargeName"
              type="text"
              placeholder="请填写姓名"
          /></span>
          <span
            ><span style="width: 80px; text-align: right; display: inline-block"
              >职务：</span
            ><input
              v-model="formData.chargePostion"
              type="text"
              placeholder="请填写职务"
          /></span>
        </div>
        <div class="title-style wordTip">
          5、员工手册声明中，手册的起草、讨论、批准与公示须遵循一定的法律流程。请明确客户所在企业员工手册的最终批准人，以及本手册的生效日期。
        </div>
        <div style="margin-left: 10px">
          <span style="margin-right: 118px"
            ><span style="width: 80px; text-align: right; display: inline-block"
              >批准人：</span
            ><input
              v-model="formData.approver"
              type="text"
              placeholder="请填写批准人"
          /></span>
          <span
            ><span style="width: 80px; text-align: right; display: inline-block"
              >生效日期：</span
            >
            <el-date-picker
              :picker-options="$set_PickerOption1()"
              v-model="formData.effectiveDate"
              type="date"
              placeholder="请选择生效日期"
              class="strpThreeSelect"
              @change="selectEffective"
            >
            </el-date-picker
          ></span>
        </div>
        <div class="title-style wordTip">
          6、如果员工有任何人力资源方面的问题时，可联系的人员部门请选择。
        </div>
        <div>
          <span style="margin: 0px 188px 0px 26px">
            <el-radio
              v-model="formData.otherContactor"
              label="1"
              style="font-size: 16px; color: #606266"
              >人力资源部</el-radio
            >
            <el-radio
              v-model="formData.otherContactor"
              label="2"
              style="font-size: 16px; color: #606266"
              >直属上级</el-radio
            >
            <el-radio
              v-model="formData.otherContactor"
              label="3"
              style="font-size: 16px; color: #606266"
              >其他部门</el-radio
            >
          </span>
          <span
            ><span style="width: 80px; text-align: right; display: inline-block"
              >联系电话：</span
            ><input
              v-model="formData.otherContactTel"
              type="text"
              placeholder="请填写联系电话"
          /></span>
        </div>
      </div>
    </div>
    <div v-if="step == 4">
      <div
        style="
          width: 700px;
          position: relative;
          left: 50%;
          transform: translate(-350px);
        "
      >
        <div class="title-style wordTip">
          7、请选择员工手册中每章标题的字体与字号。
        </div>
        <div style="margin-left: 18px">
          <span style="margin-right: 94px"
            ><span style="width: 80px; text-align: right; display: inline-block"
              >标题字体：</span
            >
            <el-select
              v-model="formData.chapterFont"
              placeholder="请选择"
              style="width: 300px"
            >
              <el-option
                v-for="item in fontList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </span>
          <span
            ><span style="width: 80px; text-align: right; display: inline-block"
              >标题字号：</span
            >
            <el-select
              v-model="formData.chapterFontSize"
              placeholder="请选择"
              style="width: 100px"
            >
              <el-option
                v-for="item in fontSize"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </span>
        </div>
        <div class="title-style wordTip">
          8、请选择员工手册中每节标题的字体与字号。
        </div>
        <div style="margin-left: 18px">
          <span style="margin-right: 94px"
            ><span style="width: 80px; text-align: right; display: inline-block"
              >标题字体：</span
            >
            <el-select
              v-model="formData.sectionFont"
              placeholder="请选择"
              style="width: 300px"
            >
              <el-option
                v-for="item in fontList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </span>
          <span
            ><span style="width: 80px; text-align: right; display: inline-block"
              >标题字号：</span
            >
            <el-select
              v-model="formData.sectionFontSize"
              placeholder="请选择"
              style="width: 100px"
            >
              <el-option
                v-for="item in fontSize"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </span>
        </div>
        <div class="title-style wordTip">
          9、请选择员工手册正文内容中所要使用的字体与字号。
        </div>
        <div style="margin-left: 18px">
          <span style="margin-right: 94px"
            ><span style="width: 80px; text-align: right; display: inline-block"
              >正文字体：</span
            >
            <el-select
              v-model="formData.contentFont"
              placeholder="请选择"
              style="width: 300px"
            >
              <el-option
                v-for="item in fontList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </span>
          <span
            ><span style="width: 80px; text-align: right; display: inline-block"
              >正文字号：</span
            >
            <el-select
              v-model="formData.contentFontSize"
              placeholder="请选择"
              style="width: 100px"
            >
              <el-option
                v-for="item in fontSize"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </span>
        </div>
      </div>
    </div>
    <div v-if="!showTable">
      <addClient :info="examineObj" @stepShow="stepShow" :stepShow="true"/>
    </div>
    <!-- 步骤按钮 -->
    <div style="width: 100%" v-if="showTable">
      <div class="stepBtn">
        <span style="background: #fff">
          <span v-if="step != 1" style="background: #eaf5ff; cursor:pointer ;" @click="backStep(step)">上一步</span>
          <span v-if="step == 1" style="background: #eaf5ff; cursor:pointer ;" @click="backIndex()">返回</span>
        </span>
        <span @click="goStep(step)" style=" cursor:pointer ;">下一步</span>
      </div>
    </div>
  </div>
</template>

<script>
import addClient from "@/views/core/UiAlerts/addClient";
import { getPolicyToken } from "@/FackApi/api/index.js";
import { getCustomer, setAddemployee ,uploadImg} from "@/FackApi/api/employee-handbook";
import { mapGetters } from "vuex";
export default {
  name: "setManual",
  components: {
    addClient,
  },
  data() {
    return {
      textWaterMark:'',
      objData: {
        OSSAccessKeyId: "",
        policy: "",
        Signature: "",
        key: "",
        host: "*",
        dir: "",
      },
      objData1: {
        OSSAccessKeyId: "",
        policy: "",
        Signature: "",
        key: "",
        host: "*",
        dir: "",
      },
      showTable: true,
      examineObj: {
        type: "add",
        tableType: "2", // 客户类型
      },
      step: 1, //步骤
      //   percen: 25, //百分比
      options: [], //客户列表
      EnterpriseScale: [
        {
          value: "1-20人",
          id: 1,
        },
        {
          value: "21-99人",
          id: 2,
        },
        {
          value: "100-499人",
          id: 3,
        },
        {
          value: "500-999人",
          id: 4,
        },
        {
          value: "1000-9999人",
          id: 5,
        },
        {
          value: "10000人以上",
          id: 6,
        },
      ],
      fontList: [{ value: "黑体" }, { value: "宋体" }, { value: "微软雅黑" }], //字体列表
      fontSize: [{ value: "12" }, { value: "14" }, { value: "16" }], //字号列表
      type: 2,
      customer: "", //客户
      customerId: "",
      customerName: "",
      scale: "", //规模
      msgWord: "请在下方选择客户名称，此客户信息将被引用到当前员工手册内容中。", //提示语
      imageUrl: "", //头像地址
      initImg: "project/cover/默认封面.png", //默认oss已存储的封面图路径
      imageUrl1: "", //头像地址
      ossInfo: {
        // oss参数
        expireTime: 120,
        uploadDir: "manual/image/",
      },
      form: {
        name: "",
        cover: "",
        description: "",
        startTime: "",
        endTime: "",
        projectAssignmentRelations: [],
      },
      formData: {
        customerId: 20047, //客户ID
        enterpriseScaleId: 0, //企业规模 1：1-20人；2：21-99人；3：100-499人；4：500-999人；5：1000-9999人；6;10000人以上
        enterpriseLogo: "", //企业LOGO
        isWaterMark: '0', //是否添加水印 0 否；1 是
        textWaterMark: "", //文字水印
        imgWaterMark: "", //图片水印地址
        title: "", //手册标题
        isAddEnterpriseName: '0', //是否添加企业名称 0 否；1 是
        isAddEnterpriseLogo: '0', //是否添加企业LOGO 0 否；1 是
        isAddLastUpdateDate: '0', //是否添加更新日期 0 否；1 是
        chargeName: "", //负责人
        chargePostion: "", //岗位
        approver: "", //审批人
        effectiveDate: "", //生效日期
        otherContactor: "", //联系人
        otherContactTel: "", //联系电话
        chapterFontSize: "16",
        chapterFont: "黑体",
        sectionFontSize: "14",
        sectionFont: "黑体",
        contentFontSize: "12",
        contentFont: "黑体",
      },
    };
  },
  mounted() {
    this.getCustomerInfo();
    let data1 = {
      code: "DZH",
      name: "大中华1",
      type: 2,
      address: "深圳福田",
      provinceId: 11,
      province: "北京",
      city: "直辖市",
      cityId: 1101,
      contact: "13398767896",
      industry: 2,
      contacts: [
        {
          name: "张三",
          mobile: "12345678910",
          position: "人事",
        },
      ],
      files: [
        {
          name: "文件名",
          filePath: "文件路径",
        },
      ],
    };
    // addCustomer(data1).then((res) => {
    //   console.log(res);
    // });
  },
  computed: {
    ...mapGetters({
      userInfo: "index/userInfoState",
      ossInfo2: "index/ossInfoState",
    }),
  },
  methods: {
    stepShow(val) {
      this.step = val;
      // console.log(val);
    },
    // 添加新客户
    addCust() {
      this.step = 0;
      this.showTable = false;
    },
    //选择生效日期
    selectEffective(val) {
      // console.log(val);
      var date = new Date(val);
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      let clickTimestamp = new Date(val).getTime();
      let nowTimestamp = new Date().getTime();
      if (nowTimestamp - clickTimestamp > 86400000) {
        this.$message({
            type:'error',
            message:'预约时间不能小于当天',
            offset:400,
            duration:800
        })
         this.effective = new Date()
         this.formData.effectiveDate = new Date()
      }else{
        this.effective = year + seperator1 + month + seperator1 + strDate;
      }
    },
    // 显示图片
    handleAvatarSuccess(res, file) {
      console.log(file);
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    handleAvatarSuccess1(res, file) {
      this.imageUrl1 = URL.createObjectURL(file.raw);
    },
    // 上传头像失败
    // 上传前的操作
    async beforeAvatarUpload(file) {
      let imgType = ["image/jpeg", "image/png", "image/jpg", "image/webp"];
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!imgType.includes(file.type)) {
         this.$message({
            type:'error',
            message:'上传图片只能是 JPG、PNG、WPS 格式!',
            offset:400,
            duration:800
        })
        return;
      }
      if (!isLt5M) {
        this.$message({
            type:'error',
            message:'上传图片大小不能超过 5MB!',
            offset:400,
            duration:800
        })
        return;
      }
      let res3 = await getPolicyToken(this.ossInfo);
      console.log(res3);
      if (res3.code == 0) {
        let data = JSON.parse(res3.content);
        console.log(data);
        this.objData = {
          OSSAccessKeyId: data.accessid,
          policy: data.policy,
          signature: data.signature,
          expire: data.expire,
          host: data.host,
          dir: data.dir,
          key: `manual/image/${this.userInfo.userId}/${new Date().getTime()}-${
            file.name
          }`,
          // key: `project/cover/${file.name}`,
        };
        console.log(this.objData);
        this.form.cover = this.objData.key;
        this.formData.enterpriseLogo = this.objData.key;
      } else {
        return;
      }
      // const formData = new FormData();
      //   formData.append("file", inputFile);
      // uploadImg(formData).then(res=>{
      //   console.log(res);
      // })
    },
    async beforeAvatarUpload1(file) {
      let imgType = ["image/jpeg", "image/png", "image/jpg", "image/webp"];
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!imgType.includes(file.type)) {
         this.$message({
            type:'error',
            message:'上传图片只能是 JPG、PNG、WPS 格式!',
            offset:400,
            duration:800
        })
        return;
      }
      if (!isLt5M) {
        this.$message({
            type:'error',
            message:'上传图片大小不能超过 5MB!',
            offset:400,
            duration:800
        })
        return;
      }
      let res3 = await getPolicyToken(this.ossInfo);
      if (res3.code == 0) {
        let data = JSON.parse(res3.content);
        this.objData1 = {
          OSSAccessKeyId: data.accessid,
          policy: data.policy,
          signature: data.signature,
          expire: data.expire,
          host: data.host,
          dir: data.dir,
          key: `manual/image/${this.userInfo.userId}/${new Date().getTime()}-${
            file.name
          }`,
          // key: `project/cover/${file.name}`,
        };
        this.form.cover = this.objData1.key;
        this.formData.imgWaterMark = this.objData1.key
      } else {
        return;
      }
    },
    // 选择客户列表
    selectCustomer(val) {
      // console.log(val);
      this.formData.customerId = val.id;
      this.customer = val;
      this.customerName = val.name;
      // console.log(val.name);
    },
    // 企业规模
    selectScale(val) {
      // console.log(val);
      this.formData.enterpriseScaleId = val;
    },
    // 下一步
    goStep(step) {
      step + 1 > 4 ? (this.step = 4) : (this.step = step + 1);
      if(step==1){
        if( this.customerName==''){
            this.$message({
            type:'error',
            message:'请选择客户！',
            offset:400,
            duration:800
        })
           this.step = 1
           return
        }
      }else if(step == 2){
         if (this.formData.title == "" || this.formData.title.length>16) {
           this.$message({
            type:'error',
            message:'手册名称不能为空且长度不超过16位',
            offset:400,
            duration:800
        })
            this.step = 2
          return;
        }
         
      }else if(step==3){
        let reg =  /^((0\d{2,3}-\d{7,8})|(1[367584]\d{9}))$/  //电话验证
        if(!reg.test(this.formData.otherContactTel)){
           this.$message({
            type:'error',
            message:'请输入正确的联系电话',
            offset:400,
            duration:800
        })
            this.step = 3
          return;
        }
          let reg1 = /^[\u4e00-\u9fa5a-zA-Z]{1,16}/
        if(!reg1.test(this.formData.chargeName)){
           this.$message({
            type:'error',
            message:'负责人姓名只支持中文或字母且长度不超过16位',
            offset:400,
            duration:800
        })
            this.step = 3
          return;
        }
         if(!reg1.test(this.formData.chargePostion)){
           this.$message({
            type:'error',
            message:'职务只支持中文或字母且长度不超过16位',
            offset:400,
            duration:800
        })
            this.step = 3
          return;
        }
         if(!reg1.test(this.formData.approver)){
           this.$message({
            type:'error',
            message:'批准人只支持中文或字母且长度不超过16位',
            offset:400,
            duration:800
        })
            this.step = 3
          return;
        }
      }else if (step == 4) {
        // console.log(this.formData);
        setAddemployee(this.formData).then((res) => {
          // console.log(res);
          if (res.code == 0) {
            this.$message({
              message: res.msg,
              type: "success",
               offset:400,
            duration:800
            });
            this.step = 1;
            this.$emit("tabCheck", step);
            this.$emit("getmanual");
          }
        });
      }
       if (step + 1 == 2) {
           this.msgWord = "请选择并上传客户公司LOGO、水印、标题名称等信息。";
        } else if (step + 1 == 3) {
          this.msgWord = "请选择员工手册陈述人、最终批准人、联系部门及联系电话。";
        } else if (step + 1 == 4) {
          this.msgWord = "请设置员工手册的字体与字号。";
        }
          
    },
    // 上一步
    backStep(step) {
      this.step = step - 1;
      if (step - 1 == 2) {
        this.msgWord = "请选择并上传客户公司LOGO、水印、标题名称等信息。";
      } else if (step - 1 == 3) {
        this.msgWord = "请选择员工手册陈述人、最终批准人、联系部。";
      } else if (step - 1 == 1) {
        this.msgWord =
          "请在下方选择客户名称，此客户信息将被引用到当前员工手册内容中。";
      }
      // if()
    },
    // 返回
    backIndex(){
       this.$emit("tabCheck", 4);
    },
    // 获取客户列表
    getCustomerInfo() {
      getCustomer(this.type).then((res) => {
        // console.log(res);
        if (res.code == 0) {
          this.options = res.items;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped >
// 进度条
::v-deep .el-progress-bar__outer {
  background: #eaf5ff;
}
::v-deep .el-progress-bar__inner {
  background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
}
::v-deep .el-progress-bar__inner:after {
  height: 17px;
  width: 17px;
  background: linear-gradient(180deg, #79baf2 0%, #6878c8 100%);
  border-radius: 50%;
  border: 3px solid #ffffff;
}
::v-deep .el-progress-bar__innerText {
  color: #fff;
  margin: 0 45%;
}
.massageCard {
  height: 40px;
  line-height: 40px;
  margin-bottom: 30px;
  background: #eaf5ff;
  border-radius: 4px;
  .mass {
    width: 6px;
    height: 40px;
    background: linear-gradient(180deg, #79baf2 0%, #6878c8 100%);
    border-radius: 3px 0px 0px 3px;
  }
}
.selectInp {
  width: 586px;
  height: 44px;
  border-radius: 6px;
  margin: 0px 20px 0px 12px;
}
.manualMsg {
  width: 100%;
  text-align: center;
  margin-top: 30px;

  position: relative;
  left: 50%;
  transform: translate(-294px);
  .msgLeft {
    color: #909399;
    float: left;
  }
  .msgRight {
    color: #333333;
    float: right;
  }
}
.stepBtn {
  width: 872px;
  height: 40px;
  position: relative;
  left: 50%;
  transform: translate(-436px);
  margin-top: 30px;
  span {
    width: 120px;
    height: 40px;
    display: inline-block;
    border-radius: 25px;
    font-weight: bold;
    text-align: center;
    line-height: 40px;
  }
  span:nth-child(1) {
    position: absolute;
    left: 0;
    color: #6474c7;
    background: #eaf5ff;
  }
  span:nth-child(2) {
    position: absolute;
    right: 0;
    color: #ffffff;
    background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
  }
}
.main-style {
  margin: 20px 0px 40px 0px;
}
.boxMain {
  width: 1552px;
  position: relative;
  left: 50%;
  transform: translate(-360px);
}
/deep/.avatar-uploader .el-upload {
  width: 130px;
  height: 130px;
  border: 1px solid #d7dbda;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 16px;
  font-weight: bolder;
  color: #6474c7;
  background: #eaf5ff;
  width: 130px;
  height: 130px;
  line-height: 130px;
  text-align: center;
  border-radius: 6px;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.upSpan {
  position: absolute;
  left: 36px;
  bottom: 14px;
  color: #6474c7;
}
.wordTip {
  font-size: 16px;
  color: #6474c7;
  margin: 40px 0px 20px 0px;
  margin-left: 26px;
}
.title-style {
  margin-left: 0px;
}
.ti {
  margin-left: 26px;
}
.asFont {
  font-size: 16px;
  color: #606266;
  margin-left: 26px;
}
.Subtitle {
  margin-left: 26px;
  font-size: 16px;
  color: #909399;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #c0c4cc;
  font-size: 16px;
}
.strpThree {
  width: 1050px;
  position: relative;
  left: 50%;
  transform: translate(-525px);
  input {
    width: 320px;
    height: 44px;
    background: #ffffff;
    border-radius: 6px;
    border: 1px solid #d7dbda;
    padding-left: 20px;
  }
  span {
    font-size: 16px;
    color: #909399;
  }
  .strpThreeSelect {
    width: 320px;
    height: 44px;
  }
}
/deep/ .el-icon-date {
  display: none;
}
/deep/ .el-input--prefix .el-input__inner {
  padding-left: 20px;
}
</style>