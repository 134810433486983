<template>
  <div style="margin-top:-30px">
    <!-- <div class="main"> -->
    <div class="mainLeft">
      <div style="height: 60px; border-radius: 18px 0px 0px 0px;background: #FFFFFF;border-bottom: 1px solid #ccc;"></div>
      <div style="padding: 20px;height: calc(100vh - 62px); overflow: auto;">
          <div v-for="(item,index) in AIData" :key="index"  class="aBox">
              <span style="color:#333;display:block;" class="bBox" @click="chackBox(index)">
                <i class="ri-arrow-right-s-fill" v-if="item.iShow"></i>
                <i class="ri-arrow-down-s-fill" v-if="!item.iShow"></i>
                <span style="cursor:pointer">{{item.label}}</span>
              </span>
              <div class="cBox" style="text-indent: 1em;height:26px;cursor:pointer;color:#909399;" v-for="(val,ind) in item.children" :key="ind" @click="cunRow(val.id,index,ind)" >{{val.label}}<span v-if="val.count!=0"  style="display:inline-block;width:15px;height:15px;line-height: 15px;margin-left: 20px;font-size: 12px;background: #FF600A;color:#FFFFFF;border-radius:50%;text-align: center;text-indent: 0em;">{{val.count}}</span></div>
          </div>
      </div>
    </div>
    <div class="mainContent" style="position: relative; height: 730px" v-loading="loaddd">
      <!-- 工具栏 -->
      <Toolbar
        style="border-bottom: 1px solid #ccc; width: 100%;background: #FFFFFF;height: 60px;padding-top:10px;"
        :editor="editor"
        :defaultConfig="toolbarConfig"
      />
      <!-- 标题 -->
      <div style="background: #FFFFFF;margin: 26px 30px 0px 30px;padding:0px 50px;box-shadow: 0px 0px 10px 0px rgba(152,172,190,0.2);">
          <div style="border-bottom:1px solid #E2E6ED;padding:50px 0px 20px 0px;color: #333333;font-size: 16px;font-weight: bold;">{{title}}</div>
      </div>
      <!-- 编辑器 -->
      <Editor
        class="editor"
        style="height: calc(100vh - 200px); margin: 0px 30px 0px 30px;"
        :defaultConfig="editorConfig"
        v-model="html"
        @onChange="onChange"
        @onCreated="onCreated"
        @onBlur="onBlur"
      />
    </div>
    <div class="mainRight">
        <div style="height: 60px; border-radius: 0px 18px 0px 0px;background: #FFFFFF;border-bottom: 1px solid #ccc;padding-top:10px">
            <span style="
            width: 40%;
            height: 36px;
            color: #FFFFFF;
            margin-left:6%;
            line-height:36px;
            background: linear-gradient(90deg, #6878C8 0%, #79BAF2 100%);
            display:inline-block;
            cursor:pointer ;
            text-align:center;
            border-radius: 25px;"
            @click="start"
            >开始AI智能审核</span>
            <span
            @click="submit(0)"
            style="
              width: 22%;
              height: 36px;
              line-height:36px;
              background: #FFFFFF;
              border-radius: 25px;
              display:inline-block;
              color: #6474C7;
              text-align:center;
              cursor:pointer ;
              border: 1px solid #6474C7;
              margin:0px 10px 0px 14px;
            ">保存</span>
            <span 
              @click="cancl"
              style="
              width: 22%;
              height: 36px;
              text-align:center;
              display:inline-block;
              color: #6474C7;
              line-height:36px;
              cursor:pointer ;
              background: #EAF5FF;
              border-radius: 25px;
            ">取消</span>
        </div>
        <div style="padding: 20px;height: calc(100vh - 68px); overflow: auto;" v-loading="loading">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane :label="NLP" name="user" >
                    <div class="cardList" style="border: 1px solid #FF600A;width: 100%;" v-for="(item,index) in cardList" :key="index">
                      <div>NLP检查{{cardList.length}}</div>
                      <div style="color: #333333;font-size: 14px;">"{{item.ori_frag}}"是否更改为"{{item.correct_frag}}"</div>
                      <div style="margin-top:32px;">
                      <span @click="ignore(item)" style="
                      width: 36%;
                      height: 36px;
                      display:inline-block;
                      text-align:center;
                      line-height:36px;
                      border-radius: 19px;
                      margin-left:8%;
                      border: 1px solid #BFBFBF;color: #BFBFBF;
                      cursor:pointer;
                      ">忽略</span>
                      <span @click="iSreplace(item)" style="
                        width: 44%;
                        height: 36px;
                        display:inline-block;
                        text-align:center;
                        color: #FFFFFF;
                        line-height:36px;
                        margin-left:10px;
                        background: linear-gradient(90deg, #6878C8 0%, #79BAF2 100%);
                        border-radius: 19px;
                        cursor:pointer;
                      ">点击替换</span></div>
                    </div>
                    <div style="text-align:center" v-show="!cardList.length">暂无数据</div>
                </el-tab-pane>
                <!-- <el-tab-pane :label="NLM" name="configure">
                  <div class="cardList" style="border: 1px solid #FF600A;" v-for="(item,index) in dnn" :key="index">
                      <div>文字纠正{{cardList.length}}</div>
                      <div style="color: #333333;font-size: 14px;">"{{item.ori_frag}}"是否更改为"{{item.correct_frag}}"</div>
                      <div style="margin-top:13px;">
                      <span  @click="ignore1(item)" style="
                      width: 80px;
                      height: 38px;
                      display:inline-block;
                      text-align:center;
                      line-height:38px;
                      border-radius: 19px;
                      margin-left:157px;
                      border: 1px solid #BFBFBF;color: #BFBFBF;
                      cursor:pointer;
                      ">忽略</span>
                      <span @click="iSreplace2(item)" style="
                        width: 110px;
                        height: 38px;
                        display:inline-block;
                        text-align:center;
                        color: #FFFFFF;
                        line-height:38px;
                        margin-left:10px;
                        background: linear-gradient(90deg, #6878C8 0%, #79BAF2 100%);
                        border-radius: 19px;
                        cursor:pointer;
                      ">点击替换</span></div>
                    </div>
                    <div style="text-align:center" v-show="!dnn.length">暂无数据</div>
                </el-tab-pane> -->
            </el-tabs>
        </div>
    </div>
    <!-- </div> -->
  </div>
</template>
<script>
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { DomEditor } from "@wangeditor/editor";
import  {Titleexamination,upContentexamination,contentexamination} from "@/FackApi/api/employee-handbook";
export default {
  name: "MyEditor",
  components: { Editor, Toolbar },
  props: {
    content: {
      type: String,
      default: "",
    },
    mamaultitle: {
      type: String,
      default: "",
    },
    AIDataList:{
      type: Array,
      default: "",
    }
  },
  data() {
    return {
      AIData:[],
      loading:false,
      loaddd:false,
      NLP:'',
      NLM:'',
      activeName: 'user',//默认选中
      editor: null,
      html: "",
      title:'',
      cardList:[],
      dnn:[],
      iShow:false,
      cunRowId:0,//章节id
      titleLength:0,//标题index
      countLength:0,//错误index
      toolbarConfig: {
        /* 显示哪些菜单，如何排序、分组 */
        toolbarKeys: [
          "undo",
          "redo",
          "|",
          "headerSelect",
          "fontFamily",
          "fontSize",
          "|",
          "bold",
          "italic",
          "underline",
          "color",
          "bgColor",
          "|",
          "bulletedList",
          "numberedList",
          {
            iconSvg:
              '<svg viewBox="0 0 1024 1024"><path d="M768 793.6v102.4H51.2v-102.4h716.8z m204.8-230.4v102.4H51.2v-102.4h921.6z m-204.8-230.4v102.4H51.2v-102.4h716.8zM972.8 102.4v102.4H51.2V102.4h921.6z"></path></svg>',
            key: "group-justify",
            menuKeys: [
              "justifyLeft",
              "justifyRight",
              "justifyCenter",
              "justifyJustify",
            ],
            title: "对齐",
          },
          {
            iconSvg:
              '<svg viewBox="0 0 1024 1024"><path d="M0 64h1024v128H0z m384 192h640v128H384z m0 192h640v128H384z m0 192h640v128H384zM0 832h1024v128H0z m0-128V320l256 192z"></path></svg>',
            key: "group-indent",
            menuKeys: ["indent", "delIndent"],
          },
          "divider",
          // {
          //   iconSvg:
          //     '<svg viewBox="0 0 1024 1024"><path d="M959.877 128l0.123 0.123v767.775l-0.123 0.122H64.102l-0.122-0.122V128.123l0.122-0.123h895.775zM960 64H64C28.795 64 0 92.795 0 128v768c0 35.205 28.795 64 64 64h896c35.205 0 64-28.795 64-64V128c0-35.205-28.795-64-64-64zM832 288.01c0 53.023-42.988 96.01-96.01 96.01s-96.01-42.987-96.01-96.01S682.967 192 735.99 192 832 234.988 832 288.01zM896 832H128V704l224.01-384 256 320h64l224.01-192z"></path></svg>',
          //   key: "group-image",
          //   menuKeys: ["insertImage", "uploadImage"],
          //   title: "图片",
          // },
        ],
        // excludeKeys: [ ], /* 隐藏哪些菜单 */
      },
      editorConfig: {
        placeholder: "",
        // autoFocus: false,
        readOnly: false, // 只读、不可编辑
        // 所有的菜单配置，都要在 MENU_CONF 属性下
        MENU_CONF: {},
      },
    };
  },
  mounted() {
    const toolbar = DomEditor.getToolbar(editor);
    const curToolbarConfig = toolbar.getConfig();
    this.cunRowId = this.AIData[0].children[0].id
    this.getTilteContent(this.cunRowId)
    this.start()
    this.cunRow(this.cunRowId,0,0)
    for (let i = 0; i < this.AIData.length; i++) {
        this.AIData[i].iShow = false
    }

   
  },
   watch:{
        // 被侦听的变量
        AIDataList(){
            this.cunRowId = this.AIDataList[0].children[0].id
            this.getTilteContent(this.cunRowId)
            this.NLM = `文字纠正(${this.dnn.length})`
            this.NLP = `NLP检查(${this.cardList.length})`
            for (let i = 0; i < this.AIDataList.length; i++) {
              this.AIDataList[i].iShow = false
            }
            this.AIData = this.AIDataList
        }
    },
  methods: {
    // tabs
    handleClick(tab, event) {
        // console.log(tab, event);
    },
    // 忽略
    ignore(val){
      if(val.ori_frag=='.'){
        val.ori_frag = '\\.'
      }
      var reg = new RegExp(val.ori_frag,'g');//g,表示全部替换。
          this.html=this.html.replace(reg,val.ori_frag)
          var newList=[];
          this.cardList.forEach(item=>{
                    if(item!= val){
                      newList.push(item)
                    }
                  })
          this.cardList=newList
           this.AIData[this.titleLength].children[this.countLength].count--
          this.NLP='NLP检查('+this.cardList.length+')'
        //保存当前内容
        this.submit(1)
    },
    ignore1(val){
      if(val.ori_frag=='.'){
        val.ori_frag = '\\.'
      }
     var reg = new RegExp(val.ori_frag,'g');//g,表示全部替换。
          this.html=this.html.replace(reg,val.ori_frag)
          var newList=[];
          this.dnn.forEach(item=>{
                    if(item!= val){
                      newList.push(item)
                    }
                  })
          this.dnn=newList
           this.AIData[this.titleLength].children[this.countLength].count--
          this.NLM='文字纠正('+this.dnn.length+')'
        //保存当前内容
        this.submit(1)
    },
    // 替换
    iSreplace(val){
      if(val.ori_frag=='.'){
        val.ori_frag = '\\.'
      }
        var reg = new RegExp(val.ori_frag,'g');//g,表示全部替换。
          this.html=this.html.replace(reg,val.correct_frag)
          var newList=[];
          this.cardList.forEach(item=>{
                    if(item!= val){
                      newList.push(item)
                    }
                  })
          this.cardList=newList
          this.AIData[this.titleLength].children[this.countLength].count--
          this.NLP='NLP检查('+this.cardList.length+')'
        //保存当前内容
        this.submit(1)
    },
    iSreplace1(val){
      if(val.ori_frag=='.'){
        val.ori_frag = '\\.'
      }
      var reg = new RegExp(val.ori_frag,'g');//g,表示全部替换。
          this.html=this.html.replace(reg,val.correct_frag)
          var newList=[];
          this.dnn.forEach(item=>{
                    if(item!= val){
                      newList.push(item)
                    }
                  })
          this.dnn=newList
           this.AIData[this.titleLength].children[this.countLength].count--
          this.NLM='文字纠正('+this.dnn.length+')'
        //保存当前内容
        this.submit(1)
    },
    // 保存
    submit(type){
        let data = {
          "id": this.cunRowId,
          "title": this.title,
          "content": this.html,
          "tp": type// 0 重新生成文档；1 仅更新当前章节内容
        }
        upContentexamination(data).then(res=>{
          // console.log(res);
          if(res.code==0){
              // this.$message({
              //   type:'success',
              //   message:'更新成功'
              // })
              if(type==0){
                this.$message({
                type:'success',
                message:'保存成功'
              })
                this.cancl()
              } 
          }
        })
    },
    // 开始审核
    start(){
      let data = {
        text: this.html
      }
      this.loading = true
      contentexamination(data).then(res=>{
        console.log(res);
        if(res.code==0){
          res.data.forEach(val=>{
            var reg1=/\<a/ig;
            var reg2=/\<span/ig;
            var reg = new RegExp(val.ori_frag,'g');//g,表示全部替换。
            let text = res.text.replace(reg,`<span style="color:#FF600A;class="resClass">${val.ori_frag}</span>`)
            text = text.replace(reg1,'\<a style=\"text-decoration: none; color: #333;\"')
            text = text.replace(reg2,'\<span style=\"color: #333;font-style:normal;\"')
            this.html = text
        })
          this.loading =false
          this.cardList = res.data
          this.dnn = res.dnn
          this.NLM = `文字纠正(${res.dnn.length})`
          this.NLP = `NLP检查(${res.data.length})`
        }
      })
    },
    // 收起
    chackBox(val){
      //  console.log(val);
      this.cunRow(this.AIData[val].children[0].id,val,0)
      let aDiv = document.getElementsByClassName('aBox')[val]
      let oDiv = aDiv.getElementsByClassName('cBox')
        for (let i = 0; i < this.AIData.length; i++) {
           this.AIData[i].iShow = false
        }
      if(oDiv[0].style.height=='0px'){  
        this.AIData[val].iShow = false
        for(var i = 0; i < oDiv.length; i++){
            oDiv[i].style.height = '26px'; 
        }   
      }else{
        for(var i = 0; i < oDiv.length; i++){
           this.AIData[val].iShow = true
            oDiv[i].style.height = '0px';  
        } 
      }
    },
    // 点击标题
    cunRow(val,index,ind){
      for (let i = 0; i < this.AIData.length; i++) {
        for (let j = 0; j < this.AIData[i].children.length; j++) { 
          this.AIData[i].children[j].tipShow = false
        }
      }
      let aBox = document.getElementsByClassName('aBox')
      for (let i = 0; i < aBox.length; i++) {
         let cBox = aBox[i].getElementsByClassName('cBox')
         for (let j = 0; j < cBox.length; j++) {
          cBox[j].style.color = '#909399'
          aBox[index].getElementsByClassName('cBox')[ind].style.color = "#6474C7"
         }
      }
      this.cunRowId = val
      this.titleLength = index
      this.countLength = ind
      this.getTilteContent(val)
      this.AIData[index].children[ind].tipShow = true
    },
    // 获取章节指定内容
    getTilteContent(val){
      this.loaddd = true
      Titleexamination(val).then(res=>{
        // console.log(res);
        if(res.code==0){
          this.loaddd = false
          // this.html = res.data.content
          var reg1=/\<a/ig;
          let text = res.data.content
          this.html =  text.replace(reg1,'\<a style=\"text-decoration: none; color: #333;\"')
          this.title = res.data.title
              let data = {
                text: res.data.content
              }
              this.loading = true
              contentexamination(data).then(res=>{
                if(res.code==0){
                  res.data.forEach(val=>{
                     var reg1=/\<a/ig;
                     var reg2=/\<span/ig;
                     var reg = new RegExp(val.ori_frag,'g');
                     let text = res.text.replace(reg,`<span style="color:#FF600A;class="resClass">${val.ori_frag}</span>`)
                     text = text.replace(reg1,'\<a style=\"text-decoration: none; color: #333;\"')
                     text = text.replace(reg2,'\<span style=\"color: #333;font-style:normal;\"')
                     this.html = text
                  })
                  this.loading =false
                  this.cardList = res.data
                  this.dnn = res.dnn
                  this.NLM = `文字纠正(${res.dnn.length})`
                  this.NLP = `NLP检查(${res.data.length})`
                }
              })
        }
      })
    },
    // 取消
    cancl(){
       let step  = 4
      //  console.log(this.$parent);
       this.$emit("tabCheck", step);
       this.$emit("getmanual");
       this.$parent.isAIShow = true
    },
    GlobalModel() {
      this.$emit("Global");
    },
    onCreated(editor) {
      // console.log(editor);
      this.editor = Object.seal(editor); // 【注意】一定要用 Object.seal() 否则会报错
    },
    onChange(editor) {
      // console.log("onChange", editor.getHtml() ); // onChange 时获取编辑器最新内容
      // const toolbar = DomEditor.getToolbar(editor)
      // console.log("工具栏配置", toolbar.getConfig().toolbarKeys ); // 工具栏配置
      // this.$emit("content", editor.getHtml());
      // console.log(editor.getHtml());
    },
    // onFocus(editor) {
    //   this.html = this.content;
    // },
    onBlur(editor) {
      this.$emit("getContent", editor.getHtml());
    },
    getEditorText() {
      const editor = this.editor;
      if (editor == null) return;
      // console.log(editor.getText()); // 执行 editor API
    },
    printEditorHtml() {
      const editor = this.editor;
      if (editor == null) return;
      // console.log(editor.getHtml()); // 执行 editor API
    },
  },
  mounted() {
    // 模拟 ajax 请求，异步渲染编辑器
    setTimeout(() => {
      this.html = this.content;
    }, 200);
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁 editor ，重要！！！
  },
};
</script>
<style scoped>
  .w-e-text >>>.resClass{
    color: #FF600A !important;
  }
  html em{
    color: #333 !important;
    font-style: normal !important;
  }
  html i{
    color: #333 !important;
    font-style: normal !important;
  }
 ::v-deep .w-e-text i{
    color: #FF600A !important;
    font-style: normal !important;
  }
  ::v-deep .w-e-text em{
    color: #333 !important;
    font-style: normal !important;
  }
  ::v-deep.token {
     color: #333 !important;
     font-style: normal !important;
  }
  ::v-deep.italic{
     color: #333 !important;
     font-style: normal !important;
  }
</style>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="less" scoped>
.Global {
  width: 120px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  float: right;
  background: linear-gradient(270deg, #f0e4fd 0%, #e6e4fd 100%);
  border-radius: 8px;
  font-weight: bold;
  color: #837af3;
  font-size: 14px;
}
</style>
<style lang="less" scoped>
::v-deep #wangeditor {
  .w-e-text-container {
    // 文本框里面的层级调低
    z-index: -1 !important;
    i{
      font-style: normal !important;
    }
  }
  .w-e-text i{
    color: #FF600A !important;
    font-style: normal !important;
  }
  .w-e-text em{
    color: #333 !important;
    font-style: normal !important;
  }
  .w-e-menu {
    // 最重要的一句代码
    z-index: auto !important;
    

    .w-e-droplist {
      // 触发工具栏后的显示框调高
      z-index: 999 !important;
    }
  }
}
/deep/.w-e-text i{
    color: #FF600A !important;
    font-style: normal !important;
  }
  /deep/.w-e-text em{
    color: #333 !important;
    font-style: normal !important;
  }
/deep/.w-e-text-container{
   height: calc(100vh - 187px) !important;
   padding: 0px 50px;
}

/deep/.w-e-toolbar {
    // 给工具栏换行
    flex-wrap: nowrap;
    z-index: 9 !important;
    margin-left:-150px;
  }

.cBox{
  overflow: hidden; transition: all .4s;
}
.mainLeft {
  width: 20%;
  height: 100vh;
  background: #ffffff;
  border-radius: 18px 0px 0px 0px;
  float: left;
}
.mainContent {
  float: left;
  width: 52%;
}
.mainRight {
  width: 28%;
  height: 100vh;
  background: #ffffff;
  border-radius: 0px 18px 0px 0px;
  float: left;
}
/deep/ .col-lg-12 .iq-card{
  margin-bottom: 15px !important;
}
.card-bodyel-tabs,
.el-tabs--top {
  margin-top: -20px;
  /deep/.el-tabs__item {
    font-size: 14px;
    // font-weight: bold;
  }
  /deep/.el-tabs__item.is-top:nth-child(2) {
    padding-left: 18px;
  }

  /deep/.el-tabs__item.is-active {
   color: #6474C7;
  }
  /deep/.el-tabs__item:hover {
   color: #6474C7;
    cursor: pointer;
  }
  /deep/.el-tabs__active-bar.is-top {
    width: 200px !important;
  }
  /deep/.el-tabs__active-bar {
    left: -18px;
    // width: 100px;
    height: 6px;
    background: linear-gradient(90deg, #6878C8 0%, #79BAF2 100%);
    border-radius: 4px 4px 0px 0px;
  }
  /deep/.el-tabs__item {
    width: 200px;
    margin:20px 0px ;
    text-align: center;
    padding: 0 23px;
    margin-bottom: 4px;
    color: #999999;
  }
}
.cardList{
  width: 400px;
  height: 172px;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 20px;
  margin-bottom:20px;
  border: 1px solid #E2E6ED;
}
</style>
